import { datadogRum } from '@datadog/browser-rum';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  TextField, FormControlLabel,
  Checkbox, Button, Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import authorization from '../../../services/auth/authorization';
import { getUser } from '../../../services';
import { MAIN_ROUTE, AUTHORIZATION_RESTORE_CODE_ROUTE } from '../../../constants/route';
import { ADMIN } from '../../../constants/roles';
import { PopupContext, POPUP_TYPES } from '../../../context';

import styles from './SignInStyles';

export default function SignIn({ setAuthorized }) {
  const dispatchPopup = useContext(PopupContext);
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm();

  const authorize = async (data) => {
    setLoading(true);
    try {
      if (data.email && data.password) {
        const { token } = await authorization({ ...data, remember });
        const user = await getUser(token);

        datadogRum.setUser({ id: user.uuid, email: user.email, name: `${user.roles}` });
        const role = user.roles[0];

        if (role === ADMIN) {
          localStorage.token = token;
          localStorage.role = role;
          setAuthorized(true);

          console.log('authorize');
          navigation(MAIN_ROUTE);
        } else {
          throw new Error('Access denied');
        }
      }
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(authorize)}
      style={styles.authorization}
    >
      <Controller
        defaultValue=""
        control={control}
        name="email"
        rules={{ required: true }}
        render={({
          field: {
            name, onBlur, onChange,
            ref, value,
          },
        }) => (
          <TextField
            sx={styles.input}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value}
            error={!!errors.email}
            type="email"
            label="Email"
            variant="standard"
          />
        )}
      />
      <br />
      <Controller
        defaultValue=""
        control={control}
        name="password"
        rules={{ required: true }}
        render={({
          field: {
            name, onBlur,
            onChange, ref, value,
          },
        }) => (
          <TextField
            sx={styles.input}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value}
            error={!!errors.password}
            type="password"
            label="Password"
            variant="standard"
          />
        )}
      />
      <FormControlLabel
        sx={{ alignSelf: 'start' }}
        control={(
          <Checkbox
            onChange={(e) => setRemember(e.target.checked)}
            checked={remember}
            size="small"
          />
        )}
        label={(
          <Typography style={{ fontSize: '12px' }}>
            Remember me
          </Typography>
        )}
      />
      <br />
      <Box>
        <LoadingButton
          loading={loading}
          type="submit"
          style={{ marginRight: '5px' }}
          variant="contained"
        >
          Sign in
        </LoadingButton>
        <Button
          onClick={() => navigation(AUTHORIZATION_RESTORE_CODE_ROUTE)}
          variant="outlined"
        >
          Forgot password?
        </Button>
      </Box>
    </form>
  );
}

SignIn.propTypes = {
  setAuthorized: PropTypes.func.isRequired,
};
