import { useState } from 'react';
import ms from 'ms';
import qs from 'qs';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import {
  ViewModule as ViewModuleIcon,
  SupportAgent as SupportAgentIcon,
  PrivacyTip as PrivacyTipIcon,
  SwitchAccount as SwitchAccountIcon,
  HelpCenter as HelpCenterIcon,
} from '@mui/icons-material';

import { useFeedbackList } from '../../services';

import {
  MODULES,
  USERS,
  USERS_FEEDBACK,
  HELP_CENTER,
  TERMS_OF_USE,
  MAIN_ROUTE,
} from '../../constants/route';

import Show from '../Show/Show';

import Logo from '../../assets/Logo.png';
import styles from './MenuStyles';

const routeList = [
  { ...MODULES, Icon: ViewModuleIcon },
  { ...USERS, Icon: SwitchAccountIcon },
  { ...USERS_FEEDBACK, Icon: SupportAgentIcon },
  { ...HELP_CENTER, Icon: HelpCenterIcon },
  { ...TERMS_OF_USE, Icon: PrivacyTipIcon },
];

export default function Menu() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [feedBackNotification, setFeedBackNotification] = useState(false);
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const feedbackList = useFeedbackList({}, {
    onSuccess: ({ data }) => {
      setFeedBackNotification((data || []).some((feedback) => feedback.status === 'pending'));
    },
    key: 'for-select',
    refetchInterval: ms('5m'),
  });

  const query = qs.stringify({ jurisdiction: jurisdictionUuid });
  return (
    <Box sx={styles.menu}>
      <Box sx={styles.nav}>
        <Link to={`${MAIN_ROUTE}?${query}`}>
          <img
            style={styles.logo}
            src={Logo}
            alt="Ginger Snail"
          />
        </Link>
        {routeList.map(({ NAME, ROUTE, Icon }) => {
          const isRouteCurrent = ROUTE === location.pathname.match(/\w+/)?.[0];

          return jurisdictionUuid ? (
            <Link
              style={styles.link}
              to={`${ROUTE}?${query}`}
              key={ROUTE}
            >
              <Show
                show={ROUTE === USERS_FEEDBACK.ROUTE && feedbackList.isFetched && feedBackNotification}
              >
                <Box sx={styles.notification} />
              </Show>
              <Button
                size="small"
                variant={isRouteCurrent ? 'contained' : 'text'}
                sx={isRouteCurrent
                  ? styles.activeButton
                  : styles.inactiveButton}
                startIcon={(
                  <Icon
                    sx={isRouteCurrent
                      ? styles.activeIcon
                      : styles.inactiveIcon}
                  />
                )}
              >
                {NAME}
              </Button>
            </Link>
          ) : (
            <Skeleton
              sx={{ margin: '10px' }}
              animation="wave"
              variant="rectangular"
              width={175}
              height={36}
              key={ROUTE}
            />
          );
        })}
        <br />
      </Box>
    </Box>
  );
}
