import { memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import TopicsList from './TopicList';

function Topic() {
  const location = useLocation();
  const loadDefault = location.pathname.split('/').length === 3;
  return (
    <>
      {loadDefault && <TopicsList />}
      <Outlet />
    </>
  );
}

export default memo(Topic);
