import PropTypes from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';
import { white } from '../../constants/styles';

export default function AddButton({
  sx, text, onClick, disabled, size,
}) {
  return (
    <LoadingButton
      onClick={onClick}
      startIcon={<AddIcon sx={{ color: white }} />}
      size="small"
      variant="contained"
      sx={{
        backgroundColor: green.A700,
        width: size === 'small' ? '100px' : '175px',
        '&:hover': {
          backgroundColor: green.A400,
        },
        ...sx,
      }}
      disabled={disabled}
    >
      {text}
    </LoadingButton>
  );
}

AddButton.propTypes = {
  sx: PropTypes.shape({}),
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
};

AddButton.defaultProps = {
  sx: {},
  text: '',
  disabled: false,
  size: 'small',
};
