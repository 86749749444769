import { Box, Typography } from '@mui/material';
import { HEADER_HEIGHT } from '../../../constants/styles';

export default function Error() {
  return (
    <Box sx={{
      width: '100%',
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Typography fontSize={25}>
        This page doesn`t exist 😕
      </Typography>
    </Box>
  );
}
