import {
  useContext, useState, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import forgotPassword from '../../../services/auth/forgotPassword';
import verifyEmailReq from '../../../services/auth/verifyEmail';
import newPassword from '../../../services/auth/newPassword';
import { PopupContext, POPUP_TYPES, ALERT_SEVERITIES } from '../../../context';
import { AUTHORIZATION_ROUTE } from '../../../constants/route';

import ArrowIcon from '../../../assets/icons/NavigationArrowIcon';
import styles from './RestoreCodeStyles';

export default function RestoreCode() {
  const codeInput = useRef();
  const passwordInput = useRef();
  const dispatchPopup = useContext(PopupContext);

  const [email, setEmail] = useState('');
  const [codeSent, setCodeSent] = useState(false);

  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const navigation = useNavigate();

  const sendCodeToEmail = async () => {
    try {
      await forgotPassword({ email });
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        severity: ALERT_SEVERITIES.SUCCESS,
        text: `Verification code sent to ${email}`,
      });
      setCodeSent(true);
      codeInput.current.focus();
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
  };

  const sendCodeToRestorePassword = async () => {
    try {
      await verifyEmailReq({ email, code, type: 'forget-password' });
      await newPassword({ email, code, password });
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        severity: ALERT_SEVERITIES.SUCCESS,
        text: 'Your password was updated',
      });
      navigation(AUTHORIZATION_ROUTE);
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
  };

  return (
    <>
      <Stack
        sx={{ cursor: 'pointer' }}
        direction="row"
        alignItems="center"
        onClick={() => navigation(AUTHORIZATION_ROUTE)}
      >
        <ArrowIcon />
        Back
      </Stack>
      <br />
      <br />
      <Stack direction="row">
        <TextField
          sx={styles.input}
          name="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          label="Email"
          variant="outlined"
        />
        <br />
        <LoadingButton
          disabled={!email}
          onClick={sendCodeToEmail}
          variant="contained"
          sx={{ width: '150px' }}
        >
          Send code
        </LoadingButton>
      </Stack>
      <br />
      <TextField
        sx={styles.input}
        name="Code"
        value={code}
        disabled={!codeSent}
        onChange={(e) => setCode(e.target.value)}
        inputRef={codeInput}
        label="Code"
        variant="outlined"
      />
      <br />
      <Stack direction="row">
        <TextField
          sx={styles.input}
          name="New password"
          value={password}
          disabled={!codeSent}
          onChange={(e) => setPassword(e.target.value)}
          inputRef={passwordInput}
          label="New password"
          variant="outlined"
        />
        <br />
        <LoadingButton
          onClick={sendCodeToRestorePassword}
          disabled={!code || !password}
          variant="contained"
          sx={{ width: '150px' }}
        >
          Set password
        </LoadingButton>
      </Stack>
    </>
  );
}
