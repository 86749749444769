import { Divider, FormControl, Grid } from '@mui/material';

import PropTypes from 'prop-types';
import { memo } from 'react';
import { Controller } from 'react-hook-form';

function LineContent({
  form, index, nested,
}) {
  return (
    <Controller
      control={form.control}
      name={`${nested}[content][${index}][text]`}
      render={({ field }) => {
        if (!field.value) {
          field.onChange('line');
        }
        return (
          <FormControl sx={{ width: '100%' }}>
            <input {...field} value="line" hidden style={{ display: 'hidden' }} />
            <Divider sx={{ height: '0.1rem', marginTop: '2rem', backgroundColor: 'rgba(0, 0, 0, 0.4)' }} />
          </FormControl>
        );
      }}
    />
  );
}

LineContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
};
LineContent.defaultProps = {};

export default memo(LineContent);
