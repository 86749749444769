import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

export default function Main() {
  return (
    <Box sx={{ padding: '16px 48px 16px 32px' }}>
      <Outlet />
      <Box sx={{
        position: 'fixed',
        bottom: '8px',
      }}
      />
    </Box>
  );
}
