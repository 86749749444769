import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import Logo from '../../assets/Logo.png';
import styles from './AuthorizationStyles';

export default function Authorization() {
  return (
    <Box sx={styles.wrapper}>
      <img
        src={Logo}
        alt="Ginger Snail"
      />
      <Box sx={styles.authorization}>
        <Outlet />
      </Box>
    </Box>
  );
}
