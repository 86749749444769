import { format } from 'light-date';
import * as lo from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { useUserList } from '../../../services';
import Table from '../../../components/Table/Table';
import { makeOrder } from '../../../utils/order';
import { makeQueryMeta } from '../../../utils/requestBuilder';

export default function Users() {
  const [searchParams] = useSearchParams();

  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const makeQuery = () => {
    const order = makeOrder(searchParams);
    if (Object.keys(order).length) {
      queryMeta.order = {};
    }

    lo.forEach(order, (ord, key) => {
      if (key === 'profile') {
        queryMeta.order['profile.firstName'] = ord;
        queryMeta.order['profile.lastName'] = ord;
      } else if (key === 'employment') {
        queryMeta.order['employment.employment.name'] = ord;
      } else if (key === 'occupation') {
        queryMeta.order['employment.occupancy.name'] = ord;
      } else {
        queryMeta.order[key] = ord;
      }
    });

    return { queryMeta };
  };

  const userList = useUserList(makeQuery());

  const columns = [
    {
      field: 'profile',
      name: 'Full Name',
      showOrder: true,
      renderCell: ({ row: { profile } }) => {
        let fullName = '';
        if (profile) {
          if (profile?.firstName) {
            fullName += profile.firstName;
          }
          if (profile?.lastName) {
            fullName += ` ${profile.lastName}`;
          }
        }
        return fullName;
      },
      width: 200,
    },
    {
      field: 'email',
      name: 'Email',
      showOrder: true,
      renderCell: (value) => {
        return (
          <a href={`mailto:${value.formattedValue}`}>
            {value.formattedValue}
          </a>
        );
      },
      width: 300,
    },
    {
      field: 'userId',
      name: 'User ID',
      showOrder: false,
      renderCell: (value) => value.row.profile?.user?.userId,
      width: 100,
    },
    {
      field: 'employment',
      name: 'Employment',
      showOrder: true,
      renderCell: (value) => value.row.employment?.employment?.name,
      width: 215,
    },
    {
      field: 'occupation',
      name: 'Occupation',
      showOrder: true,
      renderCell: (value) => value.row.employment?.occupancy?.name,
      width: 250,
    },
    {
      field: 'createdAt',
      name: 'Registered',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.createdAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
  ];
  return (
    <Table
      columns={columns}
      rows={lo.map(userList?.data?.data, (val) => ({ id: val.uuid, ...val })) || []}
      count={userList?.data?.meta?.count || 0}
      loading={userList.isLoading}
      rowsPerPage={queryMeta.limit}
    />
  );
}
