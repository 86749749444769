export default {
  authorization: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  input: {
    width: '288px',
  },
};
