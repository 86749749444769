import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

export default async (body) => {
  return fetch(`${config.api.url}${config.endpoints.learning}module/limit`, {
    method: 'PUT',
    headers: {
      Authorization: authorization(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      return responseHandler(response, 'Save module/content limitation:');
    });
};
