import { useMutation } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ order }) => {
  const query = queryString.stringify({
    jurisdictionUuid: localStorage.jurisdiction,
  });
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}help/section/order?${query}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: authorization() },
      body: JSON.stringify({ order }),
    },
  );
  return responseHandler(await res.json(), 'Change help center order:');
};
export const usePutHelpSectionOrderMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['putHelpSectionOrder', ...(options.mutationKey || [])], mutationFn });
};
