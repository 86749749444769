import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (params, options) => {
  const query = queryString.stringify({
    attributeMeta: {
      exclude: [
        ...DEFAULT_EXCLUDE,
        ...(params.attributeMeta?.exclude || []),
        'contentSequence',
      ],
    },
    queryMeta: {
      ...params.queryMeta,
      order: params.queryMeta?.order || {
        createdAt: 'DESC',
      },
    },
    filterMeta: params.filterMeta,
    includeMeta: [
      { association: 'content', order: [['sequence', 'ASC']], separate: true },
    ],
  });

  const res = await fetch(`${config.api.url}${config.endpoints.learning}statute?${query}`, {
    headers: {
      'Accept-Encoding': 'application/json',
      Authorization: authorization(),
    },
  });
  const data = await res.json();
  data.data = data.data
    .map((quiz) => ({
      ...quiz, loading: false, id: quiz.uuid, initialSequence: quiz.sequence,
    }))
    .map((quiz, index) => ({ ...quiz, index }));

  const resp = responseHandler(data, 'Get statutes list:');
  if (options.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useStatuteList = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['StatuteList', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
