import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

export default async (uuid) => {
  const query = queryString.stringify({
    jurisdictionUuid: localStorage.jurisdiction,
    attributeMeta: {
      exclude: DEFAULT_EXCLUDE,
    },
    includeMeta: [
      { association: 'modules' },
    ],
  });

  return fetch(`${config.api.url}${config.endpoints.subscription
  }bundle/${uuid}?${query}`, {
    headers: {
      Authorization: authorization(),
    },
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Get bundle:');
    });
};
