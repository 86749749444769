import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Divider, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import findLastSequence from '../../../../../utils/findLastSequence';

import PopUp from '../../../../../components/PopUp/PopUp';
import Input from '../../../../../components/Input/Input';
import Provision from './Provision/Provision';
import AddButton from '../../../../../components/AddButton/AddButton';
import DeleteIcon from '../../../../../assets/icons/DeleteIcon';

export default function StatuteEditor({
  statute, close, save,
}) {
  const [name, setName] = useState(statute.name);
  const [abbreviation, setAbbreviation] = useState(statute.abbreviation);
  const [content, setContent] = useState(_.cloneDeep(statute.content));

  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    setSaveDisabled(!(
      ((name && content.length)
            && (content.every((_content) => (
              _content.referenceNumber && _content.heading
            ))))
        && (name !== statute.name
            || abbreviation !== statute.abbreviation
            || content.length !== statute.content.length
            || content.some((_content, index) => (
              !_.isEqual(_content, statute.content[index])
            ))
        )
    ));
  }, [name, abbreviation, content]);

  const onSave = () => {
    setLoading(true);
    const data = {
      name, abbreviation, content,
    };

    save(data, statute.uuid, () => {
      setLoading(false);
      close();
    });
  };

  const addSection = () => {
    const sequence = findLastSequence(content) + 1;

    setContent([...content, {
      sequence,
      referenceNumber: '',
      heading: '',
      provision: [],
      id: `${Math.random()}`,
    }]);
  };

  const deleteContent = (index) => {
    const newSections = content.slice();
    newSections.splice(index, 1);
    setContent(newSections);
  };

  const changeContent = (index, key, value) => {
    const newSections = content.slice();
    newSections[index][key] = key === 'referenceNumber'
      ? (value.match(/\d{0,3}/)?.[0] || '')
      : value;
    setContent(newSections);
  };

  const changeProvision = (index, provisionIndex, key, value) => {
    const newSections = content.slice();
    newSections[index].provision[provisionIndex][key] = value;
    setContent(newSections);
  };

  const addProvision = (index) => {
    const newSections = content.slice();
    const sequence = findLastSequence(newSections[index].provision) + 1;

    newSections[index].provision.push({
      sequence,
      marker: '',
      text: '',
      subSection: 1,
    });

    setContent(newSections);
  };

  const deleteProvision = (index, provisionIndex) => {
    const newSections = content.slice();
    newSections[index].provision.splice(provisionIndex, 1);
    setContent(newSections);
  };

  const moveProvision = (index, drag, hover) => {
    const newSections = content.slice();
    // eslint-disable-next-line max-len
    [newSections[index].provision[drag], newSections[index].provision[hover]] = [newSections[index].provision[hover], newSections[index].provision[drag]];

    setContent(newSections);
  };

  return (
    <PopUp
      open={!!statute}
      close={close}
    >
      <Input
        inputName="Legislation/Regular Name"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <br />
      <Input
        inputName="Abbreviation"
        onChange={(e) => setAbbreviation(e.target.value)}
        value={abbreviation}
        disabled
        disabledName
      />
      <br />
      {(content || []).map((_content, index) => (
        <Box key={_content.id}>
          <br />
          <Divider />
          <br />
          <Input
            inputName={(
              <Typography sx={{ fontWeight: 'bold', display: 'flex' }}>
                <DeleteIcon onClick={() => deleteContent(index)} />
                Section Number/Reference
              </Typography>
              )}
            sx={{ width: '96px' }}
            fullWidth={false}
            onChange={(e) => changeContent(index, 'referenceNumber', e.target.value)}
            value={_content.referenceNumber}
          />
          <br />
          <Input
            inputName="Heading"
            value={_content.heading}
            onChange={(e) => changeContent(index, 'heading', e.target.value)}
          />
          <br />
          <Typography sx={{ fontWeight: 'bold' }}>
            PROVISION
          </Typography>
          <DndProvider backend={HTML5Backend}>
            {(_content.provision || []).map((provision, provisionIndex) => (
              <Provision
                updateTrigger={provision}
                index={index}
                provisionIndex={provisionIndex}
                marker={provision.marker}
                text={provision.text}
                subSection={provision.subSection}
                deleteProvision={deleteProvision}
                changeProvision={changeProvision}
                moveProvision={moveProvision}
                contentLength={_content.length}
              />
            ))}
          </DndProvider>
          <br />
          <AddButton
            onClick={() => addProvision(index)}
            sx={{ alignSelf: 'end' }}
            text="Add provision"
            size="large"
          />
        </Box>
      ))}
      <br />
      <Divider />
      <br />
      <AddButton
        onClick={addSection}
        sx={{ alignSelf: 'end' }}
        text="Add Section"
        size="large"
      />
      <br />
      <LoadingButton
        onClick={onSave}
        variant="contained"
        color="primary"
        disabled={saveDisabled}
        loading={loading}
      >
        Save
      </LoadingButton>
    </PopUp>
  );
}

StatuteEditor.propTypes = {
  statute: PropTypes.shape({
    name: PropTypes.string,
    abbreviation: PropTypes.string,
    content: PropTypes.shape([]),
    uuid: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};
