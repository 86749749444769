import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box, Typography, Button,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useJurisdictionList } from '../../services';
import logOut from '../../services/auth/logOut';

import { AUTHORIZATION_ROUTE, useNavigate } from '../../constants/route';
import flags from '../../constants/flags';
import { PopupContext, POPUP_TYPES } from '../../context';

import Select from '../Select/Select';
import styles from './HeaderStyles';

export default function Header({ setAuthorized }) {
  const navigate = useNavigate();
  const dispatchPopup = useContext(PopupContext);
  const [jurisdiction, setJurisdiction] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const pickJurisdiction = (value) => {
    setJurisdiction(value);
    searchParams.set('jurisdiction', value.uuid);
    setSearchParams(searchParams);
  };
  const jurisdictionList = useJurisdictionList(null, {
    onSuccess: (data) => {
      if (!jurisdiction) {
        pickJurisdiction(data[0]);
      }
    },
    key: 'for-select',
  });

  const unAuthorize = () => {
    dispatchPopup({
      type: POPUP_TYPES.DIALOG,
      header: 'Are you sure you want to Log Out?',
      action: async () => {
        try {
          setAuthorized(false);
          dispatchPopup({ type: POPUP_TYPES.CLEAR_ALL });
          navigate(AUTHORIZATION_ROUTE);
          await logOut();
        } catch (e) {
          dispatchPopup({
            type: POPUP_TYPES.ALERT,
            text: e.message,
          });
        }
        localStorage.clear();
        sessionStorage.clear();
      },
      needApprove: false,
    });
  };

  const renderSelectItem = (value) => {
    return (
      <>
        <img
          style={styles.flag}
          src={flags[value.iso]}
          alt={value.iso}
        />
        <span>
          {value.name}
        </span>
      </>
    );
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Select
          label="Jurisdiction"
          loaded={jurisdictionList.isFetched}
          fullWidth={false}
          activeValue={jurisdiction}
          setValue={(event) => pickJurisdiction(event.target.value)}
          values={jurisdictionList.data || []}
          itemNameGetter={renderSelectItem}
          sizeWidth="middle"
        />
        <Box sx={styles.userWrapper}>
          <Box sx={styles.role}>
            <AccountCircleIcon color="primary" />
            <Typography ml={0.8}>
              {localStorage.role || sessionStorage.role}
            </Typography>
          </Box>
          <Button
            size="small"
            variant="contained"
            onClick={unAuthorize}
          >
            Log out
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  setAuthorized: PropTypes.func.isRequired,
};
