import { useMutation } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ order, moduleUuid, jurisdictionUuid }) => {
  const query = queryString.stringify({
    jurisdictionUuid,
    moduleUuid,
  });

  const res = fetch(`${config.api.url}${config.endpoints.learning}statute/order?${query}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization(),
    },
    body: JSON.stringify({ order }),
  });
  return responseHandler(await res.json(), 'Change statute order:');
};
export const usePutOrderStatuteMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['putOrderQuiz', ...(options.mutationKey || [])], mutationFn });
};
