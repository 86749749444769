import { Button } from '@mui/material';
import lo from 'lodash';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import * as contentType from '../../../../../constants/content';
import ContentItem from './contents/ContentItem';

function Content({
  form, limited, nested, types,
}) {
  const fieldArray = useFieldArray({ control: form.control, name: `${nested}[content]` });

  const insert = (index) => {
    const value = {
      type: contentType.BODY.value, text: '', sequence: index + 1, limited: false,
    };
    fieldArray.insert(index, value);
  };
  const remove = (field, index) => {
    const contentVal = { ...field.value, removed: true, text: 'removed' };
    if (contentVal.uuid) {
      fieldArray.update(index, contentVal);
    } else {
      fieldArray.remove(index);
    }
  };

  return (
    <>
      {fieldArray.fields.map((val, index) => (
        !val.removed && (
        <ContentItem
          key={`${val.id || val.uuid}`}
          form={form}
          fieldArray={fieldArray}
          val={val}
          index={index}
          contentTypes={types}
          limited={limited}
          nested={nested}
          insert={insert}
          remove={remove}
        />
        )
      ))}
      {!lo.filter(fieldArray.fields, (val) => !val.removed).length ? (
        <Button
          color="success"
          variant="outlined"
          sx={{ width: '100%' }}
          onClick={() => {
            insert(0);
          }}
        >
          Add
        </Button>
      ) : null}
    </>
  );
}

Content.propTypes = {
  form: PropTypes.object.isRequired,
  limited: PropTypes.bool,
  nested: PropTypes.string,
  types: PropTypes.array.isRequired,
};

Content.defaultProps = {
  limited: false,
  nested: '',
};

export default memo(Content);
