import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ body, uuid }) => {
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}feedback/${uuid}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', Authorization: authorization() },
      body: JSON.stringify(body),
    },
  );
  return responseHandler(await res.json(), 'Save feedback:');
};
export const useFeedbackMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['patchFeedback', ...(options.mutationKey || [])], mutationFn });
};
