import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, Tooltip,
} from '@mui/material';
import lo from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Controller } from 'react-hook-form';
import * as contentType from '../../../../../../constants/content';
import { label } from '../../../../../../utils/string';
import BodyContent from './BodyContent';
import BreakLineContent from './BreakLineContent';
import InputContent from './InputContent';
import LineContent from './LineContent';
import ListContent from './ListContent';
import RelationContent from './RelationContent';
import SubPageContent from './SubPageContent';
import styles from '../ContentStyles';

const inputTypes = [...contentType.HEADER_TYPES, contentType.TITLE, contentType.SAMPLEILLUSTRATION];
const relationTypes = contentType.RELATION_TYPES;
const subpageTypes = contentType.SUBPAGE_TYPES;

function ContentItem({
  form, fieldArray, val, index, contentTypes, limited, nested, remove, insert,
}) {
  const [g1, g2, g3, g4] = [limited ? 2 : 0, limited ? 19 : 21, 2, 1];
  const body = val.type === contentType.BODY.value;
  const list = val.type === contentType.LIST.value;
  const line = val.type === contentType.LINE.value;
  const br = val.type === contentType.BREAK_LINE.value;

  let types = contentTypes;
  const level = nested.split('[content]').filter((cVal) => cVal).length;
  const input = inputTypes.find((val2) => val2.value === val.type);
  const rel = relationTypes.find((val2) => val2.value === val.type);
  const spage = subpageTypes.find((val2) => val2.value === val.type);

  if (level === 3) {
    types = types.filter((sVal) => ![contentType.SUB_PAGE.value, contentType.SUB_PAGE_BULLET.value].includes(sVal.value));
  }

  return (
    <Grid
      sx={styles.contentBody}
      component={FormControl}
      container
      marginBottom={4}
      columns={24}
    >
      {limited
        && (
          <Grid item xs={g1}>
            <Controller
              control={form.control}
              name={`content[${index}][limited]`}
              render={({ fieldState, field }) => {
                return (
                  <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
                    <FormControlLabel
                      {...field}
                      value={field.value}
                      control={<Switch {...field} color="primary" />}
                      label="Limited"
                      labelPlacement="start"
                      error={fieldState.error}
                    />
                  </FormControl>
                );
              }}
            />
          </Grid>
        )}
      <Grid item xs={g2} style={styles.contentItem}>
        {spage && (<SubPageContent form={form} index={index} nested={nested} limited={limited} types={types} subpage={spage} />)}
        {input && (<InputContent form={form} index={index} nested={nested} limited={limited} input={input} />)}
        {rel && (<RelationContent form={form} index={index} nested={nested} limited={limited} relation={rel} />)}
        {body && (<BodyContent form={form} index={index} nested={nested} limited={limited} />)}
        {list && (<ListContent form={form} index={index} nested={nested} limited={limited} />)}
        {br && (<BreakLineContent form={form} index={index} nested={nested} limited={limited} />)}
        {line && (<LineContent form={form} index={index} nested={nested} limited={limited} />)}
      </Grid>
      <Grid item xs={g3} style={styles.contentItem}>
        <Controller
          control={form.control}
          name={`${nested}[content][${index}][type]`}
          render={({ field }) => {
            // console.log('type', field.value);
            return (
              <FormControl sx={{ width: '100%' }}>
                <InputLabel>Type</InputLabel>
                <Select
                  {...field}
                  required
                  disabled={Boolean(spage || rel)}
                  label={label`${field.name}`}
                  onChange={(event) => {
                    if (fieldArray.fields[index]) {
                      field.onChange(event.target.value);
                      fieldArray.update(index, { ...fieldArray.fields[index], type: event.target.value });
                    }
                  }}
                >
                  {types.map((sVal) => (<MenuItem key={sVal.value} value={sVal.value}>{sVal.name}</MenuItem>))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs={g4} style={styles.contentOptions}>
        <Controller
          control={form.control}
          name={`${nested}[content][${index}]`}
          render={({ field }) => {
            const delDisabled = Boolean(
              lo.filter(field.value?.content || [], (sVal) => !sVal.removed).length && spage,
            );
            return (
              <>
                <Tooltip title="Delete">
                  <span style={styles.contentOptionsItem}>
                    <IconButton
                      {...field}
                      value={field.value.uuid}
                      sx={{ padding: 0 }}
                      disabled={delDisabled}
                      onClick={() => remove(field, index)}
                    >
                      <DeleteIcon color={delDisabled ? 'disabled' : 'error'} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Add">
                  <span style={styles.contentOptionsItem}>
                    <IconButton
                      {...field}
                      value={field.value.uuid}
                      sx={{ padding: 0 }}
                      onClick={() => {
                        insert(index + 1);
                      }}
                    >
                      <AddIcon color="success" />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

ContentItem.propTypes = {
  form: PropTypes.object.isRequired,
  limited: PropTypes.bool,
  nested: PropTypes.string,
  contentTypes: PropTypes.array.isRequired,
  val: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fieldArray: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
};
ContentItem.defaultProps = {
  limited: false,
  nested: '',

};
export default memo(ContentItem);
