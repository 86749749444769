import PropTypes from 'prop-types';
import Search from '@mui/icons-material/Search';

export default function SearchIcon({ onClick, sx }) {
  return (
    <Search
      onClick={onClick}
      sx={{
        width: '20px',
        cursor: 'pointer',
        ...sx,
      }}
    />
  );
}

SearchIcon.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};

SearchIcon.defaultProps = {
  onClick: () => {},
  sx: {},
};
