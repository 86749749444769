import PropTypes from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { white } from '../../constants/styles';

const width = {
  small: '5rem',
  large: '10rem',
};
export default function AddLink({
  sx, to, text, disabled, size,
}) {
  return (
    <LoadingButton
      component={Link}
      to={to}
      startIcon={<AddIcon sx={{ color: white }} />}
      size="small"
      variant="contained"
      sx={{
        backgroundColor: green.A700,
        width: width[size],
        '&:hover': {
          backgroundColor: green.A400,
        },
        ...sx,
      }}
      disabled={disabled}
    >
      {text}
    </LoadingButton>
  );
}

AddLink.propTypes = {
  sx: PropTypes.shape({}),
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
};

AddLink.defaultProps = {
  sx: {},
  disabled: false,
  size: 'large',
};
