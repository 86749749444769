import {
  useState, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import lo from 'lodash';
import {
  Box, Checkbox,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Image as ImageIcon,
  DoDisturbOn as DoDisturbOnIcon,
} from '@mui/icons-material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { MAX_CONTENT_NAME_LENGTH_SHORT } from '../../../../../../constants/content';
import { STATUSES } from '../../../../../../constants/statuses';
import { PROP_TYPE_MODULE } from '../../../../../../constants/propTypes';

import PopUp from '../../../../../../components/PopUp/PopUp';
import ModuleIDFields from '../../components/ModuleIDFields/ModuleIDFields';
import Show from '../../../../../../components/Show/Show';
import Select from '../../../../../../components/Select/Select';
import Input from '../../../../../../components/Input/Input';

import styles from './ModuleEditorStyles';

export default function ModuleEditor({ module, save, close }) {
  const ref = useRef();

  const [limited, setLimited] = useState(module.limited);
  const [limitedAlert, setLimitedAlert] = useState(false);

  const [appleIdMonthly, setAppleIdMonthly] = useState(module.purchaseIds.appleIdMonthly
    || '');
  const [appleIdAnnual, setAppleIdAnnual] = useState(module.purchaseIds.appleIdAnnual
    || '');

  const [status, setStatus] = useState(module.status);
  const [name, setName] = useState(module.name);
  const [icon, setIcon] = useState(module.iconUrl);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSaveDisabled(
      !((name && icon)
      && (
        !lo.isEqual({ appleIdMonthly, appleIdAnnual }, module.purchaseIds)
      || limited !== module.limited
          || name !== module.name
          || icon !== module.iconUrl
          || status !== module.status
      )),
    );
  }, [appleIdMonthly, appleIdAnnual, name, icon, status, limited]);

  useEffect(() => {
    setLimitedAlert(limited && limited !== module.limited);
  }, [limited]);

  const fileUpload = (e) => {
    if (e.target.files[0]) {
      setIcon(e.target.files[0]);
    }
  };

  const handleFileUpload = () => {
    ref.current.click();
  };

  const deleteIcon = () => {
    setIcon(module.iconUrl);
    ref.current.value = '';
  };

  const saveModule = () => {
    setLoading(true);
    save({
      name,
      icon,
      status,
      uuid: module.uuid,
      limited,
      purchaseIds: {
        appleIdMonthly,
        appleIdAnnual,
      },
    });
  };

  const memoizedIcon = useMemo(() => {
    return (
      <Show show={!!icon}>
        <img
          style={styles.moduleIcon}
          src={typeof icon === 'string'
            ? icon
            : URL.createObjectURL(icon)}
          alt="icon"
        />
      </Show>
    );
  }, [icon]);

  return (
    <PopUp
      open={!!module}
      close={close}
      header={(
        <Box sx={styles.limited}>
          Limited:
          <Checkbox
            onChange={(e) => setLimited(e.target.checked)}
            checked={limited}
            size="small"
          />
          <Show show={limitedAlert}>
            <Box sx={styles.limitedAlert}>
              All other limitations will be deleted.
              <PriorityHighIcon
                color="primary"
                sx={styles.limitedAlertIcon}
              />
            </Box>
          </Show>
        </Box>
      )}
      fullWidth={false}
    >
      <ModuleIDFields
        productIdMonthly={module.productIdMonthly}
        appleIdMonthly={appleIdMonthly}
        setAppleIdMonthly={setAppleIdMonthly}
        productIdAnnual={module.productIdAnnual}
        appleIdAnnual={appleIdAnnual}
        setAppleIdAnnual={setAppleIdAnnual}
      />
      <Box sx={styles.inputs}>
        <Select
          sx={{ position: 'relative', top: '2px', marginRight: '8px' }}
          label="Status"
          activeValue={status}
          setValue={(e) => setStatus(e.target.value)}
          values={STATUSES}
          itemNameGetter={(value) => value}
          loaded
        />
        <Input
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="name"
          maxCharacterLength={MAX_CONTENT_NAME_LENGTH_SHORT}
        />
        <input
          onChange={(e) => fileUpload(e, module.index)}
          type="file"
          accept="image/png"
          ref={ref}
          style={{ display: 'none' }}
        />
        <ImageIcon
          onClick={() => handleFileUpload()}
          sx={styles.imageIcon}
          color={icon ? 'primary' : 'action'}
        />
      </Box>
      <Box sx={styles.imageWrapper}>
        {/* check is icon just uploaded as File */}
        <Show show={!!icon?.name}>
          <DoDisturbOnIcon
            sx={styles.deleteIcon}
            onClick={deleteIcon}
            color="error"
          />
        </Show>
        {icon && memoizedIcon}
      </Box>
      <br />
      <LoadingButton
        onClick={saveModule}
        variant="contained"
        color="primary"
        disabled={saveDisabled}
        loading={loading}
      >
        Save
      </LoadingButton>
    </PopUp>
  );
}

ModuleEditor.propTypes = {
  module: PROP_TYPE_MODULE.isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
