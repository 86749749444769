import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function PageName({ name }) {
  return (
    <Typography variant="h5">
      {name}
    </Typography>
  );
}

PageName.propTypes = {
  name: PropTypes.string.isRequired,
};
