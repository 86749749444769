import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ uuid }) => {
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}help/section/${uuid}`,
    { method: 'DELETE', headers: { 'Accept-Encoding': 'application/json', Authorization: authorization() } },
  );
  return responseHandler(await res.json(), 'Delete help center:');
};
export const useDeleteHelpSectionMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['deleteHelpSection', ...(options.mutationKey || [])], mutationFn });
};
