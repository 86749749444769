import { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Box, Button, Typography, Stack,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { Map } from 'immutable';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';

import clearHTML from '../../utils/clearHTML';

import Show from '../Show/Show';
import DropDownIcon from '../../assets/icons/DropDownIcon';
import styles from './TextEditorStyles';
import './TextEditor.css';

const H1_BUTTON = { label: 'H1', style: 'header-one', type: 'block' };
const H2_BUTTON = { label: 'H2', style: 'header-two', type: 'block' };
const UL_BUTTON = { label: 'UL', style: 'unordered-list-item', type: 'block' };
const OL_BUTTON = { label: 'OL', style: 'ordered-list-item', type: 'block' };
const KEY_BUTTON = { label: 'KEY', style: 'KEY', type: 'style' };
const BOLD_BUTTON = { label: 'BOLD', style: 'BOLD', type: 'style' };
const ITALIC_BUTTON = { label: 'ITALIC', style: 'ITALIC', type: 'style' };
const UNDERLINE_BUTTON = { label: 'UNDERLINE', style: 'UNDERLINE', type: 'style' };

const BODY_BUTTONS = [
  H1_BUTTON, H2_BUTTON,
  KEY_BUTTON,
  BOLD_BUTTON, ITALIC_BUTTON, UNDERLINE_BUTTON,
];

const LIST_BUTTONS = [
  UL_BUTTON, OL_BUTTON,
  BOLD_BUTTON, ITALIC_BUTTON, UNDERLINE_BUTTON,
];

const KEY = {
  color: blue[800],
};

const styleMap = {
  KEY,
};

const styleToHTML = (style) => {
  if (style === KEY_BUTTON.style) {
    return <span id="key" />;
  }
};

const customConvertFromHTML = (HTML) => {
  return EditorState.createWithContent(convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (node.id === 'key') {
        return currentStyle.add(KEY_BUTTON.style);
      }
      return currentStyle;
    },
  })(HTML));
};

const customConvertToHTML = (editor) => {
  const HTML = convertToHTML({ styleToHTML })(editor);

  return clearHTML(HTML) ? HTML : '';
};

const blockRenderMap = Map({
  paragraph: {
    element: 'p',
  },
  unstyled: {
    element: 'p',
  },
});

export default function TextEditor({
  editorName,
  stylesDropDown, hideStyleButtons, setHideStyleButtons,
  loaded, updateTrigger, sx,
  data, top, editorType, exceptions,
  onChange,
}) {
  const editorExtraProps = {};
  if (editorType === 'body') {
    editorExtraProps.blockRenderMap = blockRenderMap;
  }

  const STYLE_BUTTONS = editorType === 'body' ? BODY_BUTTONS : LIST_BUTTONS;

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorFocus, setEditorFocus] = useState(false);

  const [listType, setListType] = useState('');

  useEffect(() => {
    if (data?.length) {
      const changedEditorState = EditorState
        .moveSelectionToEnd(customConvertFromHTML(data));

      // convert HTML tags to Editor styles
      if (/<ul.*>/.test(data)) {
        setListType(UL_BUTTON.style);
      } else if (/<ol.*>/.test(data)) {
        setListType(OL_BUTTON.style);
      }

      setEditorState(changedEditorState);
    }
  }, [updateTrigger]);

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const onStyleClick = (e, style, type) => {
    e.preventDefault();

    const toggleType = type === 'style' ? 'toggleInlineStyle' : 'toggleBlockType';
    let newEditorState = RichUtils[toggleType](editorState, style);

    // logic to prevent having several types of list in one TextEditor
    if (style === OL_BUTTON.style || style === UL_BUTTON.style) {
      if (listType && listType !== style) {
        const replaceTag = style === OL_BUTTON.style ? '<ol' : '<ul';
        const HTML = customConvertToHTML(newEditorState.getCurrentContent())
          .replace(/<[uo]l/, replaceTag);

        if (clearHTML(HTML)) {
          // put cursor to the end
          const changedEditorState = EditorState
            .moveSelectionToEnd(customConvertFromHTML(HTML));

          newEditorState = EditorState
            .forceSelection(changedEditorState, changedEditorState.getSelection());
        }
      }
      setListType(style);
    }
    setEditorState(newEditorState);
  };

  const inputOnChange = (state) => {
    setEditorState(state);

    onChange(customConvertToHTML(state.getCurrentContent()));
  };

  const currentStyles = editorState.getCurrentInlineStyle();
  const currentBlock = editorState
    .getCurrentContent()
    .getBlockForKey((editorState.getSelection()).getStartKey())
    .getType();

  return (
    <Show show={loaded} alternative="progress">
      <Box sx={styles.editorWrapper}>
        <Box sx={{
          ...styles.header,
          top,
          position: editorFocus
            ? 'sticky'
            : 'static',
        }}
        >
          <Typography sx={styles.editorName}>
            {editorName}
          </Typography>
          <Show show={!hideStyleButtons}>
            <Box sx={{
              ...styles.buttonsWrapper,
              paddingLeft: stylesDropDown ? '32px' : '0px',
            }}
            >
              {STYLE_BUTTONS
                .filter((_style) => (!_.includes(exceptions, _style.label)))
                .map(({ label, style, type }) => (
                  <Button
                    onMouseDown={(e) => onStyleClick(e, style, type)}
                    variant={
                      (type === 'block'
                        ? currentBlock === style
                        : currentStyles.has(style))
                        ? 'contained' : 'outlined'
                  }
                    sx={styles.button}
                    key={label}
                  >
                    {label}
                  </Button>
                ))}
            </Box>
          </Show>
        </Box>
        <Stack sx={{ width: '100%' }} direction="row">
          <Show show={stylesDropDown}>
            <DropDownIcon
              onClick={() => setHideStyleButtons((prev) => !prev)}
              rotate={!hideStyleButtons}
            />
          </Show>
          <Box sx={{ ...styles.editor, ...sx }}>
            <Editor // eslint-disable-next-line react/jsx-props-no-spreading
              {...editorExtraProps}
              blockStyleFn={(block) => {
                const type = block.getType();
                if (type === H1_BUTTON.style || type === H2_BUTTON.style) {
                  return type;
                }
              }}
              customStyleMap={styleMap}
              onFocus={() => setEditorFocus(true)}
              onBlur={() => setEditorFocus(false)}
              editorState={editorState}
              onChange={inputOnChange}
              handleKeyCommand={handleKeyCommand}
            />
          </Box>
        </Stack>
      </Box>
    </Show>
  );
}

TextEditor.propTypes = {
  editorName: PropTypes.string,
  stylesDropDown: PropTypes.bool,
  hideStyleButtons: Promise.bool,
  setHideStyleButtons: PropTypes.func,
  loaded: PropTypes.bool,
  updateTrigger: PropTypes.string,
  sx: PropTypes.shape({}),
  data: PropTypes.string.isRequired,
  top: PropTypes.number,
  editorType: PropTypes.oneOf(['body', 'list']),
  exceptions: PropTypes.arrayOf(PropTypes.oneOf([...BODY_BUTTONS, ...LIST_BUTTONS]
    .map(({ label }) => label))),
  onChange: PropTypes.func,
};

TextEditor.defaultProps = {
  editorName: '',
  stylesDropDown: false,
  hideStyleButtons: false,
  setHideStyleButtons: () => {},
  loaded: true,
  updateTrigger: '',
  sx: {},
  top: -1,
  editorType: 'body',
  exceptions: [KEY_BUTTON.style],
  onChange: () => {},
};
