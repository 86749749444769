import { useNavigate as useBaseNavigation } from 'react-router-dom';

export const AUTHORIZATION_ROUTE = '/';
export const AUTHORIZATION_RESTORE_CODE_ROUTE = '/restore_code';
export const AUTHORIZATION_CREATE_NEW_PASSWORD_ROUTE = '/new_password';

export const MAIN_ROUTE = '/';

export const MODULES = {
  NAME: 'Modules',
  ROUTE: 'module',
  ROUTES: {
    TOPICS: {
      NAME: 'Topics',
      ROUTE: 'topics',
      ROUTES: {
        CONTENT_CREATE: {
          NAME: 'Add',
          ROUTE: 'create',
        },
        CONTENT_UPDATE: {
          NAME: 'Edit',
          ROUTE: 'update/:topic',
        },
      },
    },
    SUB_TOPICS: {
      NAME: 'Sub-Topics',
      ROUTE: 'sub_topics',
      ROUTES: {
        CONTENT_CREATE: {
          NAME: 'Add',
          ROUTE: 'create',
        },
        CONTENT_UPDATE: {
          NAME: 'Edit',
          ROUTE: 'update/:subTopic',
        },
      },
    },
    CASES: {
      NAME: 'Cases',
      ROUTE: 'case',
    },
    STATUTES: {
      NAME: 'Statutes',
      ROUTE: 'statute',
    },
    QUIZZES: {
      NAME: 'Quizzes',
      ROUTE: 'quizzes',
    },
  },
};

export const USERS = {
  NAME: 'Users',
  ROUTE: 'users',
  ROUTES: {},
};

export const USERS_FEEDBACK = {
  NAME: 'Users Feedback',
  ROUTE: 'user_feedback',
  ROUTES: {},
};

export const HELP_CENTER = {
  NAME: 'Help Center',
  ROUTE: 'help_center',
  ROUTES: {},
};

export const TERMS_OF_USE = {
  NAME: 'Terms&Privacy',
  ROUTE: 'terms_of_use',
  ROUTES: {},
};

const makeParam = (url, param) => {
  Object.keys(param).forEach((key) => {
    url = url.replace(`:${key}`, param[key]);
  });
  return url;
};

export const useNavigate = (() => {
  const baseNavigate = useBaseNavigation();

  return (param, url) => {
    const uri = typeof param === 'string' ? param : makeParam(url, param || {});
    baseNavigate(uri);
  };
});
