import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';

import { LearningContext } from '../../../../../../context';
import { MAX_CONTENT_NAME_LENGTH_SHORT } from '../../../../../../constants/content';
import { STATUSES } from '../../../../../../constants/statuses';
import { PROP_TYPE_MODULE } from '../../../../../../constants/propTypes';

import PopUp from '../../../../../../components/PopUp/PopUp';
import { useModuleList } from '../../../../../../services/learning/module/getModules';
import ModuleIDFields from '../../components/ModuleIDFields/ModuleIDFields';
import Select from '../../../../../../components/Select/Select';
import Input from '../../../../../../components/Input/Input';
import Show from '../../../../../../components/Show/Show';
import AddButton from '../../../../../../components/AddButton/AddButton';

import DeleteIcon from '../../../../../../assets/icons/DeleteIcon';
import styles from './ModuleBundleEditorStyles';

export default function ModuleBundleEditor({ bundle, save, close }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleList = useModuleList({ filterMeta: { jurisdictionUuid } }, { key: 'for-bundle' });

  const MAX_MODULES_IN_BUNDLE = 3;
  const MIN_MODULES_IN_BUNDLE = 2;

  const [appleIdMonthly, setAppleIdMonthly] = useState(bundle.purchaseIds.appleIdMonthly || '');
  const [appleIdAnnual, setAppleIdAnnual] = useState(bundle.purchaseIds.appleIdAnnual || '');

  const [status, setStatus] = useState(bundle.status);
  const [name, setName] = useState(bundle.name);

  const [modules, setModules] = useState(_.cloneDeep(bundle.modules));

  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [errorModules, setErrorModules] = useState([]);

  useEffect(() => {
    const errors = [];

    modules
      .map(({ uuid }) => uuid)
      .forEach((uuid, index, array) => {
        if (_.lastIndexOf(array, uuid) !== index) {
          errors.push(uuid);
        }
      });

    setErrorModules(errors);
  }, [modules]);

  const changeModule = (index, uuid) => {
    const newModules = modules.slice();
    newModules[index].uuid = uuid;
    setModules(newModules);
  };

  const deleteModule = (index) => {
    const newModules = modules.slice();
    newModules.splice(index, 1);
    setModules(newModules);
  };

  const addModule = () => {
    const newModules = modules.slice();
    newModules.push({
      uuid: '',
      id: `${Math.random()}`,
    });
    setModules(newModules);
  };

  useEffect(() => {
    setSaveDisabled(
      !(name
      && modules.length >= MIN_MODULES_IN_BUNDLE
      && modules.every(({ uuid }) => uuid)
      && !errorModules.length
      && (!_.isEqual({}, bundle.purchaseIds)
        || name !== bundle.name
        || status !== bundle.status
        || !_.isEqual(modules, bundle.modules)
      )),
    );
  }, [status, name, modules, errorModules]);

  const saveBundle = () => {
    setLoading(true);
    save({
      purchaseIds: { appleIdMonthly, appleIdAnnual },
      name,
      status,
      // TODO temporary hardcoded sequence, waiting fixes on BE
      modules: modules.map(({ uuid }) => ({ uuid, sequence: 1 })),
    }, bundle.uuid);
  };

  return (
    <PopUp
      open={!!bundle}
      close={close}
      header={(
        <Typography
          variant="h6"
        >
          {`${bundle.uuid ? 'Edit' : 'Create new'} Bundle`}
        </Typography>
    )}
      fullWidth={false}
    >
      <ModuleIDFields
        productIdMonthly={bundle.productIdMonthly}
        appleIdMonthly={appleIdMonthly}
        setAppleIdMonthly={setAppleIdMonthly}
        productIdAnnual={bundle.productIdAnnual}
        appleIdAnnual={appleIdAnnual}
        setAppleIdAnnual={setAppleIdAnnual}
      />
      <Box sx={styles.name}>
        <Select
          sx={{ position: 'relative', top: '2px', marginRight: '8px' }}
          label="Status"
          activeValue={status}
          setValue={(e) => setStatus(e.target.value)}
          values={STATUSES}
          itemNameGetter={(value) => value}
          loaded
        />
        <Input
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="name"
          maxCharacterLength={MAX_CONTENT_NAME_LENGTH_SHORT}
        />
      </Box>
      <br />
      {moduleList.isFetched && modules.map((_module, index) => (
        <Box
          sx={styles.module}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Select
            label="Module"
            activeValue={moduleList.data?.data.find((__module) => (_module.uuid === __module.uuid))}
            setValue={(e) => changeModule(index, e.target.value.uuid)}
            values={moduleList.data.data}
            scrolledBottom={() => {}}
            itemNameGetter={(value) => value.name}
            sizeWidth="middle"
            error={errorModules.includes(_module.uuid)}
            loaded
          />
          <DeleteIcon
            onClick={() => deleteModule(index)}
          />
        </Box>
      ))}
      <Show show={modules.length < MAX_MODULES_IN_BUNDLE}>
        <AddButton
          onClick={addModule}
          text="Add module"
          size="large"
        />
      </Show>
      <br />
      <LoadingButton
        onClick={saveBundle}
        variant="contained"
        color="primary"
        loading={loading}
        disabled={saveDisabled}
      >
        Save
      </LoadingButton>
    </PopUp>
  );
}

ModuleBundleEditor.propTypes = {
  bundle: PropTypes.shape({
    productIdAnnual: PropTypes.string,
    productIdMonthly: PropTypes.string,
    purchaseIds: PropTypes.shape({
      appleIdMonthly: PropTypes.string.isRequired,
      appleIdAnnual: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    status: PropTypes.oneOf(STATUSES),
    modules: PropTypes.arrayOf(PROP_TYPE_MODULE),
    uuid: PropTypes.string,
  }).isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
