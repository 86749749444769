import {
  Autocomplete, CircularProgress, FormControl, FormHelperText, TextField,
} from '@mui/material';

import PropTypes from 'prop-types';
import lo from 'lodash';
import {
  memo, Fragment, useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useCasesList, useStatuteList, useSubTopicList } from '../../../../../../services';
import { label } from '../../../../../../utils/string';
import * as contentType from '../../../../../../constants/content';
import styles from '../ContentStyles';

const relations = {
  subtopic: useSubTopicList,
  case: useCasesList,
  caseWithBullets: useCasesList,
  statute: useStatuteList,
};

function RelationContent({
  form, index, nested, relation,
}) {
  const [searchParams] = useSearchParams();

  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleUuid = searchParams.get('module');

  const [open, setOpen] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [webSearchQuery, setWebSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  const params = {
    filterMeta: { jurisdictionUuid, moduleUuid },
    queryMeta: { page, limit },
    attributeMeta: {
      exclude: [contentType.CASE_VALUE,
        contentType.CASE_WITH_BULLETS_VALUE,
        contentType.STATUTE_VALUE].includes(relation.value) ? ['content'] : null,
    },
  };
  if (webSearchQuery) {
    params.filterMeta.tsQuery = { searchVector: webSearchQuery };
  }

  const options = { key: `${nested}[content][${index}]` };
  const list = relations[relation.value](params, {
    ...options,
    enabled: open,
    onSuccess: (data) => setOptionList([...optionList, ...lo.map(data.data, (val) => ({
      content: val.content, id: val.id, text: val.name, type: relation.value, uuid: val.uuid,
    }))]),
  });
  const debounceSearch = lo.debounce((val) => {
    setPage(1);
    setOptionList([]);
    setWebSearchQuery(val);
  }, 300, { maxWait: 400 });
  const debouncePage = lo.debounce((val) => {
    setPage(page + val);
  }, 500, { maxWait: 1000 });
  return (
    <Controller
      control={form.control}
      name={options.key}
      render={({ fieldState, field }) => {
        return (
          <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
            <Autocomplete
              {...field}
              required
              label={label`${relation.name}`}
              options={lo.uniqBy(optionList, 'uuid')}
              slotProps={{ paper: { sx: { overflowY: 'hidden' }, onScrollCapture: () => debouncePage(1) } }}
              error={fieldState.error}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onChange={(event, value) => value && field.onChange({ ...field.value, ...value })}
              onInput={(event) => debounceSearch(event.target.value)}
              isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(iParams) => (
                <TextField
                  {...iParams}
                  sx={{ '& .MuiInputBase-root': styles.input[relation.value] }}
                  label={label`${relation.name}`}
                  InputProps={{
                    ...iParams.InputProps,
                    endAdornment: (
                      <>
                        {list.isLoading && <CircularProgress color="inherit" size={20} />}
                        {iParams.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

RelationContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string,
  relation: PropTypes.object.isRequired,
};
RelationContent.defaultProps = {
  nested: '',
};

export default memo(RelationContent);
