import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE, REQUEST_LIMIT_MIN } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

export const queryFn = async (params, options) => {
  const query = queryString.stringify({
    attributeMeta: {
      exclude: [...DEFAULT_EXCLUDE, 'hasLimits'],
    },
    queryMeta: {
      ...params.queryMeta,
      order: params.queryMeta?.order || {
        createdAt: 'DESC',
      },
    },
    filterMeta: params.filterMeta,
    includeMeta: [
      {
        association: 'limit',
        on: {
          literal: '"limit"."moduleUuid" = "Module"."uuid"',
          topicUuid: { is: 'null' },
          topicContentUuid: { is: 'null' },
          topicCaseUuid: { is: 'null' },
          subTopicUuid: { is: 'null' },
          subTopicContentUuid: { is: 'null' },
          subTopicStatuteUuid: { is: 'null' },
          subTopicCaseUuid: { is: 'null' },
        },
      },
    ],
  });

  const res = await fetch(`${config.api.url}${config.endpoints.learning}module?${query}`, {
    headers: {
      'Accept-Encoding': 'application/json',
      Authorization: authorization(),
    },
  });
  const data = await res.json();
  const resp = responseHandler(data, 'Get module list:');
  if (options?.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};

export const useModuleList = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['ModuleList', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
