import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box, FormControl, FormControlLabel, Grid, Switch, TextField,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useContext, memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import * as contentType from '../../../../../constants/content';
import { MODULES, useNavigate } from '../../../../../constants/route';

import {
  useSubTopicContent, usePostSubTopicMutation, usePatchSubTopicMutation, useDeleteSubTopicMutation,
} from '../../../../../services';
import { queryOptions2 } from '../../../../../services/config';
import { useModuleByUuid } from '../../../../../services/learning/module/getModuleByUuid';
import { useSubTopicByUuid } from '../../../../../services/learning/subTopic/getSubTopicByUuid';
import ajv, { resolver } from '../../../../../utils/ajv';

import { PopupContext, POPUP_TYPES, ALERT_SEVERITIES } from '../../../../../context';
import { label } from '../../../../../utils/string';
import Content from '../Content/Content';
import contentHandler from '../utils/contentHandler';

const routes = MODULES.ROUTES;
const types = contentType.SUB_TOPIC_CONTENT_TYPES;

function SubTopicContent() {
  const dispatchPopup = useContext(PopupContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { subTopic } = useParams();
  const [searchParams] = useSearchParams();
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleUuid = searchParams.get('module');

  const schema = subTopic ? 'UpdateSubTopicInput' : 'CreateSubTopicInput';
  const validateInput = ajv.getSchema(schema);

  const queryOptions = { ...queryOptions2, enabled: Boolean(moduleUuid) && Boolean(subTopic) };
  const mutationOptions = {
    ...queryOptions2,
    onSuccess: async () => {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Case changes was successfully saved',
        severity: ALERT_SEVERITIES.INFO,
      });
    },
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
  };
  const activeModule = useModuleByUuid(moduleUuid, queryOptions);
  const item = useSubTopicByUuid(subTopic, queryOptions);
  const content = useSubTopicContent(subTopic, queryOptions);
  const postM = usePostSubTopicMutation(mutationOptions);
  const patchM = usePatchSubTopicMutation(mutationOptions);
  const deleteM = useDeleteSubTopicMutation(mutationOptions);

  const isLoading = () => {
    return activeModule.isFetching
      || item.isFetching
      || content.isFetching
      || postM.isPending
      || patchM.isPending
      || deleteM.isPending;
  };
  const defaultValues = (nItem, nContents) => ({
    name: nItem.data?.name || '',
    limited: activeModule.data?.limited || false,
    jurisdictionUuid,
    moduleUuid,
    content: contentHandler(nContents?.data || {}),
  });

  const form = useForm({
    defaultValues: defaultValues(item, content),
    resolver: resolver.bind(null, validateInput, defaultValues(item, content), ['type', 'relUuid', 'uuid', 'sequence']),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    disabled: isLoading(),
  });

  useEffect(() => {
    if (item.isFetched && content.isFetched) {
      form.reset(defaultValues(item, content));
    }
  }, [item.isFetched, content.isFetched]);
  const isFormDisabled = () => (
    form.formState.disabled
    || !form.formState.isValid
    || form.formState.isLoading
    || form.formState.isSubmitting
    || false);

  const basePath = location.pathname.replace('/create', '');
  const save = async (body) => {
    const res = await postM.mutateAsync({ ...body, moduleUuid, jurisdictionUuid });
    form.unregister();
    navigate({ subTopic: res.uuid }, `${basePath}/${routes.SUB_TOPICS.ROUTES.CONTENT_UPDATE.ROUTE}${location.search}`);
  };
  const update = async (uuid, body) => {
    await patchM.mutateAsync({ uuid, body });
    await form.reset(defaultValues(await item.refetch(), await content.refetch()));
  };
  const remove = (uuid) => {
    dispatchPopup({
      type: POPUP_TYPES.DIALOG,
      header: 'Are you sure you want to delete sub-topic?',
      action: () => deleteM.mutate(uuid),
    });
  };

  return (
    <>
      {isLoading() && (
        <Skeleton
          sx={{
            position: 'fixed',
            width: '100%',
            height: 'calc(100vh - 20em)',
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.8,
            zIndex: 9999,
          }}
          animation="wave"
          variant="rectangular"
          key={subTopic}
        />
      )}
      <form
        id={schema}
        onSubmit={form.handleSubmit(async (data) => {
          if (subTopic) {
            await update(subTopic, data);
          } else {
            await save(data);
          }
        })}
      >
        <Box hidden>
          <Controller
            control={form.control}
            name="[jurisdictionUuid]"
            render={({ field }) => (<TextField {...field} type="hidden" />)}
          />
          <Controller
            control={form.control}
            name="[moduleUuid]"
            render={({ field }) => (<TextField {...field} type="hidden" />)}
          />
        </Box>
        <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 22em)', paddingRight: '0.8rem' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'row' }} component={FormControl} container spacing={2} marginTop={1}>
            {activeModule.data?.limited && (
              <Grid item xs={1}>
                <Controller
                  control={form.control}
                  name="[limited]"
                  render={({ fieldState, field }) => (
                    <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
                      <FormControlLabel
                        label={label`${field.name}`}
                        labelPlacement="start"
                        control={(<Switch {...field} error={fieldState.error} helperText={fieldState.error?.message} />)}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} marginBottom={1}>
              <Controller
                control={form.control}
                name="[name]"
                render={({ fieldState, field }) => {
                  return (
                    <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
                      <TextField
                        {...field}
                        required
                        error={Boolean(fieldState.error)}
                        label={label`${field.name}`}
                        helperText={fieldState.error?.message}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
          <Content
            form={form}
            types={types}
            limited={activeModule.data?.limited}
          />
        </Box>
        <Box pt={4} display="flex" justifyContent="end" boxSizing="border-box">
          <LoadingButton
            type="submit"
            variant="contained"
            color="info"
            state={{ marginRight: '8px' }}
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isDirty || isFormDisabled()}
          >
            Save
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="error"
            onClick={() => remove(subTopic)}
            style={{ marginLeft: '8px', marginRight: '18px' }}
            loading={form.formState.isSubmitting}
            disabled={!form.formState.isDirty || isFormDisabled()}
          >
            Remove
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default memo(SubTopicContent);
