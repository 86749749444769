import PropTypes from 'prop-types';
import {
  Box, Divider, Stack, Typography,
} from '@mui/material';

import Show from '../../../../../../components/Show/Show';
import Input from '../../../../../../components/Input/Input';

import CopyIcon from '../../../../../../assets/icons/CopyIcon';
import styles from './ModuleIDFieldsStyles';

export default function ModuleIDFields({
  productIdMonthly,
  appleIdMonthly,
  setAppleIdMonthly,
  productIdAnnual,
  appleIdAnnual,
  setAppleIdAnnual,
}) {
  const copyId = (idName, id) => {
    navigator.clipboard.writeText(id);
  };

  return (
    <Box sx={{ width: '512px' }}>
      <Show show={!!productIdMonthly}>
        <Typography sx={styles.idHeader}>
          Monthly
        </Typography>
        <Stack alignItems="center" direction="row">
          <Typography sx={styles.idName}>
            Product ID:
          </Typography>
          <Input
            sx={styles.purchaseID}
            value={productIdMonthly}
            disabled
            placeholder="Product ID monthly"
            afterInput={(
              <CopyIcon
                sx={styles.copyIcon}
                onClick={() => {
                  copyId('productIdMonthly', productIdMonthly);
                }}
              />
              )}
          />
        </Stack>
        <Stack alignItems="center" direction="row">
          <Typography sx={styles.idName}>
            Apple ID:
          </Typography>
          <Input
            placeholder="Apple ID monthly"
            sx={styles.purchaseID}
            onChange={(e) => setAppleIdMonthly(e.target.value)}
            value={appleIdMonthly}
          />
        </Stack>
        <Divider />
        <br />
      </Show>
      <Show show={!!productIdAnnual}>
        <Typography sx={styles.idHeader}>
          Annual
        </Typography>
        <Stack alignItems="center" direction="row">
          <Typography sx={styles.idName}>
            Product ID:
          </Typography>
          <Input
            sx={styles.purchaseID}
            placeholder="Product ID annual"
            value={productIdAnnual}
            afterInput={(
              <CopyIcon
                sx={styles.copyIcon}
                onClick={() => {
                  copyId('productIdAnnual', productIdAnnual);
                }}
              />
              )}
            disabled
          />
        </Stack>
        <Stack alignItems="center" direction="row">
          <Typography sx={styles.idName}>
            Apple ID:
          </Typography>
          <Input
            placeholder="Apple ID annual"
            sx={styles.purchaseID}
            onChange={(e) => setAppleIdAnnual(e.target.value)}
            value={appleIdAnnual}
          />
        </Stack>
        <Divider />
        <br />
      </Show>
    </Box>
  );
}

ModuleIDFields.propTypes = {
  productIdMonthly: PropTypes.string,
  appleIdMonthly: PropTypes.string,
  setAppleIdMonthly: PropTypes.func.isRequired,
  productIdAnnual: PropTypes.string,
  appleIdAnnual: PropTypes.string,
  setAppleIdAnnual: PropTypes.func.isRequired,
};

ModuleIDFields.defaultProps = {
  appleIdMonthly: '',
  productIdMonthly: '',
  appleIdAnnual: '',
  productIdAnnual: '',
};
