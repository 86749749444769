import * as lo from 'lodash';
import { useContext, useState } from 'react';
import {
  Stack, Checkbox, Typography, Button, Box,
} from '@mui/material';
import { red, green } from '@mui/material/colors';
import { format } from 'light-date';
import { useSearchParams } from 'react-router-dom';

import { useFeedbackList, useFeedbackMutation } from '../../../services';
import { POPUP_TYPES, PopupContext } from '../../../context';

import Table from '../../../components/Table/Table';
import PopUp from '../../../components/PopUp/PopUp';

import SearchIcon from '../../../assets/icons/SearchIcon';
import { makeOrder } from '../../../utils/order';
import { makeQueryMeta } from '../../../utils/requestBuilder';

export default function UsersFeedback() {
  const dispatchPopup = useContext(PopupContext);

  const [messagePopup, setMessagePopup] = useState('');
  const [searchParams] = useSearchParams();

  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const makeQuery = () => {
    const order = makeOrder(searchParams);
    if (Object.keys(order).length) {
      queryMeta.order = {};
    }

    lo.forEach(order, (ord, key) => {
      if (key === 'profile') {
        queryMeta.order['user.profile.firstName'] = ord;
        queryMeta.order['user.profile.lastName'] = ord;
      } else if (key === 'email') {
        queryMeta.order['user.email'] = ord;
      } else if (key === 'type') {
        queryMeta.order['type.name'] = ord;
      } else {
        queryMeta.order[key] = ord;
      }
    });

    return { queryMeta };
  };

  const feedbackList = useFeedbackList(makeQuery());
  const feedbackMutation = useFeedbackMutation({
    onSuccess: () => feedbackList.refetch(),
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
  });

  const columns = [
    {
      field: 'status',
      name: '',
      showOrder: true,
      renderCell: (value) => {
        return (
          <Checkbox
            checked={value.row.status === 'solved'}
            onChange={(e) => {
              feedbackMutation.mutate({
                body: { status: e.target.checked ? 'solved' : 'pending' },
                uuid: value.row.uuid,
              });
            }}
            size="small"
          />
        );
      },
      width: 115,
    },
    {
      field: 'createdAt',
      name: 'Date',
      showOrder: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '14px' }}>
            {format(new Date(params.row.createdAt), '{dd}/{MM}/{yyyy}')}
          </Typography>
        );
      },
      width: 150,
    },
    {
      field: 'profile',
      name: 'Full Name',
      showOrder: true,
      renderCell: ({ row: { user } }) => {
        if (user) {
          const { profile } = user;
          let fullName = '';
          if (profile) {
            if (profile.firstName) {
              fullName += profile.firstName;
            }
            if (profile.lastName) {
              fullName += ` ${profile.lastName}`;
            }
          }
          return fullName || '—';
        }
      },
      width: 200,
    },
    {
      field: 'userID',
      name: 'User ID',
      showOrder: false,
      renderCell: (value) => {
        return (
          <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '14px' }}>
            {value.row.user.userId}
          </Typography>
        );
      },
      width: 100,
    },
    {
      field: 'email',
      name: 'Email',
      showOrder: true,
      renderCell: (value) => {
        return (
          <a href={`mailto:${value.formattedValue}`}>
            {value.row.user.email}
          </a>
        );
      },
      width: 250,
    },
    {
      field: 'type',
      name: 'Type',
      showOrder: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '14px' }}>
            {params.row.type.name}
          </Typography>
        );
      },
      width: 200,
    },
    {
      field: 'subject',
      name: 'Subject',
      showOrder: false,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontSize: '14px' }}>
            {params.row.subject}
          </Typography>
        );
      },
      width: 175,
    },
    {
      field: 'message',
      name: 'Message',
      showOrder: false,
      renderCell: (value) => {
        return (
          <>
            <Stack
              onClick={() => setMessagePopup(value.row.uuid)}
              direction="row"
              alignItems="center"
            >
              <SearchIcon
                sx={{ marginRight: '4px' }}
              />
              <Typography
                sx={{
                  maxWidth: '300px',
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: '14px',
                }}
                noWrap
              >
                {value.row.message}
              </Typography>
            </Stack>
            <PopUp
              open={messagePopup === value.row.uuid}
              close={() => setMessagePopup('')}
            >
              <Box>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>
                    Status:
                  </span>
                  {' '}
                  <span
                    style={{
                      color:
                        value.row.status === 'pending'
                          ? red[500]
                          : green[500],
                    }}
                  >
                    {value.row.status}
                  </span>
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>
                    User:
                  </span>
                  {' '}
                  {value.row.user.email}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>
                    User ID:
                  </span>
                  {' '}
                  {value.row.user.userId}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Message:
                </Typography>
                <Typography sx={{ hyphens: 'auto' }}>
                  {value.row.message}
                </Typography>
                <br />
                <Button
                  onClick={() => navigator.clipboard.writeText(value.row.message)}
                  variant="outlined"
                >
                  <Typography>
                    Copy Message
                  </Typography>
                </Button>
              </Box>
            </PopUp>
          </>
        );
      },
      width: 350,
    },
  ];
  return (
    <Table
      columns={columns}
      rows={lo.map(feedbackList?.data?.data, (val) => ({ id: val.uuid, ...val })) || []}
      count={feedbackList?.data?.meta?.count || 0}
      loading={feedbackMutation.isPending || feedbackList.isLoading}
      rowsPerPage={queryMeta.limit}
    />
  );
}
