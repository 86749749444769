import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions2 } from '../../config';

const queryFn = async (params, options) => {
  const query = queryString.stringify({ attributeMeta: { exclude: [...DEFAULT_EXCLUDE, 'countryUuid'] } });
  const res = await fetch(
    `${config.api.url}${config.endpoints.info}jurisdiction?${query}`,
    { headers: { 'Accept-Encoding': 'application/json', Authorization: authorization() } },
  );
  const data = await res.json();
  const resp = responseHandler(data, 'Get jurisdictions list:', true);
  if (options.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useJurisdictionList = (params, options) => {
  return useQuery({
    ...options,
    ...params,
    ...queryOptions2,
    queryKey: ['fetchJurisdictionList'],
    queryFn: queryFn.bind(null, params, options),
  });
};
