import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

export default async (name, icon, status) => {
  const body = new FormData();
  body.append('name', name);
  body.append('icon', icon);
  body.append('status', status);
  body.append('jurisdictionUuid', localStorage.jurisdiction);

  return fetch(`${config.api.url}${config.endpoints.learning}module`, {
    method: 'POST',
    headers: {
      Authorization: authorization(),
    },
    body,
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Save module:');
    });
};
