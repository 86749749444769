import queryString from 'qs';

import config from '../../../config/config.json';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

export default async (order) => {
  const query = queryString.stringify({
    jurisdictionUuid: localStorage.jurisdiction,
  });

  return fetch(`${config.api.url}${config.endpoints.learning}module/order?${query}`, {
    method: 'PUT',
    headers: {
      Authorization: authorization(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ order }),
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Change module order:');
    });
};
