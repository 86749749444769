import { useDrag } from 'react-dnd';

export default (accept, index, id) => {
  return useDrag({
    type: accept,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
};
