import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ body, uuid }) => {
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}definition/terms-of-use/${uuid}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: authorization(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );
  return responseHandler(await res.json(), 'Save terms of use:');
};
export const usePatchTermsOfUseMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['patchTermsOfUse', ...(options.mutationKey || [])], mutationFn });
};
