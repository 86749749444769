import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';

import getPageCountMeta from '../../utils/getPageCountMeta';

export default function PaginationComponent({
  page, setPage, meta,
  children, loaded,
}) {
  const pageCount = getPageCountMeta(meta);

  return pageCount > 1 ? (
    <>
      <Pagination
        onChange={(e, _page) => setPage(_page)}
        page={page}
        count={pageCount}
      />
      <br />
      {children}
      <br />
      {loaded && (
      <Pagination
        onChange={(e, _page) => setPage(_page)}
        page={page}
        count={pageCount}
      />
      )}
    </>
  )
    : children;
}

PaginationComponent.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    count: PropTypes.number,
    limit: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
  loaded: PropTypes.bool,
};

PaginationComponent.defaultProps = {
  meta: {
    count: 1,
    limit: 1,
  },
  loaded: false,
};
