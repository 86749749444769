export default {
  limitedAlert: {
    position: 'relative',
    bottom: '4px',
    fontSize: '12px',
  },

  limitedAlertIcon: {
    position: 'relative',
    top: '4px',
  },

  limited: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  inputs: {
    display: 'flex',
    alignItems: 'center',
  },

  imageWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },

  imageIcon: {
    margin: '0 8px',
    cursor: 'pointer',
    imageRendering: 'crisp-edges',
  },

  deleteIcon: {
    position: 'absolute',
    right: '11px',
    cursor: 'pointer',
  },

  moduleIcon: {
    imageRendering: 'crisp-edges',
    height: '128px',
    marginTop: '16px',
    marginLeft: '8px',
  },
};
