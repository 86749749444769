import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async (uuid) => {
  const res = await fetch(`${config.api.url}${config.endpoints.learning}quiz/${uuid}`, {
    method: 'DELETE',
    headers: { 'Accept-Encoding': 'application/json', Authorization: authorization() },
  });
  return responseHandler(await res.json(), 'Delete quiz:');
};
export const useDeleteQuizMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['DeleteQuiz', ...(options.mutationKey || [])], mutationFn });
};
