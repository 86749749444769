import lo from 'lodash';
import {
  CASE, STATUTE, SUB_PAGE, SUB_PAGE_BULLET, SUB_TOPIC,
} from '../../../../../constants/content';

const filterContentValues = (content, type) => {
  const sequence = content.subTopicCaseRel?.sequence
  || content.topicsCasesRel?.sequence
  || content.subTopicsStatuteRel?.sequence
  || content.topicSubTopicRel?.sequence
  || content?.sequence;
  return ({
    limited: content.limited,
    text: content.name || content.text,
    content: content.content || [],
    uuid: content.uuid,
    id: `${content.uuid}${sequence}`,
    relUuid: content.subTopicCaseRel?.relUuid
      || content.topicsCasesRel?.relUuid
      || content.subTopicsStatuteRel?.relUuid
      || content.topicSubTopicRel?.relUuid,
    sequence,
    type: content?.subTopicCaseRel?.type || type || content.type,
    removed: false,
  });
};

// { content: [], cases: [], statutes: [], subtopics: []} => { content: [] }
const contentHandler = (content) => {
  // TITLE/BODY/LIST/HEADER1/HEADER2/BREAK_LINE_VALUE/SAMPLEILLUSTRATION/LINE/SUB_PAGE/SUB_PAGE_BULLET etc
  const contentRest = (content?.content || []).map((_content) => {
    let childrenContent = [];
    if (_content.type === SUB_PAGE.value || _content.type === SUB_PAGE_BULLET.value) {
      childrenContent = contentHandler(_content);
    }

    return filterContentValues({ ..._content, content: childrenContent });
  });

  const contentSubTopics = (content?.subtopics || []).map((subTopic) => (
    filterContentValues(subTopic, SUB_TOPIC.value)
  ));

  const contentCases = (content?.cases || []).map((_case) => (
    filterContentValues(lo.omit(_case, ['content']), CASE.value)
  ));

  const contentStatutes = (content.statutes || []).map((_statute) => (
    filterContentValues(lo.omit(_statute, ['content']), STATUTE.value)
  ));

  return [
    ...contentRest,
    ...contentSubTopics,
    ...contentCases,
    ...contentStatutes,
  ].sort((prev, cur) => (prev.sequence - cur.sequence));
};

export default contentHandler;
