import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (uuid, options) => {
  const query = {};
  const res = await fetch(
    `${config.api.url}${config.endpoints.learning}sub-topic/${uuid}?${queryString.stringify(query)}`,
    {
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: authorization(),
      },
    },
  );
  const data = await res.json();

  const resp = responseHandler(data, 'Get sub-topic:');
  if (options.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useSubTopicByUuid = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['useSubTopicByUuid', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
