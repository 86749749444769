import { PropTypes } from 'prop-types';
import { Button } from '@mui/material';
import Close from '@mui/icons-material/Close';

export default function CloseIcon({ onClick, sx }) {
  return (
    <Button
      onClick={onClick}
      sx={{ cursor: 'pointer', ...sx }}
    >
      <Close />
    </Button>
  );
}

CloseIcon.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};

CloseIcon.defaultProps = {
  onClick: () => {
  },
  sx: {},
};
