import PropTypes from 'prop-types';
import { Dialog, DialogContent, Box } from '@mui/material';

import CloseIcon from '../../assets/icons/CloseIcon';

export default function PopUp({
  open, close,
  header, fullWidth, children,
}) {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: '985px',
        },
      }}
      fullWidth={fullWidth}
    >
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
        >
          {header}
          <Box sx={{ alignSelf: 'end' }}>
            <CloseIcon onClick={close} />
          </Box>
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  );
}

PopUp.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  header: PropTypes.node,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
PopUp.defaultProps = {
  header: <Box sx={{ width: '100px' }} />,
  fullWidth: true,
};
