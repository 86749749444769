import ms from 'ms';
import config from '../config/config.json';

export const queryOptions = {
  refetchOnReconnect: config.queryOptions.refetchOnReconnect,
  refetchInterval: ms(config.queryOptions.refetchInterval || 0),
  refetchIntervalInBackground: config.queryOptions.refetchIntervalInBackground,
  refetchOnMount: config.queryOptions.refetchOnMount,
  refetchOnWindowFocus: config.queryOptions.refetchOnWindowFocus,
};

export const queryOptions2 = {
  refetchOnReconnect: false,
  refetchInterval: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};
