import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (params, options) => {
  const query = {
    attributeMeta: {
      exclude: [
        ...DEFAULT_EXCLUDE,
        'subTopic',
        'subTopicUuid'],
    },
    queryMeta: {
      ...params.queryMeta,
      order: params.queryMeta.order || {
        createdAt: 'DESC',
      },
    },
    filterMeta: params.filterMeta,
    includeMeta: [
      {
        association: 'question',
        include: [{ association: 'answers' }],
      },
      {
        association: 'jurisdiction',
      },
      {
        association: 'topic',
      },
      {
        association: 'subTopic',
      },
      {
        association: 'case',
      },
    ],
  };

  const res = await fetch(
    `${config.api.url}${config.endpoints.learning}quiz?${queryString.stringify(query)}`,
    {
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: authorization(),
      },
    },
  );
  const data = await res.json();
  data.data = data.data
    .map((quiz) => ({
      ...quiz, loading: false, id: quiz.uuid, initialSequence: quiz.sequence,
    }))
    .map((quiz, index) => ({ ...quiz, index }));

  const resp = responseHandler(data, 'Get quizzes list:');
  if (options.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useQuizList = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['QuizList', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
