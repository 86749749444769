import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import dragHandler from '../../utils/dragAndDrop/dragHandler';
import dropHandler from '../../utils/dragAndDrop/dropHandler';

import Show from '../Show/Show';
import Field from '../Field/Field';
import DragIcon from '../../assets/icons/DragIcon';
import DropDownIcon from '../../assets/icons/DropDownIcon';
import Limited from '../../assets/icons/Limited';

import styles from './ItemStyles';

// Component for rendering Item in DnD List
export default function Item({
  item,
  subItems, showSubItems, changeSubItemsVisibility, subItemsHeader,
  index, moveContent,
  saveAlert, _delete, edit, editLoading,
  limited,
}) {
  const dragRef = useRef(null);
  const [, drag] = dragHandler('Item', index, item.uuid);
  const [{ handlerId }, drop] = dropHandler('Item', dragRef, index, moveContent);
  drag(drop(dragRef));

  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDelete = () => {
    setDeleteLoading(true);
    _delete(item.uuid);
  };

  return (
    <Box
      ref={dragRef}
      sx={styles.item}
      data-handler-id={handlerId}
    >
      <Box sx={styles.itemBody}>
        <Box sx={styles.itemInputWrapper}>
          <DragIcon />
          <Show
            show={!!subItems?.length}
            alternative={<Box sx={styles.arrowDropPlug} />}
          >
            <DropDownIcon
              onClick={() => changeSubItemsVisibility(index)}
              rotate={item.showSubTopics}
            />
          </Show>
          <Field sx={{ width: '315px' }}>
            <Typography>
              {item.name}
            </Typography>
          </Field>
          <Alert
            sx={{ marginLeft: '8px', opacity: +saveAlert }}
            severity="success"
          >
            saved!
          </Alert>
        </Box>
        <Box sx={{ ml: '16px' }}>
          <LoadingButton
            onClick={edit}
            size="small"
            variant="contained"
            sx={styles.addButton}
            loading={editLoading}
          >
            Edit
          </LoadingButton>
          <LoadingButton
            onClick={onDelete}
            size="small"
            variant="contained"
            color="error"
            loading={deleteLoading}
          >
            Delete
          </LoadingButton>
        </Box>
      </Box>
      <Show show={showSubItems}>
        <Box sx={{ ml: '64px' }}>
          <br />
          {subItemsHeader}
          {subItems.map((subItem) => (
            <Field
              sx={{ width: '300px', mb: '8px', mt: '8px' }}
              key={subItem.uuid || subItem.id}
            >
              {subItem.name}
            </Field>
          ))}
        </Box>
      </Show>
      <Show show={limited}>
        <Box sx={styles.limited}>
          <Limited />
        </Box>
      </Show>
    </Box>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    showSubTopics: PropTypes.bool,
    uuid: PropTypes.string,
  }).isRequired,
  subItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    uuid: PropTypes.string,
  })),
  showSubItems: PropTypes.bool,
  changeSubItemsVisibility: PropTypes.func,
  subItemsHeader: PropTypes.string,
  index: PropTypes.number.isRequired,
  moveContent: PropTypes.func.isRequired,
  saveAlert: PropTypes.bool,
  _delete: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  editLoading: PropTypes.bool,
  limited: PropTypes.bool,
};

Item.defaultProps = {
  subItems: [],
  showSubItems: false,
  changeSubItemsVisibility: () => {},
  subItemsHeader: '',
  saveAlert: false,
  editLoading: false,
  limited: false,
};
