export default {
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  authorization: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
};
