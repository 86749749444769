import { blue, red } from '@mui/material/colors';
import { MENU_PADDING, white } from '../../constants/styles';

export default {
  menu: {
    position: 'fixed',
    width: MENU_PADDING,
    height: '100vh',
    paddingTop: '38px',
    backgroundColor: blue['800'],
    zIndex: 1,
  },

  nav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  logo: {
    width: '100px',
    marginBottom: '75px',
  },

  link: {
    position: 'relative',
    lineHeight: '3.5',
  },

  notification: {
    position: 'absolute',
    right: '-4px',
    borderRadius: '4px',
    top: '8px',
    zIndex: 1,
    width: '8px',
    height: '8px',
    backgroundColor: red.A400,
  },

  activeButton: {
    width: '175px',
    padding: '7px 10px',
    justifyContent: 'flex-start',
    textAlign: 'left',
    backgroundColor: white,
    color: blue['800'],
    '&:hover': {
      backgroundColor: white,
    },
  },

  inactiveButton: {
    width: '175px',
    padding: '7px 10px',
    justifyContent: 'flex-start',
    textAlign: 'left',
    color: white,
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },

  activeIcon: {
    color: blue['800'],
  },

  inactiveIcon: {
    color: white,
  },

};
