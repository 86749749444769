export default (data, drag, drop, changeSequence = false) => {
  console.log('ddd', drag, drop);
  if (drop > drag) {
    data.splice(drop + 1, 0, data[drag]);
    data.splice(drag, 1);
  } else {
    data.splice(drop, 0, data[drag]);
    data.splice(drag + 1, 1);
  }

  if (changeSequence) {
    data.forEach((item, index) => { (item.sequence = index); });
  }
};

export const move = (data, drag, drop, pCount) => {
  const dragEl = data.splice(drag, 1)[0];

  data.splice(drop, 0, dragEl);

  data.forEach((item, index) => {
    item.sequence = pCount + index + 1;
  });
};
