import config from '../../config/config.json';

import responseHandler from '../../utils/responseHandler';
import authorization from '../authorization';

export default async () => {
  return fetch(`${config.api.url}${config.endpoints.auth}auth/logout`, {
    method: 'PATCH',
    headers: {
      Authorization: authorization(),
    },
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Log out');
    });
};
