import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { REQUEST_LIMIT_MIN } from '../../constants/server';

import TableHeader from './TableHeader/TableHeader';
import TableFooter from './TableFooter/TableFooter';

export default function Table({
  columns, rows, count, rowsPerPage, loading,
}) {
  const renderedColumns = columns.map(({
    field, name, showOrder, renderCell, width,
  }) => {
    return {
      field,
      renderHeader: () => {
        return (
          <TableHeader
            name={name}
            column={field}
            showOrder={showOrder}
          />
        );
      },
      renderCell,
      width,
      sortable: false,
    };
  });

  return (
    <Box sx={{ height: '863px' }}>
      <DataGrid
        columns={renderedColumns}
        rows={rows}
        density="compact"
        rowsPerPageOptions={[rowsPerPage]}
        rowCount={count}
        // onPageChange={setPage}
        loading={loading}
        hideFooterSelectedRowCount
        disableColumnMenu
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          Footer: () => (
            <TableFooter
              count={count}
              rowsPerPage={rowsPerPage}
            />
          ),
        }}
      />
    </Box>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired }),
  ).isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  rowsPerPage: REQUEST_LIMIT_MIN,
};
