import { blue } from '@mui/material/colors';
import { white } from '../../../constants/styles';

export default {
  activeSwitcher: {
    background: blue[700],
    textColor: white,
    color: white,
  },
  wrapper: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
  },

  breadcrumbs: {
    marginLeft: '32px',
  },

  colorWhite: {
    color: 'white',
  },
  colorBlack: {
    color: 'black',
  },
  tooltipButton: {
    margin: 0,
    padding: 0,
    width: 0,
    height: 0,
  },
};
