import PropTypes from 'prop-types';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function DeleteIcon({ onClick, sx }) {
  return (
    <DeleteForeverIcon
      onClick={onClick}
      style={{ cursor: 'pointer', ...sx }}
      color="error"
    />
  );
}

DeleteIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
};

DeleteIcon.defaultProps = {
  sx: {},
};
