import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async (body) => {
  const res = await fetch(`${config.api.url}${config.endpoints.learning}case`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization(),
    },
    body: JSON.stringify(body),
  });
  return responseHandler(await res.json(), 'Save case:');
};
export const usePostCaseMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['postQuizMutation', ...(options.mutationKey || [])], mutationFn });
};
