import { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Typography, Box,
  Divider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';

import { PROP_TYPE_CASE } from '../../../../../constants/propTypes';

import Show from '../../../../../components/Show/Show';
import PopUp from '../../../../../components/PopUp/PopUp';
import Input from '../../../../../components/Input/Input';
import AddButton from '../../../../../components/AddButton/AddButton';
import TextEditor from '../../../../../components/TextEditor/TextEditor';
import Select from '../../../../../components/Select/Select';

import DeleteIcon from '../../../../../assets/icons/DeleteIcon';
import { useCasesList, useSubTopicList } from '../../../../../services';
import styles from './CaseEditorStyles';

export default function CaseEditor({
  _case, close, save,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleUuid = searchParams.get('module');

  const params = { filterMeta: { jurisdictionUuid, moduleUuid } };
  const options = { key: 'for-case' };
  const subTopicList = useSubTopicList(params, options);
  const caseList = useCasesList(params, options);

  const [name, setName] = useState(_case.name);
  const [judgmentReference, setJudgmentReference] = useState(_case.judgmentReference);
  const [factBite, setFactBite] = useState(_case.factBite);
  const [held, setHeld] = useState(_case.held || '');
  const [keywords, setKeywords] = useState(_case.keywords);
  // eslint-disable-next-line max-len
  const [referenceExtracts, setReferenceExtracts] = useState(_.cloneDeep(_case.referenceExtracts) || []);
  const [content, setContent] = useState(_case.content || '');

  const [loading, setLoading] = useState(false);

  const addReference = () => {
    setReferenceExtracts([
      ...referenceExtracts,
      {
        keyReference: '',
        keyPoint: '',
        subTopicUuid: subTopicList.data.data[0].uuid,
        id: Math.random(),
        cases: [],
      }]);
  };

  const deleteReference = (index) => {
    const newReferences = referenceExtracts.slice();
    newReferences.splice(index, 1);
    setReferenceExtracts(newReferences);
  };

  const changeReference = (index, property, value) => {
    const newReferences = referenceExtracts.slice();
    newReferences[index][property] = value;
    setReferenceExtracts(newReferences);
  };

  const addCaseCompare = (index) => {
    const newReferences = referenceExtracts.slice();
    newReferences[index].cases = [...newReferences[index].cases, caseList.data.data[0].uuid];
    setReferenceExtracts(newReferences);
  };

  const changeCaseCompare = (referenceIndex, caseIndex, caseUuid) => {
    const newReferences = referenceExtracts.slice();
    newReferences[referenceIndex].cases[caseIndex] = caseUuid;
    setReferenceExtracts(newReferences);
  };

  const changeKeyword = (index, value) => {
    const newKeywords = keywords.slice();
    newKeywords[index] = value;
    setKeywords(newKeywords);
  };

  const onSave = () => {
    setLoading(true);
    const data = {
      name,
      judgmentReference,
      factBite,
      held,
      keywords: keywords.filter((keyword) => keyword),
      referenceExtracts,
      content,
    };

    save(data, _case.uuid, () => {
      setLoading(false);
      close();
    });
  };

  return (
    <PopUp
      open={!!_case}
      close={close}
    >
      <br />
      <Input
        inputName="CASE NAME"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <br />
      <Input
        inputName="JUDGMENT REFERENCE"
        onChange={(e) => setJudgmentReference(e.target.value)}
        value={judgmentReference}
      />
      <br />
      <TextEditor
        editorName="FACT BITE"
        onChange={(value) => setFactBite(value)}
        data={factBite}
      />
      <br />
      <TextEditor
        editorName="HELD"
        top={-21}
        onChange={setHeld}
        data={held}
      />
      <br />
      <Box sx={styles.keywords}>
        <Input
          inputName="KEYWORDS"
          onChange={(e) => changeKeyword(0, e.target.value)}
          value={keywords[0]}
          maxCharacterLength={20}
        />
        <Input
          sx={{ marginTop: '28px' }}
          onChange={(e) => changeKeyword(1, e.target.value)}
          value={keywords[1]}
          maxCharacterLength={20}
        />
        <Input
          onChange={(e) => changeKeyword(2, e.target.value)}
          value={keywords[2]}
          maxCharacterLength={20}
        />
        <Input
          onChange={(e) => changeKeyword(3, e.target.value)}
          value={keywords[3]}
          maxCharacterLength={20}
        />
        <Input
          onChange={(e) => changeKeyword(4, e.target.value)}
          value={keywords[4]}
          maxCharacterLength={20}
        />
      </Box>
      <br />
      <Divider sx={{ mb: '8px' }} />
      {referenceExtracts.map((reference, index) => (
        <Box sx={{ mb: '16px' }} key={reference.uuid || reference.id}>
          <Typography sx={styles.referenceExtract}>
            <DeleteIcon
              onClick={() => deleteReference(index)}
            />
            {`REFERENCE EXTRACT ${index + 1}`}
          </Typography>
          <Select
            label="Sub-topics"
            activeValue={subTopicList.data?.data.find((val) => (val.uuid === reference.subTopicUuid))}
            setValue={(e) => changeReference(index, 'subTopicUuid', e.target.value.uuid)}
            values={subTopicList.data?.data || []}
            scrolledBottom={() => {}}
            itemNameGetter={(value) => value.name}
            sizeWidth="middle"
            loaded
          />
          <br />
          <br />
          <Input
            inputName="JUDGE NAME"
            onChange={(e) => changeReference(index, 'judgmentReference', e.target.value)}
            value={reference.judgmentReference}
          />
          <br />
          <TextEditor
            editorName="Key reference"
            onChange={(value) => changeReference(index, 'keyReference', value)}
            data={reference.keyReference}
          />
          <br />
          <Input
            inputName="Key point"
            fullWidth
            multiline
            onChange={(e) => changeReference(index, 'keyPoint', e.target.value)}
            value={reference.keyPoint}
          />
          <Box sx={styles.caseCompare}>
            {reference.cases.map((caseCompare, _index) => (
              <>
                <Select
                  label="Case"
                  activeValue={caseList.data?.data.find((val) => (val.uuid === caseCompare.uuid || val.uuid === caseCompare))}
                  setValue={(e) => changeCaseCompare(index, _index, e.target.value.uuid)}
                  values={caseList.data?.data || []}
                  scrolledBottom={() => {}}
                  itemNameGetter={(value) => value.name}
                  sizeWidth="middle"
                  loaded
                />
                <br />
              </>
            ))}
            <AddButton
              onClick={() => addCaseCompare(index)}
              sx={{ mt: '16px' }}
              text="Case Compare"
              size="large"
            />
          </Box>
          <Divider sx={{ mb: '8px', mt: '8px' }} />
        </Box>
      ))}
      <Show show={!!subTopicList.data?.data?.length}>
        <>
          <AddButton
            sx={styles.addButton}
            onClick={addReference}
            text="Add reference"
            size="large"
          />
          <Divider sx={{ mb: '16px' }} />
        </>
      </Show>
      <TextEditor
        exceptions={[]}
        editorName="FULL CASE"
        top={-21}
        onChange={setContent}
        data={content}
      />
      <br />
      <LoadingButton
        onClick={onSave}
        variant="contained"
        color="primary"
        loading={loading}
      >
        Save
      </LoadingButton>
    </PopUp>
  );
}

CaseEditor.propTypes = {
  _case: PROP_TYPE_CASE.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};
