import { useMutation } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ order, moduleUuid, jurisdictionUuid }) => {
  const query = {
    jurisdictionUuid,
    moduleUuid,
  };
  const res = await fetch(
    `${config.api.url}${config.endpoints.learning}quiz/order?${queryString.stringify(query)}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization(),
      },
      body: JSON.stringify({ order }),
    },
  );
  return responseHandler(await res.json(), 'Change quiz order:');
};
export const usePutOrderQuizMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['putOrderQuiz', ...(options.mutationKey || [])], mutationFn });
};
