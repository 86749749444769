import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { Box } from '@mui/material';
import { REQUEST_LIMIT_MIN } from '../../../../../constants/server';

import getModuleBundleByUuid from '../../../../../services/subscription/bundle/getModuleBundleByUuid';
import { useModuleBundleList } from '../../../../../services/subscription/bundle/getModuleBundles';
import postBundle from '../../../../../services/subscription/bundle/postBundle';
import patchBundle from '../../../../../services/subscription/bundle/patchBundle';

import { PopupContext, POPUP_TYPES } from '../../../../../context';
import { ENABLED } from '../../../../../constants/statuses';

import Show from '../../../../../components/Show/Show';
import PageName from '../../../../../components/PageName/PageName';
import Module from '../components/Module/Module';
import ModuleBundleEditor from './ModuleBundleEditor/ModuleBundleEditor';
import AddButton from '../../../../../components/AddButton/AddButton';

import styles from '../ModulesStyles';

export default function ModuleBundlesList() {
  const dispatchPopup = useContext(PopupContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [bundleEditor, setBundleEditor] = useState(null);

  const [saveAlertIds, setSaveAlertIds] = useState([]);

  const makeMeta = () => {
    const newOrder = { };
    const newPage = searchParams.get('page');
    return [{ jurisdictionUuid: searchParams.get('jurisdiction') }, newOrder, newPage, REQUEST_LIMIT_MIN];
  };
  const moduleBundleList = useModuleBundleList(...makeMeta());

  const openBundleEditor = () => {
    setBundleEditor({
      purchaseIds: {
        appleIdMonthly: '',
        appleIdAnnual: '',
      },
      name: '',
      status: ENABLED,
      modules: [],
      save: false,
    });
  };

  useEffect(() => {
    const editorUuid = searchParams.get('uuid');
    if (editorUuid && moduleBundleList?.data?.data?.length) {
      setBundleEditor(moduleBundleList?.data?.data?.find((bundle) => (bundle.uuid === editorUuid)));
    } else {
      setBundleEditor(null);
    }
  }, [searchParams, moduleBundleList?.data?.data.length]);

  const closeBundleEditor = () => {
    searchParams.delete('uuid');
    setSearchParams(searchParams);
    setBundleEditor(null);
  };

  const showSaveAlert = (uuid) => {
    setSaveAlertIds((ids) => [...ids, uuid]);

    setTimeout(() => {
      const alerts = saveAlertIds.slice();
      alerts.splice(alerts.indexOf(uuid), 2);
      setSaveAlertIds(alerts);
    }, 5000);
  };

  const saveBundle = async (bundle) => {
    try {
      const justCreatedBundle = await postBundle(bundle);
      showSaveAlert(justCreatedBundle.uuid);
      await moduleBundleList.refetch();
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
    closeBundleEditor();
  };

  const updateBundle = async (bundle, uuid) => {
    try {
      const updatedBundleData = {};
      const initialBundle = await getModuleBundleByUuid(uuid);

      if (!_.isEqual(initialBundle.purchaseIds, bundle.purchaseIds)) {
        updatedBundleData.purchaseIds = bundle.purchaseIds;
      }
      if (initialBundle.name !== bundle.name) {
        updatedBundleData.name = bundle.name;
      }
      if (initialBundle.status !== bundle.status) {
        updatedBundleData.status = bundle.status;
      }

      const modules = {};
      const addModules = bundle.modules.filter((module) => {
        return !(initialBundle.modules.some((_module) => _module.uuid === module.uuid));
      });

      const removeModule = initialBundle.modules.filter((module) => {
        return !(bundle.modules.some((_module) => _module.uuid === module.uuid));
      })
        .map((module) => module.uuid);

      if (addModules.length || removeModule.length) {
        if (addModules.length) {
          modules.add = addModules;
        }
        if (removeModule.length) {
          modules.remove = removeModule;
        }
      }

      if (_.size(modules)) {
        updatedBundleData.modules = modules;
      }

      if (_.size(updatedBundleData)) {
        await patchBundle(updatedBundleData, uuid);
        showSaveAlert(uuid);
        await moduleBundleList.refetch();
      }
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
    closeBundleEditor();
  };

  return (
    <>
      <Box
        sx={styles.moduleWrapper}
        pb={2}
      >
        <PageName name="Module Bundles" />
        <br />
        <Show show={moduleBundleList.isFetched} alternative="progress">
          <>
            {moduleBundleList?.data?.data.map((bundle, index) => (
              <Module
                module={bundle}
                index={index}
                edit={() => {
                  searchParams.set('uuid', bundle.uuid);
                  setSearchParams(searchParams);
                }}
                saveAlert={saveAlertIds.some((id) => id === bundle.uuid)}
                key={bundle.uuid}
              />
            ))}
            <AddButton
              onClick={openBundleEditor}
              text="add bundle"
              size="large"
            />
          </>
        </Show>
      </Box>
      {bundleEditor
        && (
          <ModuleBundleEditor
            bundle={bundleEditor}
            save={bundleEditor.uuid ? updateBundle : saveBundle}
            close={closeBundleEditor}
          />
        )}
    </>
  );
}
