import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import { black } from '../../constants/styles';

import Show from '../Show/Show';
import styles from './InputStyles';

export default function Input({
  value,
  inputName, sx, fullWidth, inputProps,
  multiline, onChange,
  disabled, disabledName, placeholder,
  maxCharacterLength, afterInput,
}) {
  const [charactersLength, setCharactersLength] = useState(value?.length || 0);
  const [charactersLengthError, setCharactersLengthError] = useState(false);

  return (
    <Box sx={styles.inputWrapper}>
      <Typography sx={{ ...styles.inputName, color: disabledName ? grey[500] : black }}>
        {inputName}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <TextField
          variant="standard"
          type="text"
          fullWidth={fullWidth}
          InputProps={{
            ...inputProps,
            disableUnderline: true,
          }}
          multiline={multiline}
          onChange={(e) => {
            if (!maxCharacterLength || e.target.value.length <= maxCharacterLength) {
              onChange(e);
              setCharactersLength(e.target.value.length);
            } else {
              setCharactersLengthError(true);
              setTimeout(() => {
                setCharactersLengthError(false);
              }, 500);
            }
          }}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          sx={{
            width: fullWidth ? '100%' : '300px',
            ...styles.input,
            ...sx,
          }}
        />
        <Show show={!!maxCharacterLength}>
          <Box sx={{
            ...styles.afterInput,
            color: charactersLengthError ? red[700] : blue[700],
          }}
          >
            {`${charactersLength}/${maxCharacterLength}`}
          </Box>
        </Show>
        <Show show={!!afterInput}>
          <Box sx={styles.afterInput}>
            {afterInput}
          </Box>
        </Show>
      </Box>
    </Box>
  );
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sx: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledName: PropTypes.bool,
  placeholder: PropTypes.string,
  maxCharacterLength: PropTypes.number,
  afterInput: PropTypes.any,
};

Input.defaultProps = {
  inputName: '',
  sx: {},
  fullWidth: true,
  inputProps: {},
  multiline: false,
  onChange: () => {
  },
  value: '',
  disabled: false,
  disabledName: false,
  placeholder: '',
  maxCharacterLength: 0,
  afterInput: false,
};
