import { blue } from '@mui/material/colors';
import { white } from '../../../constants/styles';

export default {
  activeSwitcher: {
    background: blue[700],
    color: white,
  },

  editorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '700px',
    padding: '8px',
  },

  buttonsWrapper: {
    marginBottom: '24px',
  },

  button: {
    marginRight: '8px',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
  },

  editor: {
    width: '700px',
  },
};
