import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as DialogWrapper,
  DialogTitle, DialogContent,
  Box, DialogContentText, DialogActions,
  Checkbox, Button, Typography,
} from '@mui/material';

import Show from '../Show/Show';

export default function Dialog({
  header, text, action, needApprove, onClose,
}) {
  const [sure, setSure] = useState(!needApprove);

  return (
    <DialogWrapper
      fullWidth
      open
    >
      <DialogTitle>
        {header}
      </DialogTitle>
      <Show show={!!text}>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
      </Show>
      <DialogActions sx={{ justifyContent: 'space-between', pl: '22px' }}>
        <Show show={needApprove} alternative={<Box />}>
          <Typography>
            Tick to confirm deletion
            <Checkbox
              checked={sure}
              onChange={(e) => setSure(e.target.checked)}
            />
          </Typography>
        </Show>
        <Box>
          <Button
            onClick={() => {
              action();
              onClose();
            }}
            disabled={!sure}
          >
            Yes
          </Button>
          <Button onClick={onClose}>
            No
          </Button>
        </Box>
      </DialogActions>
    </DialogWrapper>
  );
}

Dialog.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.func,
  needApprove: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  header: '',
  text: '',
  needApprove: true,
  action: () => {},
};
