// handler for server requests
export default (response, prefix = '', responseInData = false) => {
  if (response.statusCode > 299) {
    let errorMessage = '';

    if (typeof response.message === 'string') {
      errorMessage = response.message;
    } else if (Array.isArray(response.message)) {
      errorMessage = response.message.reduce((_message, current) => {
        return `${_message + (
          (typeof current?.constraints === 'object'
                && Object.values(current?.constraints).toString())
            || current.message)};  `;
      }, '');
    }
    throw new Error(`${prefix} ${errorMessage}`);
  } else {
    return responseInData ? response.data : response;
  }
};
