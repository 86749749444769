import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  FormControl, InputLabel,
  Select as SelectMUI, MenuItem,
  Typography, Skeleton, CircularProgress,
} from '@mui/material';

import { red } from '@mui/material/colors';
import { white } from '../../constants/styles';

export default function Select({
  label, loaded, error, disabled,
  activeValue, setValue, values,
  scrolledBottom, scroll,
  itemNameGetter, sizeWidth, sx = {},
}) {
  const [loading, setLoading] = useState(false);
  const isDisabled = disabled || !values?.length;

  let width;
  const height = '48px';

  switch (sizeWidth) {
    case 'small':
      width = '125px';
      break;
    case 'middle':
      width = '250px';
      break;
    case 'large':
      width = '500px';
      break;
    case 'full':
      width = '100%';
      break;
    default:
      width = 'inherit';
  }

  return (loaded)
    ? (
      <FormControl
        sx={{
          minWidth: width,
          maxWidth: width,
          width,
          height,
          backgroundColor: error ? red[100] : white,
          borderRadius: '4px',
        }}
      >
        <InputLabel>
          <Typography
            sx={{ position: 'relative', bottom: isDisabled ? '4px' : 0 }}
            color={isDisabled ? 'error' : 'info'}
          >
            {!values.length ? `Any "${label}" data yet` : label}
          </Typography>
        </InputLabel>
        <SelectMUI
          defaultValue={activeValue || ''}
          MenuProps={{
            PaperProps: {
              onScroll: async () => {
                if (!loading) {
                  setLoading(true);
                  await scrolledBottom();
                  setLoading(false);
                }
              },
              style: {
                maxHeight: scroll ? 160 : 'none',
              },
            },
          }}
          disabled={isDisabled}
          value={activeValue || ''}
          label={label}
          onChange={setValue}
          sx={{ height, ...sx }}
        >
          {values.map((value, i) => (
            <MenuItem
              sx={{
                minWidth: width,
                maxWidth: width,
                width,
                ...sx,
              }}
              value={value || ''}
              key={`${value.uuid}`}
            >
              {itemNameGetter(value)}
            </MenuItem>
          ))}
          {loading
           && (
           <Stack
             direction="row"
             justifyContent="center"
           >
             <CircularProgress />
           </Stack>
           )}
        </SelectMUI>
      </FormControl>
    )
    : (
      <Skeleton
        animation="wave"
        sx={{ borderRadius: '4px', ...sx }}
        variant="rectangular"
        width={width}
        height={height}
      />
    );
}

Select.propTypes = {
  label: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  activeValue: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  scrolledBottom: PropTypes.func,
  scroll: PropTypes.bool,
  itemNameGetter: PropTypes.func.isRequired,
  sizeWidth: PropTypes.oneOf(['small', 'middle', 'large', 'full']),
  sx: PropTypes.shape({}),
};

Select.defaultProps = {
  label: '',
  error: false,
  disabled: false,
  activeValue: null,
  scrolledBottom: () => {},
  scroll: true,
  sizeWidth: 'small',
  sx: {},
};
