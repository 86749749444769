import { FormControl, FormHelperText } from '@mui/material';

import PropTypes from 'prop-types';
import { memo } from 'react';
import { Controller } from 'react-hook-form';
import Editor from './Editor';

function ListContent({
  form, index, nested,
}) {
  const key = `${nested}[content][${index}][text]`;
  return (
    <Controller
      control={form.control}
      name={`${nested}[content][${index}][text]`}
      render={({ fieldState, field }) => {
        return (
          <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
            <Editor
              toolbar="listToolbar"
              field={field}
            />
            <FormHelperText color="error">{fieldState.error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

ListContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
};
ListContent.defaultProps = {};

export default memo(ListContent);
