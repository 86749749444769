import { FormControl } from '@mui/material';

import PropTypes from 'prop-types';
import { memo } from 'react';
import { Controller } from 'react-hook-form';

function BreakLineContent({
  form, index, nested,
}) {
  return (
    <Controller
      control={form.control}
      name={`${nested}[content][${index}][text]`}
      render={({ field }) => {
        if (!field.value) {
          console.log('field', field);
          field.onChange('br');
        }
        return (
          <FormControl sx={{ width: '100%' }}>
            <input {...field} value="br" hidden style={{ display: 'hidden' }} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2%"
              style={{ margin: 'auto', marginTop: '1rem' }}
              fill="currentColor"
              className="bi bi-file-break"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 10.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5M12 0H4a2 2 0 0 0-2 2v7h1V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v7h1V2a2 2 0 0 0-2-2m2 12h-1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2H2v2a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2z"
              />
            </svg>
          </FormControl>
        );
      }}
    />
  );
}

BreakLineContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
};
BreakLineContent.defaultProps = {};

export default memo(BreakLineContent);
