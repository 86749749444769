import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ uuid, body }) => {
  const res = await fetch(`${config.api.url}${config.endpoints.learning}sub-topic/${uuid}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization(),
    },
    body: JSON.stringify(body),
  });
  return responseHandler(await res.json(), 'Save sub-topic:');
};
export const usePatchSubTopicMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['patchSubTopicMutation', ...(options.mutationKey || [])], mutationFn });
};
