export default {
  name: {
    display: 'flex',
    alignItems: 'center',
  },

  module: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
};
