import { blue } from '@mui/material/colors';
import {
  TITLE,
  HEADER1, HEADER2, SAMPLEILLUSTRATION,
  SUB_PAGE, SUB_PAGE_BULLET,
  CASE, CASE_WITH_BULLETS, STATUTE,
} from '../../../../../constants/content';

const styles = {};

styles[TITLE.value] = {
  fontFamily: 'Montserrat, sans-serif',
  color: blue[500],
};

styles[HEADER1.value] = {
  fontSize: '22px', color: '#777', fontWeight: 700,
};

styles[HEADER2.value] = {
  fontSize: '20px', color: '#777', fontWeight: 700, fontStyle: 'italic',
};

styles[SAMPLEILLUSTRATION.value] = {
  fontSize: '18px',
  fontFamily: 'Montserrat, sans-serif',
};

styles[SUB_PAGE.value] = {
  color: '#008EFF',
};

styles[SUB_PAGE_BULLET.value] = {
  color: '#008EFF',
};

styles[CASE.value] = {
  backgroundColor: '#C9E6FF', fontStyle: 'italic',
};

styles[CASE_WITH_BULLETS.value] = {
  backgroundColor: '#C9E6FF', fontStyle: 'italic',
};

styles[STATUTE.value] = {
  backgroundColor: 'rgba(0, 101, 184, 0.13)',
};

export default {
  input: styles,

  contentBody: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0.4rem',
  },
  contentItem: {
    padding: '0 0.2rem',
  },
  contentOptions: {
    textAlign: 'center',
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
    marginTop: '0.2rem',
  },
  contentOptionsItem: {
    margin: '0.1rem',
    display: 'inline-block',
  },
};
