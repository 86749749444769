import PropTypes from 'prop-types';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { blue } from '@mui/material/colors';

export default function DropDownIcon({ onClick, rotate }) {
  return (
    <ArrowDropDownCircleIcon
      onClick={onClick}
      sx={{
        marginRight: '8px',
        transition: 'transform 0.2s ease-in-out 0s',
        color: blue[700],
        cursor: 'pointer',
        transform: `rotate(${rotate ? '180deg' : '0deg'})`,
      }}
    />
  );
}

DropDownIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  rotate: PropTypes.bool,
};

DropDownIcon.defaultProps = {
  rotate: false,
};
