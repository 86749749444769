import { memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubTopicsList from './SubTopicsList';

function SubTopic() {
  const location = useLocation();
  const loadDefault = location.pathname.split('/').length === 3;
  return (
    <>
      {loadDefault && <SubTopicsList />}
      <Outlet />
    </>
  );
}

export default memo(SubTopic);
