export default {
  idHeader: {
    marginBottom: '4px',
    textAlign: 'center',
    fontSize: '17px',
    fontWeight: 'bold',
  },

  idName: {
    position: 'relative',
    bottom: '4px',
    width: '104px',
    marginRight: '4px',
    whiteSpace: 'nowrap',
  },

  purchaseID: {
    marginRight: '8px',
    marginBottom: '16px',
  },

  copyIcon: {
    position: 'relative',
    bottom: '11px',
  },
};
