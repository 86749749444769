import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  useHelpSectionList,
  usePostHelpSectionMutation,
  usePatchHelpSectionMutation,
  useDeleteHelpSectionMutation,
  usePutHelpSectionOrderMutation,
} from '../../../services';
import {
  ALERT_SEVERITIES, POPUP_TYPES, PopupContext, ScrollButtonsContext,
} from '../../../context';
import { ENABLED } from '../../../constants/statuses';
import move from '../../../utils/dragAndDrop/move';

import Show from '../../../components/Show/Show';
import Pagination from '../../../components/Pagination/Pagination';
import Item from '../../../components/Item/Item';
import HelpCenterEditor from './HelpCenterEditor/HelpCenterEditor';
import AddButton from '../../../components/AddButton/AddButton';

export default function HelpCenter() {
  const checkScroll = useContext(ScrollButtonsContext);
  const dispatchPopup = useContext(PopupContext);

  const [initialHelpCenter, setInitialHelpCenter] = useState([]);
  const [helpCenter, setHelpCenter] = useState([]);
  const [helpCenterMeta, setHelpCenterMeta] = useState(null);
  const [helpCenterEditor, setHelpCenterEditor] = useState(null);

  const [saveAlertIds, setSaveAlertIds] = useState([]);
  const [saveOrder, setSaveOrder] = useState(false);
  const [page, setPage] = useState(1);

  const showSaveAlert = (uuid) => {
    setSaveAlertIds((ids) => [...ids, uuid]);

    setTimeout(() => {
      const alerts = saveAlertIds.slice();
      alerts.splice(alerts.indexOf(uuid), 2);
      setSaveAlertIds(alerts);
    }, 5000);
  };

  const helpSectionList = useHelpSectionList({ sequence: 'ASC' }, page);
  const helpSectionOptions = {
    onSuccess: () => helpSectionList.refetch(),
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
    onSettled: (data, error) => {
      if (!error) {
        setHelpCenterEditor(null);
        showSaveAlert(data?.uuid);
      }
    },
  };
  const postHelpSection = usePostHelpSectionMutation(helpSectionOptions);
  const patchHelpSection = usePatchHelpSectionMutation(helpSectionOptions);
  const putHelpSectionOrder = usePutHelpSectionOrderMutation({
    onSuccess: () => helpSectionList.refetch(),
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
    onSettled: (data, error) => {
      if (!error) {
        dispatchPopup({
          type: POPUP_TYPES.ALERT,
          text: 'Help center order was successfully saved',
          severity: ALERT_SEVERITIES.SUCCESS,
        });
        showSaveAlert(data?.uuid);
      }
      setSaveOrder(false);
    },
  });
  const deleteHelpSection = useDeleteHelpSectionMutation({
    ...helpSectionOptions,
    onSettled: (data, error) => !error && dispatchPopup({
      type: POPUP_TYPES.DELETE_ALERT,
      text: 'Question was successfully deleted',
      severity: ALERT_SEVERITIES.INFO,
    }),
  });

  useEffect(() => {
    if (helpSectionList.isFetched) {
      setHelpCenter(helpSectionList.data.data);
      setInitialHelpCenter(helpSectionList.data.data);
      setHelpCenterMeta(helpSectionList.data.meta);
      setTimeout(checkScroll);
    }
    if (helpSectionList.isError) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: helpSectionList.message,
      });
    }
  }, [page, helpSectionList.data]);

  const openQuestionEditor = () => {
    setHelpCenterEditor({
      answer: '',
      question: '',
      status: ENABLED,
    });
  };

  const isLoading = () => {
    return postHelpSection.isPending
      || patchHelpSection.isPending
      || putHelpSectionOrder.isPending
      || deleteHelpSection.isPending
      || helpSectionList.isPending;
  };
  const updateQuestion = (_helpCenter) => {
    const initialQuestion = _.find(
      initialHelpCenter,
      (val) => val.uuid === helpCenterEditor.uuid,
    );
    const updatedQuestion = {};

    _.forEach(Object.keys(_helpCenter), (key) => {
      if (_helpCenter[key] !== initialQuestion[key]) {
        updatedQuestion[key] = _helpCenter[key];
      }
    });

    return patchHelpSection.mutateAsync({
      body: updatedQuestion,
      uuid: helpCenterEditor.uuid,
    });
  };
  const deleteQuestion = (uuid) => {
    dispatchPopup({
      type: POPUP_TYPES.DIALOG,
      header: 'Are you sure you want to delete question?',
      action: () => deleteHelpSection.mutate({ uuid }),
    });
  };
  const moveQuestion = (drag, drop) => {
    const newHelpCenter = helpCenter.slice();
    move(newHelpCenter, drag, drop);

    setSaveOrder(_.some(initialHelpCenter, (question, i) => (
      newHelpCenter[i].uuid !== question.uuid
    )));

    setHelpCenter(newHelpCenter);
  };
  const saveNewOrder = () => {
    const newModuleOrder = _.filter(helpCenter, (module, index) => (
      module?.sequence !== initialHelpCenter[index]?.sequence
    ));

    const sequences = _.map(newModuleOrder, (module) => +module.sequence)
      .sort((a, b) => a - b);

    const reassignedModules = _.map(newModuleOrder, (module, index) => (
      { uuid: module.uuid, sequence: sequences[index] }
    ));

    if (reassignedModules.length) {
      putHelpSectionOrder.mutate({ order: reassignedModules });
    }
  };

  return helpSectionList.isFetched && (
    <DndProvider backend={HTML5Backend}>
      <Show show={!!helpCenterMeta} alternative="progress">
        <Pagination
          page={page}
          setPage={setPage}
          meta={helpCenterMeta}
          loaded={helpSectionList.isFetched}
        >
          <Show show={helpSectionList.isFetched} alternative="progress">
            <>
              <DndProvider backend={HTML5Backend}>
                {helpCenter.map((question, index) => (
                  <Item
                    item={{
                      ...question,
                      name: question.question,
                    }}
                    index={index}
                    moveContent={moveQuestion}
                    saveAlert={saveAlertIds.some((id) => id === question.uuid)}
                    _delete={() => deleteQuestion(question.uuid)}
                    edit={() => setHelpCenterEditor(_.cloneDeep(question))}
                    key={question.uuid}
                  />
                ))}
              </DndProvider>
              <AddButton
                text="add question"
                onClick={openQuestionEditor}
                size="large"
                disabled={isLoading()}
              />
              <LoadingButton
                onClick={saveNewOrder}
                disabled={!saveOrder}
                size="small"
                variant="contained"
                sx={{ ml: '35px' }}
                loading={putHelpSectionOrder.isPending}
              >
                Save order
              </LoadingButton>
            </>
          </Show>
        </Pagination>
      </Show>
      {helpCenterEditor && (
        <HelpCenterEditor
          helpCenter={helpCenterEditor}
          close={() => setHelpCenterEditor(null)}
          save={helpCenterEditor.uuid ? updateQuestion : postHelpSection.mutateAsync}
        />
      )}
    </DndProvider>
  );
}
