import { useState, useEffect } from 'react';
import lo from 'lodash';
import PropTypes from 'prop-types';
import {
  Stack, Box, Typography, Checkbox, Divider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';

import PopUp from '../../../../../components/PopUp/PopUp';
import Select from '../../../../../components/Select/Select';
import TextEditor from '../../../../../components/TextEditor/TextEditor';
import { useCasesList, useTopicList } from '../../../../../services';

export default function QuizzesEditor({
  close, save, quiz,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleUuid = searchParams.get('module');

  const [activeTopic, setActiveTopic] = useState(null);
  const [activeCase, setActiveCase] = useState(null);

  const params = { filterMeta: { jurisdictionUuid, moduleUuid } };
  const options = { key: 'for-quiz' };
  const topicList = useTopicList(params, options);
  const caseList = useCasesList(params, options);

  const [questions, setQuestions] = useState(lo.cloneDeep(quiz.question.content));
  const [answers, setAnswers] = useState(lo.cloneDeep(quiz.question.answers
    .sort((prev, cur) => prev.sequence - cur.sequence)));
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const changeAnswer = (index, key, value) => {
    const newAnswers = answers.slice();
    newAnswers[index][key] = value;
    setAnswers(newAnswers);
  };

  const onSave = () => {
    setLoading(true);
    const data = {
      question: { content: questions, answers, uuid: quiz.question.uuid },
      topicUuid: activeTopic?.uuid,
      caseUuid: activeCase?.uuid,
    };
    save(data, quiz.uuid, () => {
      setLoading(false);
      close();
    });
  };

  useEffect(() => {
    if (topicList.isFetched && caseList.isFetched) {
      if (!activeTopic) {
        setActiveTopic(lo.find(topicList?.data?.data, (val) => (val.uuid === quiz.topicUuid)));
      }
      if (!activeCase) {
        setActiveCase(lo.find(caseList?.data?.data, (val) => (val.uuid === quiz.caseUuid)));
      }
    }
    setSaveDisabled(!(
      activeTopic
      && questions
      && answers.some((answer) => answer.correct)
      && answers.every((answer) => answer.answer)
    ));
  }, [topicList, caseList, activeTopic, questions, answers]);

  return (
    <PopUp
      open={!!quiz}
      close={close}
    >
      <Stack direction="row">
        <Select
          label="Topic"
          loaded={topicList.isFetched}
          activeValue={activeTopic}
          setValue={(e) => setActiveTopic(e.target.value)}
          values={topicList.data?.data || []}
          scrolledBottom={() => {
          }}
          sizeWidth="middle"
          sx={{ marginRight: '16px' }}
          itemNameGetter={(value) => value.name}
        />
        <Select
          label="Case (if any)"
          loaded={caseList.isFetched}
          activeValue={activeCase}
          setValue={(e) => setActiveCase(e.target.value)}
          values={caseList.data?.data || []}
          scrolledBottom={() => {
          }}
          sizeWidth="middle"
          itemNameGetter={(value) => value.name}
        />
      </Stack>
      <br />
      <TextEditor
        editorName="Questions"
        data={questions}
        onChange={(value) => setQuestions(value)}
      />
      <br />
      <Divider />
      <br />
      {answers.map((answer, index) => (
        <Box key={answer.uuid}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontWeight="bold">
              {`Answer ${answer.sequence})`}
            </Typography>
            <Box
              onClick={() => changeAnswer(index, 'correct', !answer.correct)}
              sx={{ cursor: 'pointer' }}
            >
              Correct answer
              <Checkbox checked={answer.correct} />
            </Box>
          </Stack>
          <TextEditor
            data={answer.answer}
            onChange={(value) => changeAnswer(index, 'answer', value)}
          />
          <br />
        </Box>
      ))}
      <LoadingButton
        onClick={onSave}
        loading={loading}
        variant="contained"
        color="primary"
        disabled={saveDisabled}
      >
        Save
      </LoadingButton>
    </PopUp>
  );
}

QuizzesEditor.propTypes = {
  close: PropTypes.func.isRequired,
  quiz: PropTypes.shape({
    question: PropTypes.shape({
      content: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(PropTypes.shape({
        sequence: PropTypes.number.isRequired,
        correct: PropTypes.bool.isRequired,
        answer: PropTypes.string.isRequired,
      })),
      uuid: PropTypes.string,
    }).isRequired,
    topicUuid: PropTypes.string,
    subTopicUuid: PropTypes.string,
    caseUuid: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  save: PropTypes.func.isRequired,
};
