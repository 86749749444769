import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';

export default function Limited() {
  return (
    <Box sx={{
      width: '55px',
      height: '15px',
      paddingRight: '2px',
      borderRadius: '2px',
      backgroundColor: blue.A400,
      fontSize: '12px',
      color: 'white',
      textAlign: 'right',
    }}
    >
      limited
    </Box>
  );
}
