import { blue, grey } from '@mui/material/colors';
import { white } from '../../constants/styles';

export default {
  editorWrapper: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },

  editorName: {
    marginBottom: '4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

  header: {
    paddingTop: '8px',
    backgroundColor: white,
    zIndex: 2,
  },

  buttonsWrapper: {
    marginBottom: '24px',
  },

  button: {
    marginRight: '8px',
  },

  editor: {
    width: '100%',
    padding: '14px 16px',
    border: `1px solid ${grey[400]}`,
    borderRadius: '4px',
    backgroundColor: blue[50],
  },
};
