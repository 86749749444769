import { blue } from '@mui/material/colors';

export default {
  moduleWrapper: {
    marginBottom: '16px',
    position: 'relative',
    right: '8px',
    display: 'flex',
    alignItems: 'center',
  },

  status: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    marginRight: '8px',
  },

  field: {
    width: '300px',
    position: 'relative',
  },

  icon: {
    width: '24px',
    imageRendering: 'crisp-edges',
  },

  divider: {
    margin: '0 8px',
  },

  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    overflow: 'auto',
  },

  limited: {
    position: 'absolute',
    right: '180px',
    top: '-4px',
  },

  editIcon: {
    margin: '0 8px',
    borderLeft: `4px solid ${blue.A400}`,
    borderBottom: `4px solid ${blue.A400}`,
    borderRadius: '2px',
    cursor: 'pointer',
  },
};
