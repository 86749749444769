import { useState, useContext, memo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import lo from 'lodash';
import { format } from 'light-date';
import AddLink from '../../../../../components/AddButton/AddLink';
import Table from '../../../../../components/Table/Table';
import TableDnD from '../../../../../components/Table/TableDnD/TableDnD';
import { MODULES } from '../../../../../constants/route';

import { useSubTopicList, usePutOrderSubTopicMutation, useDeleteSubTopicMutation } from '../../../../../services';

import { move } from '../../../../../utils/dragAndDrop/move';
import { makeOrder } from '../../../../../utils/order';
import { makeQueryMeta } from '../../../../../utils/requestBuilder';

import { PopupContext, POPUP_TYPES, ALERT_SEVERITIES } from '../../../../../context';

const routes = MODULES.ROUTES;

function SubTopicsList() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleUuid = searchParams.get('module');

  const dispatchPopup = useContext(PopupContext);
  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const filterMeta = { jurisdictionUuid, moduleUuid };

  const [subtopics, setSubTopics] = useState([]);
  const [ordering, setOrdering] = useState(false);

  const makeQuery = () => {
    const order = makeOrder(searchParams);
    if (Object.keys(order).length) {
      queryMeta.order = {};
    }

    lo.forEach(order, (ord, key) => {
      queryMeta.order[key] = ord;
    });
    return { queryMeta, filterMeta };
  };
  const list = useSubTopicList(makeQuery(), {
    enabled: !ordering,
    onSuccess: ({ data }) => setSubTopics(data),
  });
  const options = {
    onSuccess: async () => {
      setOrdering(false);
      await list.refetch();
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Case changes was successfully saved',
        severity: ALERT_SEVERITIES.INFO,
      });
      setOrdering(false);
    },
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
  };
  const putOrderM = usePutOrderSubTopicMutation(options);
  const deleteM = useDeleteSubTopicMutation(options);

  const isLoading = () => {
    return list.isFetching
      || putOrderM.isPending
      || deleteM.isPending;
  };

  const moveSubTopic = (drag, drop) => {
    setOrdering(true);
    const swap = subtopics?.slice() || [];
    move(swap, drag, drop, (queryMeta.page - 1) * queryMeta.limit);

    setSubTopics(swap);
  };
  const saveOrder = () => {
    const order = lo.filter(subtopics, (val) => (val.sequence !== val.initialSequence))
      .map(({ sequence, uuid }) => ({ sequence, uuid }));

    putOrderM.mutate({ moduleUuid, jurisdictionUuid, order });
  };

  const remove = (uuid) => {
    dispatchPopup({
      type: POPUP_TYPES.DIALOG,
      header: 'Are you sure you want to delete sub-topic?',
      action: () => deleteM.mutate(uuid),
    });
  };
  const columns = [
    {
      field: 'order',
      name: '',
      type: 'actions',
      showOrder: false,
      renderCell: (value) => (
        <TableDnD id={value.row.uuid} index={value.row.index} move={moveSubTopic} />
      ),
      width: 50,
    },
    {
      field: 'name',
      name: 'Name',
      renderCell: (value) => value.row.name,
      showOrder: true,
      width: 314,
    },
    {
      field: 'updatedAt',
      name: 'UpdatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.updatedAt), '{dd}/{MM}/{yyyy} {HH}:{mm}:{ss}'),
      width: 195,
    },
    {
      field: 'createdAt',
      name: 'CreatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.createdAt), '{dd}/{MM}/{yyyy} {HH}:{mm}:{ss}'),
      width: 195,
    },
    {
      field: 'action',
      name: 'Action',
      type: 'actions',
      showOrder: false,
      width: 185,
      renderCell: (value) => {
        return (
          <>
            <AddLink
              size="small"
              text={routes.SUB_TOPICS.ROUTES.CONTENT_UPDATE.NAME}
              to={`${routes.SUB_TOPICS.ROUTES.CONTENT_UPDATE.ROUTE.replace(':subTopic', value.row.uuid)}${search}`}
              sx={{ marginRight: '8px' }}
            />
            <LoadingButton
              loading={value.row.loading}
              onClick={() => remove(value.row.uuid)}
              size="small"
              variant="contained"
              color="error"
            >
              Delete
            </LoadingButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <AddLink
          text={routes.SUB_TOPICS.ROUTES.CONTENT_CREATE.NAME}
          to={`${routes.SUB_TOPICS.ROUTES.CONTENT_CREATE.ROUTE}${search}`}
        />
      </Stack>
      <br />
      <Table
        columns={columns}
        rows={subtopics}
        count={list.data?.meta?.count || 0}
        loading={isLoading()}
        rowsPerPage={queryMeta.limit}
      />
      <br />
      <Stack direction="row" justifyContent="space-between">
        <LoadingButton
          loading={putOrderM.isPending}
          onClick={saveOrder}
          size="small"
          variant="contained"
          disabled={isLoading() || !ordering}
        >
          Save order
        </LoadingButton>
      </Stack>
    </>
  );
}

export default memo(SubTopicsList);
