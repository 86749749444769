import { blue, grey } from '@mui/material/colors';

export default {
  field: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    padding: '16px',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    borderRadius: '4px',
    border: `1px solid ${grey[400]}`,
    backgroundColor: blue[50],
  },
};
