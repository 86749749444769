import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import lo from 'lodash';

import { Box, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useModuleList } from '../../../../../services/learning/module/getModules';
import postModule from '../../../../../services/learning/module/postModule';
import patchModule from '../../../../../services/learning/module/patchModule';
import moduleLimit from '../../../../../services/learning/module/moduleLimit';
import changeModuleOrder from '../../../../../services/learning/module/changeModuleOrder';
import move from '../../../../../utils/dragAndDrop/move';
import {
  PopupContext, POPUP_TYPES, ALERT_SEVERITIES, ScrollButtonsContext,
} from '../../../../../context';
import { ENABLED } from '../../../../../constants/statuses';

import Show from '../../../../../components/Show/Show';
import Pagination from '../../../../../components/Pagination/Pagination';
import PageName from '../../../../../components/PageName/PageName';
import { makeQueryMeta } from '../../../../../utils/requestBuilder';
import Module from '../components/Module/Module';
import ModuleEditor from './ModuleEditor/ModuleEditor';
import AddButton from '../../../../../components/AddButton/AddButton';

import styles from '../ModulesStyles';

export default function ModulesList() {
  const dispatchPopup = useContext(PopupContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialModules, setInitialModules] = useState([]);
  const [modules, setModules] = useState([]);
  const [modulesMeta, setModulesListMeta] = useState(null);

  const [moduleEditor, setModuleEditor] = useState(null);

  const [saveAlertIds, setSaveAlertIds] = useState([]);
  const [saveOrder, setSaveOrder] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const makeQuery = () => {
    queryMeta.order = { sequence: 'ASC' };
    return {
      queryMeta,
      filterMeta: { jurisdictionUuid },
    };
  };
  const moduleList = useModuleList(makeQuery());

  useEffect(() => {
    if (!moduleList.isPending) {
      moduleList.refetch();
    }

    if (moduleList.isError) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: moduleList.message,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (moduleList.isFetched) {
      setModules(moduleList.data?.data);
      setInitialModules(moduleList.data?.data);
      setModulesListMeta(moduleList.data?.meta);
    }

    if (moduleList.isError) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: moduleList.message,
      });
    }
  }, [moduleList.data]);

  useEffect(() => {
    const editorUuid = searchParams.get('uuid');
    if (editorUuid && modules.length) {
      setModuleEditor(modules.find((module) => (module.uuid === editorUuid)));
    } else {
      setModuleEditor(null);
    }
  }, [searchParams, modules?.length]);

  const openModuleEditor = () => {
    setModuleEditor({
      purchaseIds: {
        appleIdMonthly: '',
        appleIdAnnual: '',
      },
      limited: false,
      status: ENABLED,
      name: '',
      iconUrl: '',
      save: false,
    });
  };
  const closeModuleEditor = () => {
    searchParams.delete('uuid');
    setSearchParams(searchParams);
    setModuleEditor(null);
  };

  const showSaveAlert = (uuid) => {
    setSaveAlertIds((ids) => [...ids, uuid]);

    setTimeout(() => {
      const alerts = saveAlertIds.slice();
      alerts.splice(alerts.indexOf(uuid), 2);
      setSaveAlertIds(alerts);
    }, 5000);
  };

  const saveModule = async (module) => {
    try {
      const justCreatedModule = await postModule(
        module.name,
        module.icon,
        module.status,
        module.purchaseIds,
      );

      if (module.limited) {
        const limit = { add: [{ moduleUuid: justCreatedModule.uuid }] };
        await moduleLimit(limit);
      }
      moduleList.refetch();
      showSaveAlert(justCreatedModule.uuid);
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
    closeModuleEditor();
  };

  const updateModule = async (module) => {
    try {
      const updatedModuleData = { jurisdictionUuid };
      const initialModule = modules.find((val) => val.uuid === module.uuid);

      if (!lo.isEqual(initialModule.purchaseIds, module.purchaseIds)) {
        updatedModuleData.purchaseIds = module.purchaseIds;
      }
      if (initialModule.status !== module.status) {
        updatedModuleData.status = module.status;
      }
      if (initialModule.name !== module.name) {
        updatedModuleData.name = module.name;
      }
      if (initialModule.iconUrl !== module.icon) {
        updatedModuleData.icon = module.icon;
      }

      if (lo.size(updatedModuleData)) {
        await patchModule(updatedModuleData, module.uuid);
      }

      if (initialModule.limited !== module.limited) {
        const limit = {};
        if (module.limited) {
          limit.add = [{ moduleUuid: module.uuid }];
        } else {
          limit.remove = [{ moduleUuid: module.uuid }];
        }
        await moduleLimit(limit);
      }
      moduleList.refetch();
      showSaveAlert(module.uuid);
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
    closeModuleEditor();
  };

  const moveModule = (drag, drop) => {
    const newModules = modules.slice();
    move(newModules, drag, drop);

    setSaveOrder(initialModules.some((_module, i) => (
      newModules[i].uuid !== _module.uuid
    )));

    setModules(newModules);
  };

  const saveNewOrder = async () => {
    setLoading(true);
    try {
      const newModuleOrder = modules.filter((module, index) => {
        return module?.sequence !== initialModules[index]?.sequence;
      });

      const sequences = newModuleOrder.map((module) => +module.sequence)
        .sort((a, b) => a - b);

      const reassignedModules = newModuleOrder.map((module, index) => (
        { uuid: module.uuid, sequence: sequences[index] }
      ));

      if (reassignedModules.length) {
        await changeModuleOrder(reassignedModules);
        moduleList.refetch();
      }
      setSaveOrder(false);
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Modules order was successfully saved',
        severity: ALERT_SEVERITIES.SUCCESS,
      });
    } catch (e) {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: e.message,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        sx={styles.moduleWrapper}
        pb={2}
      >
        <PageName name="Modules" />
        <br />
        <Show show={!!modulesMeta} alternative="progress">
          <Pagination
            meta={modulesMeta}
            page={Number(searchParams.get('page'))}
            setPage={(page) => {
              searchParams.set('page', page);
              setSearchParams(searchParams);
            }}
            loaded={moduleList.isFetched}
          >
            <Show show={moduleList.isFetched} alternative="progress">
              <>
                {modules?.map((module, index) => (
                  <Module
                    module={module}
                    index={index}
                    edit={() => {
                      searchParams.set('uuid', module.uuid);
                      setSearchParams(searchParams);
                    }}
                    moveModule={moveModule}
                    saveAlert={saveAlertIds.some((id) => id === module.uuid)}
                    key={module.uuid}
                  />
                ))}
                <Stack direction="row">
                  <AddButton
                    onClick={openModuleEditor}
                    text="add module"
                    size="large"
                  />
                  <LoadingButton
                    onClick={saveNewOrder}
                    disabled={!saveOrder}
                    size="small"
                    variant="contained"
                    sx={{ ml: '35px' }}
                    loading={loading}
                  >
                    Save order
                  </LoadingButton>
                </Stack>
              </>
            </Show>
          </Pagination>
        </Show>
      </Box>
      {moduleEditor
        && (
        <ModuleEditor
          module={moduleEditor}
          save={moduleEditor.uuid ? updateModule : saveModule}
          close={closeModuleEditor}
        />
        )}
    </>
  );
}
