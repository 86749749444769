import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Divider } from '@mui/material';

import Input from '../../../../../../components/Input/Input';
import TextEditor from '../../../../../../components/TextEditor/TextEditor';
import Select from '../../../../../../components/Select/Select';
import Show from '../../../../../../components/Show/Show';
import dragHandler from '../../../../../../utils/dragAndDrop/dragHandler';
import dropHandler from '../../../../../../utils/dragAndDrop/dropHandler';

import DeleteIcon from '../../../../../../assets/icons/DeleteIcon';
import DragIcon from '../../../../../../assets/icons/DragIcon';

export default function Provision({
  updateTrigger,
  index, provisionIndex, marker, text, subSection,
  deleteProvision, changeProvision, moveProvision,
  contentLength,
}) {
  const dragRef = useRef(null);
  const [, drag] = dragHandler('Provision', provisionIndex, `${provisionIndex}`);
  const [{ handlerId }, drop] = dropHandler(
    'Provision',
    dragRef,
    provisionIndex,
    (_drag, hover) => moveProvision(index, _drag, hover),
  );
  drag(drop(dragRef));

  const [hideStyleButtons, setHideStyleButtons] = useState(true);

  return (
    <Box
      ref={dragRef}
      data-handler-id={handlerId}
    >
      <Stack
        direction="row"
        justifyContent="start"
        alignItems="start"
        sx={{ position: 'relative' }}
      >
        <Stack
          sx={{ paddingTop: hideStyleButtons ? '8px' : '69px' }}
          direction="row"
          alignItems="center"
        >
          <DeleteIcon
            onClick={() => deleteProvision(index, provisionIndex)}
          />
          <DragIcon />
          <Box sx={{ marginRight: '8px' }}>
            <Input
              sx={{ width: '106px' }}
              fullWidth={false}
              onChange={(e) => (
                changeProvision(index, provisionIndex, 'marker', e.target.value)
              )}
              value={marker}
              maxCharacterLength={5}
            />
          </Box>
        </Stack>
        <TextEditor
          stylesDropDown
          hideStyleButtons={hideStyleButtons}
          setHideStyleButtons={setHideStyleButtons}
          sx={{ width: '590px', marginRight: '8px' }}
          data={text}
          updateTrigger={updateTrigger}
          editorType="list"
          onChange={(value) => (
            changeProvision(index, provisionIndex, 'text', value)
          )}
        />
        <Box sx={{ marginTop: hideStyleButtons ? '12px' : '71px', width: '150px' }}>
          <Select
            loaded
            activeValue={subSection}
            setValue={(e) => {
              changeProvision(index, provisionIndex, 'subSection', e.target.value);
            }}
            sizeWidth="full"
            itemNameGetter={(value) => `${value} Subsection`}
            values={[1, 2, 3]}
          />
        </Box>
      </Stack>
      <br />
      <Show show={provisionIndex + 1 < contentLength}>
        <Divider />
      </Show>
    </Box>
  );
}

Provision.propTypes = {
  updateTrigger: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  provisionIndex: PropTypes.number.isRequired,
  marker: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subSection: PropTypes.number.isRequired,
  deleteProvision: PropTypes.func.isRequired,
  changeProvision: PropTypes.func.isRequired,
  moveProvision: PropTypes.func.isRequired,
  contentLength: PropTypes.number.isRequired,
};
