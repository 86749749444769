import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../config/config.json';
import responseHandler from '../../utils/responseHandler';
import authorization from '../authorization';
import { queryOptions } from '../config';

const queryFn = async (params) => {
  const query = {
    queryMeta: {
      ...params.queryMeta,
      order: params.queryMeta.order || {
        createdAt: 'DESC',
      },
    },
    filterMeta: { roles: { contains: 'user' } },
    includeMeta: [
      { association: 'profile' },
      {
        association: 'employment',
        include: [
          { association: 'employment' },
          { association: 'occupancy' },
          { association: 'country' },
        ],
      },
    ],
  };

  const res = await fetch(`${config.api.url}${config.endpoints.user}user?${queryString.stringify(query)}`, {
    headers: {
      Authorization: authorization(),
    },
  });
  return responseHandler(await res.json(), 'Get user list:');
};
export const useUserList = (params, ...options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['fetchUserList', params, options],
    queryFn: queryFn.bind(null, params),
  });
};
