import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import NavigationArrowIcon from '../../../assets/icons/NavigationArrowIcon';
import styles from './TableFooterStyles';

export default function TableFooter({ count, rowsPerPage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page] = useState(Number(searchParams.get('page') || 1));

  const changePage = (direction) => {
    if (direction === 'left') {
      searchParams.set('page', (page - 1));
    } else if (direction === 'right') {
      searchParams.set('page', (page + 1));
    }
    setSearchParams(searchParams);
  };

  const startPage = count <= rowsPerPage ? 1 : (page * rowsPerPage) - rowsPerPage + 1;
  const endPage = page * rowsPerPage < count ? page * rowsPerPage : count;

  return (
    <Box sx={styles.footer}>
      <Typography sx={{ mr: '8px' }}>
        {`${startPage} - ${endPage} of ${count}`}
      </Typography>
      <NavigationArrowIcon
        disabled={page <= 1}
        onClick={() => changePage('left')}
        sx={{ mr: '16px' }}
      />
      <NavigationArrowIcon
        disabled={((page) * rowsPerPage) > count}
        onClick={() => changePage('right')}
        direction="right"
      />
    </Box>
  );
}

TableFooter.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
