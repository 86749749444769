import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import PopupContextProvider from './context/providers/PopUp/PopupContextProvider';

import Main from './pages/Main/Main';

import Modules from './pages/Main/Modules/Modules';
import AddModules from './pages/Main/Modules/AddModules/AddModules';
import Cases from './pages/Main/Modules/Cases/Cases';
import Statute from './pages/Main/Modules/Statute/Statute';
import Quizzes from './pages/Main/Modules/Quizzes/Quizzes';
import SubTopic from './pages/Main/Modules/Topics/subTopic/SubTopic';
import TopicContent from './pages/Main/Modules/Topics/topic/TopicContent';
import SubTopicContent from './pages/Main/Modules/Topics/subTopic/SubTopicContent';
import Topic from './pages/Main/Modules/Topics/topic/Topic';

import UsersFeedback from './pages/Main/UsersFeedback/UsersFeedback';
import Users from './pages/Main/Users/Users';
import HelpCenter from './pages/Main/HelpCenter/HelpCenter';
import TermsPrivacy from './pages/Main/Terms&Privacy/Terms&Privacy';
import Error from './pages/Main/Error/Error';

import Authorization from './pages/Authorization/Authorization';
import SignIn from './pages/Authorization/SignIn/SignIn';
import RestoreCode from './pages/Authorization/RestoreCode/RestoreCode';
import CreateNewPassword from './pages/Authorization/CreateNewPassword/CreateNewPassword';

import Menu from './components/Menu/Menu';
import Header from './components/Header/Header';

import {
  MAIN_ROUTE,
  MODULES,
  USERS,
  USERS_FEEDBACK,
  HELP_CENTER,
  TERMS_OF_USE,
  AUTHORIZATION_ROUTE,
  AUTHORIZATION_RESTORE_CODE_ROUTE,
  AUTHORIZATION_CREATE_NEW_PASSWORD_ROUTE,
} from './constants/route';
import { MENU_PADDING } from './constants/styles';
import { setRumUser } from './rum';

function App() {
  const [authorized, setAuthorized] = useState(
    !!localStorage.token || !!sessionStorage.token,
  );
  const queryClient = new QueryClient();
  setImmediate(() => setRumUser());

  return (
    <QueryClientProvider client={queryClient}>
      <PopupContextProvider>
        {authorized
          ? (
            <>
              <Menu />
              <Box sx={{ paddingLeft: MENU_PADDING }}>
                <Header setAuthorized={setAuthorized} />
                <Routes>
                  <Route path={MAIN_ROUTE} element={<Main />}>
                    <Route path={MODULES.ROUTE} element={<Modules />}>
                      <Route index element={<AddModules />} />
                      <Route path={MODULES.ROUTES.TOPICS.ROUTE} element={<Topic />}>
                        <Route path={MODULES.ROUTES.TOPICS.ROUTES.CONTENT_CREATE.ROUTE} element={<TopicContent />} />
                        <Route path={MODULES.ROUTES.TOPICS.ROUTES.CONTENT_UPDATE.ROUTE} element={<TopicContent />} />
                      </Route>
                      <Route path={MODULES.ROUTES.SUB_TOPICS.ROUTE} element={<SubTopic />}>
                        <Route path={MODULES.ROUTES.SUB_TOPICS.ROUTES.CONTENT_CREATE.ROUTE} element={<SubTopicContent />} />
                        <Route path={MODULES.ROUTES.SUB_TOPICS.ROUTES.CONTENT_UPDATE.ROUTE} element={<SubTopicContent />} />
                      </Route>
                      <Route path={MODULES.ROUTES.CASES.ROUTE} element={<Cases />} />
                      <Route path={MODULES.ROUTES.STATUTES.ROUTE} element={<Statute />} />
                      <Route path={MODULES.ROUTES.QUIZZES.ROUTE} element={<Quizzes />} />
                    </Route>
                    <Route path={USERS.ROUTE} element={<Users />} />
                    <Route path={USERS_FEEDBACK.ROUTE} element={<UsersFeedback />} />
                    <Route path={HELP_CENTER.ROUTE} element={<HelpCenter />} />
                    <Route path={TERMS_OF_USE.ROUTE} element={<TermsPrivacy />} />
                    <Route path="*" element={<Error />} />
                  </Route>
                </Routes>
              </Box>
            </>
          )
          : (
            <Routes>
              <Route element={<Authorization />}>
                <Route
                  path={AUTHORIZATION_ROUTE}
                  element={<SignIn setAuthorized={setAuthorized} />}
                />
                <Route
                  path={AUTHORIZATION_RESTORE_CODE_ROUTE}
                  element={<RestoreCode />}
                />
                <Route
                  path={AUTHORIZATION_CREATE_NEW_PASSWORD_ROUTE}
                  element={<CreateNewPassword />}
                />
              </Route>
              <Route
                path="*"
                element={<Navigate replace to={AUTHORIZATION_ROUTE} />}
              />
            </Routes>
          )}
      </PopupContextProvider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default App;
