import { useReducer, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Alert, Button } from '@mui/material';

import { PopupContext, POPUP_TYPES, ALERT_SEVERITIES } from '../../index';

import Show from '../../../components/Show/Show';
import Dialog from '../../../components/Dialog/Dialog';

import styles from './PopupContextProviderStyles';

function PopupContextProvider({ children }) {
  const [popup, dispatchPopup] = useReducer(
    (state, {
      type, header, severity, text, action, index, needApprove,
    }) => {
      const newAlerts = state.alerts.slice();
      const newDialogs = state.dialogs.slice();

      switch (type) {
        case POPUP_TYPES.DIALOG:
          return {
            ...state,
            dialogs: [...state.dialogs, {
              header, text, action, id: `${Math.random()}`, needApprove,
            }],
          };

        case POPUP_TYPES.ALERT:
          return {
            ...state,
            alerts: [...state.alerts, { text, id: `${Math.random()}`, severity }],
          };

        case POPUP_TYPES.DELETE_ALERT:
          newAlerts.splice(index, 1);
          return { ...state, alerts: newAlerts };

        case POPUP_TYPES.DELETE_DIALOG:
          newDialogs.splice(index, 1);
          return { ...state, dialogs: newDialogs };

        case POPUP_TYPES.CLEAR_ALL:
          return { dialogs: [], alerts: [] };

        case POPUP_TYPES.CLEAR_ALERTS:
          return { ...state, alerts: [] };

        default:
          return state;
      }
    },
    { dialogs: [], alerts: [] },
  );

  useEffect(() => {
    if (popup.alerts.length) {
      setTimeout(() => {
        dispatchPopup({
          type: POPUP_TYPES.DELETE_ALERT,
          index: popup.alerts.length - 1,
        });
      }, 3000);
    }
  }, [popup.alerts.length]);

  const popupValue = useMemo(() => dispatchPopup, [popup]);
  return (
    <>
      <Show show={!!popup.alerts.length}>
        <Box sx={styles.alertWrapper}>
          <Box sx={styles.alertsList}>
            {popup.alerts.map((alert) => {
              return (
                <Alert
                  sx={styles.alert}
                  severity={alert.severity || ALERT_SEVERITIES.ERROR}
                  key={alert.id}
                >
                  {alert.text}
                </Alert>
              );
            })}
          </Box>
          <Show show={popup.alerts.length > 2}>
            <Button
              variant="contained"
              onClick={() => dispatchPopup({ type: POPUP_TYPES.CLEAR_ALERTS })}
              sx={styles.clearAlerts}
            >
              clear all
            </Button>
          </Show>
        </Box>
      </Show>
      {popup.dialogs.map((dialog, index) => {
        return (
          <Dialog
            header={dialog.header}
            text={dialog.text}
            action={dialog.action}
            onClose={() => dispatchPopup({ type: POPUP_TYPES.DELETE_DIALOG, index })}
            needApprove={dialog.needApprove}
            key={dialog.id}
          />
        );
      })}
      <PopupContext.Provider value={popupValue}>
        {children}
      </PopupContext.Provider>
    </>
  );
}
export default PopupContextProvider;

PopupContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
