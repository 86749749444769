export default {
  header: {
    display: 'flex',
    alignItems: 'center',
  },

  filterIcon: {
    marginLeft: '4px',
  },
};
