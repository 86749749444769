import { useQuery } from '@tanstack/react-query';
import config from '../../../config/config.json';
import { REQUEST_LIMIT_MAX } from '../../../constants/server';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async () => {
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}definition/terms-of-use`,
    {
      headers: {
        Authorization: authorization(),
      },
    },
  );
  return responseHandler(await res.json(), 'Get terms of use:', true);
};
export const useDefinitionTermsOfUse = (order = {}, page = 1, limit = REQUEST_LIMIT_MAX, ...options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['fetchTermsOfUse', order, page, limit, options],
    queryFn: queryFn.bind(null, order, page, limit),
  });
};
