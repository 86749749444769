import lo from 'lodash';

export function addNecessaryProps(necessary, diff, current) {
  lo.forEach(necessary, (nKey) => { diff[nKey] = current[nKey]; });
}

export function difference(initial, current, necessary) {
  const instArr = current instanceof Array;
  const diff = instArr ? [] : {};

  const [iKeys, cKeys] = [lo.keys(initial), lo.keys(current)];
  const keys = cKeys.length > iKeys.length ? cKeys : iKeys;
  lo.forEach(keys, (key) => {
    const [iVal, cVal] = [initial[key], current[key]];
    if (lo.isObject(cVal) && !lo.isEmpty(cVal)) {
      let nestedDiff = {};
      if (iVal && cVal) {
        nestedDiff = difference(iVal, cVal, necessary);
      } else if (lo.keys(cVal).length) {
        nestedDiff = cVal;
      }

      if (instArr && lo.keys(nestedDiff).length) {
        diff.push(nestedDiff);
      } else if (lo.keys(nestedDiff).length) {
        diff[key] = nestedDiff;
        addNecessaryProps(necessary, diff, current);
      }
    } else if (!lo.isEqual(iVal, cVal) && lo.keys(cVal).length) {
      diff[key] = cVal;
      addNecessaryProps(necessary, diff, current);
    }
  });

  return diff;
}

export function nestedMerge(data, source, target) {
  const swap = lo.cloneDeep(data);
  if (swap[target]?.length) {
    swap[source] = [...(swap[target] || []), ...(swap[source] || [])];
    delete swap[target];
  }
  if (swap[source]?.length) {
    swap[source] = lo.map(swap[source], (val) => {
      return nestedMerge(val, source, target);
    });
  }
  return swap;
}

export function buildData(data) {
  const swap = lo.cloneDeep(data);
  if (swap?.content) {
    const content1 = swap.content.filter((val) => val.removed);
    const content2 = swap.content.filter((val) => !val.removed).map((val, index) => {
      if (!val.removed) {
        val.sequence = index + 1;
      }
      if (val.content) {
        return buildData(val);
      }
      return val;
    });
    swap.removedContent = content1;
    swap.content = content2;
  }
  return swap;
}

export const weakMap = (() => {
  console.log('WeakMap', WeakMap);
  return new WeakMap();
})();

export const map = (() => {
  const mapObj = new Map();
  console.log('Map', mapObj);
  return mapObj;
})();
