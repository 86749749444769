import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Copy from '@mui/icons-material/ContentCopy';

export default function CopyIcon({ onClick, sx }) {
  return (
    <IconButton
      onClick={onClick}
      sx={sx}
    >
      <Copy />
    </IconButton>
  );
}

CopyIcon.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};

CopyIcon.defaultProps = {
  onClick: () => {},
  sx: {},
};
