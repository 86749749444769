import { FormControl, FormHelperText } from '@mui/material';

import PropTypes from 'prop-types';

import { memo } from 'react';
import { Controller } from 'react-hook-form';
import Editor from './Editor';

function BodyContent({
  form, index, nested,
}) {
  const key = `${nested}[content][${index}][text]`;
  return (
    <Controller
      control={form.control}
      name={key}
      render={({ fieldState, field }) => {
        return (
          <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
            <Editor
              toolbar="bodyToolbar"
              field={field}
            />
            <FormHelperText color="error">{fieldState.error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

BodyContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
};
BodyContent.defaultProps = {};

export default memo(BodyContent);
