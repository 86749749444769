import { Stack } from '@mui/material';
import ModuleList from './ModulesList/ModulesList';
import ModuleBundleList from './ModuleBundlesList/ModuleBundlesList';

export default function AddModules() {
  return (
    <Stack direction="row">
      <ModuleList />
      <ModuleBundleList />
    </Stack>
  );
}
