import config from '../../config/config.json';

import responseHandler from '../../utils/responseHandler';

export default async (data) => fetch(
  `${config.api.url}${config.endpoints.auth}auth/forgetPassword`,
  {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  },
)
  .then(async (response) => {
    return responseHandler(await response.json(), 'Forgot password:');
  });
