import { PropTypes } from 'prop-types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { grey } from '@mui/material/colors';
import { black } from '../../constants/styles';

export default function NavigationArrowIcon({
  onClick, disabled, direction, sx,
}) {
  return (
    <ArrowBackIosNewIcon
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      sx={{
        width: '20px',
        cursor: 'pointer',
        ...sx,
        transform: `rotate(${direction === 'right' ? '180deg' : '0deg'})`,
        color: disabled ? grey[300] : black,
      }}
    />
  );
}

NavigationArrowIcon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  sx: PropTypes.shape({}),
};

NavigationArrowIcon.defaultProps = {
  onClick: () => {},
  disabled: false,
  direction: 'left',
  sx: {},
};
