import { FormControl, Grid, TextField } from '@mui/material';

import PropTypes from 'prop-types';
import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { label } from '../../../../../../utils/string';
import styles from '../ContentStyles';

function InputContent({
  form, index, nested, input,
}) {
  return (
    <Controller
      control={form.control}
      name={`${nested}[content][${index}][text]`}
      render={({ fieldState, field }) => (
        <FormControl sx={{ width: '100%' }}>
          <TextField
            sx={{ '& .MuiInputBase-root': styles.input[input.value] }}
            {...field}
            required
            error={Boolean(fieldState.error)}
            label={label`${input.value}`}
            helperText={fieldState.error?.message}
          />
        </FormControl>
      )}
    />
  );
}

InputContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
};
InputContent.defaultProps = {
};

export default memo(InputContent);
