import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE, REQUEST_LIMIT_MAX, REQUEST_LIMIT_MIN } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

export const queryFn = async (params, order, page, limit) => {
  const query = queryString.stringify({
    attributeMeta: {
      exclude: [...DEFAULT_EXCLUDE, 'content', 'modulesCount'],
    },
    queryMeta: {
      limit: limit || REQUEST_LIMIT_MIN,
      page: page || 1,
      order,
    },
    includeMeta: [
      { association: 'modules' },
    ],
    ...params,
  });
  const res = await fetch(`${config.api.url}${config.endpoints.subscription}bundle?${query}`, {
    headers: {
      Authorization: authorization(),
    },
  });
  const data = await res.json();
  return responseHandler(data, 'Get module bundle list:');
};

export const useModuleBundleList = (params, order, page, limit, ...options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['ModuleBundleList', order, page, limit, params, options],
    queryFn: queryFn.bind(null, params, order, page, limit),
  });
};
