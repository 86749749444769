import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async (uuid) => {
  const res = await fetch(`${config.api.url}${config.endpoints.learning}case/${uuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization(),
    },
  });
  return responseHandler(await res.json(), 'Delete case:');
};
export const useDeleteCaseMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['deleteCaseMutation', ...(options.mutationKey || [])], mutationFn });
};
