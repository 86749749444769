import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async (uuid) => {
  const res = await fetch(`${config.api.url}${config.endpoints.learning}sub-topic/${uuid}`, {
    method: 'DELETE',
    headers: {
      Authorization: authorization(),
    },
  });
  return responseHandler(res, 'Delete sub-topic:');
};
export const useDeleteSubTopicMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['deleteSubTopicMutation', ...(options.mutationKey || [])], mutationFn });
};
