import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ uuid, body }) => {
  const res = await fetch(`${config.api.url}${config.endpoints.learning}quiz/${uuid}`, {
    method: 'PATCH',
    headers: {
      Authorization: authorization(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return responseHandler(await res.json(), 'Save quiz:');
};
export const usePatchQuizMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['patchQuiz', ...(options.mutationKey || [])], mutationFn });
};
