import { PropTypes } from 'prop-types';
import { IconButton } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { grey, blue } from '@mui/material/colors';

export default function ArrowIcon({
  onClick, rotate, isActive, sx,
}) {
  return (
    <IconButton
      size="small"
      onClick={onClick}
    >
      <ExpandCircleDownIcon
        sx={{
          width: '20px',
          height: '20px',
          color: isActive ? blue['600'] : grey[300],
          cursor: 'pointer',
          transform: `rotate(${rotate ? '180deg' : '0deg'})`,
          ...sx,
        }}
      />
    </IconButton>
  );
}

ArrowIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  rotate: PropTypes.bool,
  isActive: PropTypes.bool,
  sx: PropTypes.shape({}),
};

ArrowIcon.defaultProps = {
  rotate: false,
  isActive: false,
  sx: {},
};
