import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  TextField, FormControlLabel,
  Checkbox, Button, Typography,
  ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import config from '../../../config/config.json';
import Show from '../../../components/Show/Show';
import styles from './CreateNewPasswordStyles';

export default function CreateNewPassword() {
  const [currentServer, setCurrentServer] = useState(
    localStorage.server
      || config.api.environ,
  );

  const switchServer = (_, serverType) => {
    if (serverType) {
      setCurrentServer(serverType);
      localStorage.server = serverType;
    }
  };

  const [remember, setRemember] = useState(false);
  const { control, formState: { errors } } = useForm();

  return (
    <form style={styles.authorization}>
      CREATE NEW PASSWORD
      <Show show={config.apis.length}>
        <Box sx={styles.serverToggle}>
          <ToggleButtonGroup
            value={currentServer}
            exclusive
            onChange={switchServer}
          >
            {config.apis.map((environ) => (
              <ToggleButton
                style={currentServer === environ ? styles.activeSwitcher : {}}
                value={environ}
              >
                {environ}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Show>
      <Controller
        defaultValue=""
        control={control}
        name="email"
        rules={{ required: true }}
        render={({
          field: {
            name, onBlur, onChange,
            ref, value,
          },
        }) => (
          <TextField
            sx={styles.input}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value}
            error={!!errors.email}
            type="email"
            label="Email"
            variant="standard"
          />
        )}
      />
      <br />
      <Controller
        defaultValue=""
        control={control}
        name="password"
        rules={{ required: true }}
        render={({
          field: {
            name, onBlur,
            onChange, ref, value,
          },
        }) => (
          <TextField
            sx={styles.input}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            value={value}
            error={!!errors.password}
            type="password"
            label="Password"
            variant="standard"
          />
        )}
      />
      <FormControlLabel
        sx={{ alignSelf: 'start' }}
        control={(
          <Checkbox
            onChange={(e) => setRemember(e.target.checked)}
            checked={remember}
            size="small"
          />
            )}
        label={(
          <Typography style={{ fontSize: '12px' }}>
            Remember me
          </Typography>
            )}
      />
      <br />
      <Box>
        <LoadingButton
          type="submit"
          style={{ marginRight: '5px' }}
          variant="contained"
        >
          Sign in
        </LoadingButton>
        <Button disabled variant="outlined">
          Forgot password?
        </Button>
      </Box>
    </form>
  );
}
