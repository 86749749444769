import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { STATUSES } from '../../../../constants/statuses';

import PopUp from '../../../../components/PopUp/PopUp';
import Input from '../../../../components/Input/Input';
import TextEditor from '../../../../components/TextEditor/TextEditor';
import Select from '../../../../components/Select/Select';

export default function HelpCenterEditor({ helpCenter, close, save }) {
  const [status, setStatus] = useState(helpCenter.status);
  const [question, setQuestion] = useState(helpCenter.question);
  const [answer, setAnswer] = useState(helpCenter.answer);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  useEffect(() => {
    setSaveDisabled(
      !(question
        && answer
        && (
          status !== helpCenter.status
            || question !== helpCenter.question
            || answer !== helpCenter.answer
        )),
    );
  }, [status, question, answer]);

  const saveHelpCenter = async () => {
    setLoading(true);
    await save({
      status,
      question,
      answer,
    });
    setLoading(false);
  };

  return (
    <PopUp
      open={!!helpCenter}
      close={close}
    >
      <Stack direction="row">
        <Select
          sx={{ position: 'relative', top: '2px', marginRight: '8px' }}
          label="Status"
          activeValue={status}
          setValue={(e) => setStatus(e.target.value)}
          values={STATUSES}
          itemNameGetter={(value) => value}
          loaded
        />
        <Input
          onChange={(e) => setQuestion(e.target.value)}
          value={question}
        />
      </Stack>
      <TextEditor
        editorName="Answer"
        onChange={setAnswer}
        data={answer}
      />
      <br />
      <LoadingButton
        onClick={saveHelpCenter}
        size="small"
        variant="contained"
        loading={loading}
        disabled={saveDisabled}
      >
        Save
      </LoadingButton>
    </PopUp>
  );
}

HelpCenterEditor.propTypes = {
  helpCenter: PropTypes.shape({
    status: PropTypes.oneOf(STATUSES),
    question: PropTypes.string,
    answer: PropTypes.string,

  }).isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};
