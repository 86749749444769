import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE, REQUEST_LIMIT_MAX } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (order, page = 1, limit = REQUEST_LIMIT_MAX) => {
  const query = queryString.stringify({
    queryMeta: { limit, page, order },
    attributeMeta: { exclude: DEFAULT_EXCLUDE },
  });
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}help/section?${query}`,
    { headers: { 'Accept-Encoding': 'application/json', Authorization: authorization() } },
  );
  return responseHandler(await res.json(), 'Get help center list:');
};
export const useHelpSectionList = (order = {}, page = 1, limit = REQUEST_LIMIT_MAX, ...options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['fetchHelpSectionList', order, page, limit, options],
    queryFn: queryFn.bind(null, order, page, limit),
  });
};
