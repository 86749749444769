import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

export default async (data, uuid) => {
  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === 'purchaseIds') {
      if (data[key].appleIdMonthly) {
        body.append('purchaseIds[appleIdMonthly]', data[key].appleIdMonthly);
      }
      if (data[key].appleIdAnnual) {
        body.append('purchaseIds[appleIdAnnual]', data[key].appleIdAnnual);
      }
    } else {
      body.append(key, data[key]);
    }
  });

  return fetch(`${config.api.url}${config.endpoints.learning}module/${uuid}`, {
    method: 'PATCH',
    headers: {
      Authorization: authorization(),
    },
    body,
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Save module:');
    });
};
