import PropTypes from 'prop-types';
import Drag from '@mui/icons-material/DragHandle';

export default function DragIcon({ sx }) {
  return (
    <Drag
      sx={{
        transform: 'rotate(90deg)',
        cursor: 'pointer',
        ...sx,
      }}
      color="action"
    />
  );
}

DragIcon.propTypes = {
  sx: PropTypes.shape({}),
};

DragIcon.defaultProps = {
  sx: {},
};
