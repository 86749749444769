import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import styles from './FieldStyles';

export default function Field({ children, sx }) {
  return (
    <Box sx={{ ...styles.field, ...sx }}>
      {children}
    </Box>
  );
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

Field.defaultProps = {
  sx: {},
};
