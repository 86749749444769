import { grey } from '@mui/material/colors';
import { HEADER_HEIGHT, white } from '../../constants/styles';

export default {
  wrapper: {
    width: '100%',
    height: HEADER_HEIGHT,
    top: 0,
    padding: '0 32px 0 32px',
    backgroundColor: white,
    boxShadow: `0 0 7px ${grey[500]}`,
    zIndex: 3,
  },

  header: {
    maxWidth: '1600px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',
  },

  flag: {
    width: '16px',
    marginRight: '8px',
  },

  userWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  role: {
    marginRight: '16px',
    display: 'flex',
    cursor: 'pointer',
  },
};
