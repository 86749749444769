import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { green, yellow, red } from '@mui/material/colors';

import dragHandler from '../../../../../../utils/dragAndDrop/dragHandler';
import dropHandler from '../../../../../../utils/dragAndDrop/dropHandler';
import {
  ENABLED, DISABLED, DELETED,
} from '../../../../../../constants/statuses';
import { PROP_TYPE_MODULE } from '../../../../../../constants/propTypes';

import Show from '../../../../../../components/Show/Show';
import Field from '../../../../../../components/Field/Field';
import Limited from '../../../../../../assets/icons/Limited';
import DragIcon from '../../../../../../assets/icons/DragIcon';

import styles from './ModuleStyles';

export default function Module({
  module, index,
  edit, moveModule, saveAlert,
}) {
  const dragRef = useRef(null);
  const [, drag] = dragHandler('Module', index, module.uuid);
  const [{ handlerId }, drop] = dropHandler('Module', dragRef, index, moveModule);

  if (moveModule) {
    drag(drop(dragRef));
  }

  let moduleStatusColor;
  switch (module.status) {
    case ENABLED:
      moduleStatusColor = green.A700;
      break;
    case DISABLED:
      moduleStatusColor = yellow.A700;
      break;
    case DELETED:
      moduleStatusColor = red.A700;
      break;
    default:
      moduleStatusColor = green.A700;
  }

  return (
    <Box
      sx={styles.moduleWrapper}
      ref={dragRef}
      data-handler-id={handlerId}
    >
      <Show show={!!moveModule}>
        <DragIcon />
      </Show>
      <Box
        data-testid="moduleStatus"
        sx={{
          ...styles.status,
          backgroundColor: moduleStatusColor,
        }}
      />
      <Field sx={styles.field}>
        <Show show={!!module.iconUrl}>
          <img
            style={styles.icon}
            src={module.iconUrl}
            alt={`${module.name} icon`}
          />
          <Divider
            sx={styles.divider}
            orientation="vertical"
          />
        </Show>
        <Box sx={styles.textWrapper}>
          <p>
            {module.name}
          </p>
        </Box>
      </Field>
      <Show show={module.limited}>
        <Box sx={styles.limited}>
          <Limited />
        </Box>
      </Show>
      <EditIcon
        onClick={edit}
        style={styles.editIcon}
        color="primary"
      />
      <Show show={saveAlert}>
        <Alert
          ml={8}
          severity="success"
        >
          saved!
        </Alert>
      </Show>
    </Box>
  );
}

Module.propTypes = {
  module: PROP_TYPE_MODULE.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  edit: PropTypes.func.isRequired,
  moveModule: PropTypes.func,
  saveAlert: PropTypes.bool,
};

Module.defaultProps = {
  saveAlert: false,
  moveModule: null,
};
