import { datadogRum } from '@datadog/browser-rum';
import config from './config/config.json';
import { getUser } from './services';

if (config.rum) {
  datadogRum.init(config.rum);
}

export const setRumUser = async () => {
  if (config.rum) {
    const token = localStorage.getItem('token');
    if (token) {
      const user = await getUser(token);
      datadogRum.setUser({ id: user.uuid, email: user.email, name: `${user.roles}` });
    }
    datadogRum.startSessionReplayRecording();
  }
};
