import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import DisableIcon from '../../../assets/icons/DisableIcon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import styles from './TableHeaderStyles';

export default function TableHeader({ name, column, showOrder }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const setOrder = (newOrder) => {
    if (newOrder) {
      searchParams.set(`order.${column}`, newOrder);
    } else {
      searchParams.delete(`order.${column}`);
    }
    setSearchParams(searchParams);
  };

  return (
    <Box sx={styles.header}>
      <Box sx={{ marginRight: '8px' }}>
        {name}
      </Box>
      {showOrder && (
      <>
        <DisableIcon onClick={() => setOrder(null)} />
        <ArrowIcon
          onClick={() => setOrder('ASC')}
          isActive={searchParams.get(`order.${column}`) === 'ASC'}
          rotate
        />
        <ArrowIcon
          onClick={() => setOrder('DESC')}
          isActive={searchParams.get(`order.${column}`) === 'DESC'}
        />
      </>
      )}
    </Box>
  );
}

TableHeader.propTypes = {
  name: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  showOrder: PropTypes.bool,
};

TableHeader.defaultProps = {
  showOrder: true,
};
