import { format } from 'light-date';
import lo from 'lodash';
import { useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  useCasesList, usePostCaseMutation, usePatchCaseMutation, useDeleteCaseMutation, usePutOrderCaseMutation,
} from '../../../../services';

import { move } from '../../../../utils/dragAndDrop/move';
import clearHTML from '../../../../utils/clearHTML';
import {
  ALERT_SEVERITIES, POPUP_TYPES, PopupContext,
} from '../../../../context';

import Table from '../../../../components/Table/Table';
import TableDnD from '../../../../components/Table/TableDnD/TableDnD';
import AddButton from '../../../../components/AddButton/AddButton';
import { makeOrder } from '../../../../utils/order';
import { makeQueryMeta } from '../../../../utils/requestBuilder';
import CaseEditor from './CaseEditor/CaseEditor';

export default function Cases() {
  const dispatchPopup = useContext(PopupContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [cases, setCases] = useState([]);
  const [caseEditor, setCaseEditor] = useState(null);
  const [ordering, setOrdering] = useState(false);

  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const moduleUuid = searchParams.get('module');
  const jurisdictionUuid = searchParams.get('jurisdiction');

  const makeQuery = () => {
    const order = makeOrder(searchParams);
    if (Object.keys(order).length) {
      queryMeta.order = {};
    }

    lo.forEach(order, (ord, key) => {
      queryMeta.order[key] = ord;
    });

    return {
      queryMeta,
      filterMeta: { jurisdictionUuid, moduleUuid },
    };
  };
  const caseList = useCasesList(makeQuery(), {
    enabled: !ordering,
    onSuccess: ({ data }) => setCases(data),
  });
  const caseOptions = {
    onSuccess: async () => {
      setOrdering(false);
      await caseList.refetch();
      setCaseEditor(null);
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Case changes was successfully saved',
        severity: ALERT_SEVERITIES.INFO,
      });
      setOrdering(false);
    },
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
  };
  const postCaseM = usePostCaseMutation(caseOptions);
  const patchCaseM = usePatchCaseMutation(caseOptions);
  const putOrderCaseM = usePutOrderCaseMutation(caseOptions);
  const deleteCaseM = useDeleteCaseMutation(caseOptions);

  const openCaseEditor = (row) => {
    if (row) {
      searchParams.set('uuid', row.uuid);
      setSearchParams(searchParams);
    }
    setCaseEditor(row || {
      name: '',
      judgmentReference: '',
      factBite: '',
      held: '',
      keywords: ['', '', '', '', ''],
      referenceExtracts: [],
    });
  };
  const closeCaseEditor = () => {
    if (caseEditor) {
      searchParams.delete('uuid');
      setSearchParams(searchParams);
    }
    setCaseEditor(null);
  };

  useEffect(() => {
    const editorUuid = searchParams.get('uuid');
    const currentEditor = cases.find((val) => (val.uuid === editorUuid));
    if (currentEditor) {
      openCaseEditor(currentEditor);
    } else {
      closeCaseEditor();
    }
  }, [searchParams, cases]);

  const isLoading = () => {
    return caseList.isFetching
      || postCaseM.isPending
      || patchCaseM.isPending
      || putOrderCaseM.isPending
      || deleteCaseM.isPending;
  };
  const saveCase = async (data, _, onSuccess) => {
    const body = {};
    Object.keys(data).forEach((key) => {
      if (key === 'keywords' || data[key]?.length) {
        body[key] = data[key];
      }
    });

    if (body.referenceExtracts) {
      body.referenceExtracts = body.referenceExtracts.map((val) => ({ ...val, cases: { add: val.cases } }));
    }

    await postCaseM.mutateAsync({ moduleUuid, jurisdictionUuid, ...body }, { onSuccess });
    closeCaseEditor();
  };
  const updateCase = async (data, uuid, onSuccess) => {
    const initialCase = cases.find((val) => val.uuid === uuid);
    const body = {};

    Object.keys(data).forEach((key) => {
      if (JSON.stringify(data[key]) !== JSON.stringify(initialCase[key])) {
        if (key !== 'referenceExtracts') {
          body[key] = data[key];
        }
      }
    });

    const addReferences = data.referenceExtracts
      .filter((reference) => !reference.uuid)
      .map((reference) => {
        if (reference.cases?.length) {
          return { ...reference, cases: { add: reference.cases } };
        }

        return {
          judgmentReference: reference.judgmentReference,
          keyPoint: reference.keyPoint,
          keyReference: reference.keyReference,
          subTopicUuid: reference.subTopicUuid,
        };
      });

    const updateReferences = data.referenceExtracts
      .filter((newReference) => newReference.uuid && (
        JSON.stringify(newReference) !== JSON.stringify(initialCase.referenceExtracts
          .find((reference) => (reference.uuid === newReference.uuid)))
      )).map((reference) => {
        if (reference.cases?.length) {
          return { ...reference, cases: { add: reference.cases } };
        }
        return {
          judgmentReference: reference.judgmentReference,
          keyPoint: reference.keyPoint,
          keyReference: reference.keyReference,
          subTopicUuid: reference.subTopicUuid,
          uuid: reference.uuid,
        };
      });

    const removeReferences = initialCase.referenceExtracts.filter((reference) => {
      return !(data.referenceExtracts.some((_content) => {
        return _content.uuid === reference.uuid;
      }));
    }).map((reference) => reference.uuid);

    if (addReferences.length || updateReferences.length || removeReferences.length) {
      body.referenceExtracts = {};
      if (addReferences.length) {
        body.referenceExtracts.add = addReferences;
      }
      if (updateReferences.length) {
        body.referenceExtracts.update = updateReferences;
      }
      if (removeReferences.length) {
        body.referenceExtracts.remove = removeReferences;
      }
    }

    await patchCaseM.mutateAsync({ uuid, body: { ...body, moduleUuid, jurisdictionUuid } }, { onSuccess });
    closeCaseEditor();
  };
  const moveCase = (drag, drop) => {
    setOrdering(true);
    const swap = cases?.slice() || [];
    move(swap, drag, drop);

    setCases(swap);
  };
  const saveOrder = () => {
    const order = lo.filter(cases, (val) => (val.sequence !== val.initialSequence))
      .map(({ sequence, uuid }) => ({ sequence, uuid }));

    putOrderCaseM.mutate({ moduleUuid, jurisdictionUuid, order });
  };
  const deleteCase = (uuid) => {
    dispatchPopup({
      type: POPUP_TYPES.DIALOG,
      header: 'Are you sure you want to delete case?',
      action: () => {
        cases.find((_case) => _case.uuid === uuid).loading = true;
        setCases(cases);

        deleteCaseM.mutate(uuid);
      },
    });
  };

  const columns = [
    {
      field: 'order',
      name: '',
      type: 'actions',
      showOrder: false,
      renderCell: (value) => (
        <TableDnD
          id={value.row.uuid}
          index={value.row.index}
          move={moveCase}
        />
      ),
      width: 50,
    },
    {
      field: 'votingCount',
      name: 'Votes',
      renderCell: ({ row: { votingCount } }) => {
        return votingCount >= 0 ? votingCount : '-';
      },
      showOrder: true,
      width: 160,
    },
    {
      field: 'name',
      name: 'Name',
      renderCell: (value) => value.row.name,
      showOrder: true,
      width: 314,
    },
    {
      field: 'judgmentReference',
      name: 'Judgment Reference',
      renderCell: (value) => value.row.judgmentReference,
      showOrder: true,
      width: 275,
    },
    {
      field: 'factBite',
      name: 'Fact Bite',
      renderCell: (value) => clearHTML(value.row.factBite || ''),
      showOrder: true,
      width: 250,
    },
    {
      field: 'updatedAt',
      name: 'UpdatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.updatedAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
    {
      field: 'createdAt',
      name: 'CreatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.createdAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
    {
      field: 'action',
      name: 'Action',
      type: 'actions',
      showOrder: false,
      width: 185,
      renderCell: (value) => {
        return (
          <>
            <AddButton
              onClick={() => {
                openCaseEditor(value.row);
              }}
              text="edit"
              sx={{ marginRight: '8px' }}
            />
            <LoadingButton
              loading={value.row.loading}
              onClick={async () => {
                await deleteCase(value.row.uuid);
              }}
              size="small"
              variant="contained"
              color="error"
            >
              Delete
            </LoadingButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <AddButton
          disabled={isLoading()}
          onClick={() => openCaseEditor()}
          text="Add Case"
          size="large"
        />
      </Stack>
      <br />
      <Table
        columns={columns}
        rows={cases}
        count={caseList.data?.meta?.count || 0}
        loading={isLoading()}
        rowsPerPage={queryMeta.limit}
      />
      <br />
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <LoadingButton
          loading={putOrderCaseM.isPending}
          onClick={saveOrder}
          size="small"
          variant="contained"
          disabled={isLoading() || !ordering}
        >
          Save order
        </LoadingButton>
        <AddButton
          disabled={isLoading()}
          onClick={() => openCaseEditor()}
          text="Add Case"
          size="large"
        />
      </Stack>
      {caseEditor && (
        <CaseEditor
          _case={caseEditor}
          close={closeCaseEditor}
          save={caseEditor.uuid ? updateCase : saveCase}
        />
      )}
    </>
  );
}
