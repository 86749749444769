import { green } from '@mui/material/colors';
import { white } from '../../../constants/styles';

export default {
  authorization: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },

  input: {
    width: '288px',
    marginRight: '8px',
  },

  serverToggle: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
  },

  activeSwitcher: {
    background: green[500],
    color: white,
  },
};
