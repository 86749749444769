import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../config/config.json';
import { DEFAULT_EXCLUDE, REQUEST_LIMIT_MIN } from '../../constants/server';
import responseHandler from '../../utils/responseHandler';
import authorization from '../authorization';
import { queryOptions } from '../config';

export const getUser = async (token) => {
  const query = queryString.stringify({
    attributeMeta: {
      exclude: DEFAULT_EXCLUDE,
    },
  });
  return fetch(`${config.api.url}${config.endpoints.user}user/me?${query}`, {
    headers: {
      Authorization: `Bearer ${token || authorization()}`,
    },
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Get user:');
    });
};
export const useUser = (order = {}, page = 1, limit = REQUEST_LIMIT_MIN, ...options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['fetchUser', order, page],
    queryFn: getUser.bind(null, order, page, limit),
  });
};
