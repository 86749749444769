import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (params, options) => {
  const query = {
    attributeMeta: {
      exclude: [...DEFAULT_EXCLUDE, ...(params.attributeMeta?.exclude || [])],
    },
    queryMeta: {
      ...params.queryMeta,
      order: params.queryMeta?.order || {
        sequence: 'ASC',
      },
    },
    filterMeta: params.filterMeta,
  };
  const res = await fetch(
    `${config.api.url}${config.endpoints.learning}sub-topic?${queryString.stringify(query)}`,
    {
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: authorization(),
      },
    },
  );
  const data = await res.json();
  data.data = data.data.map((val, index) => ({
    ...val, index, loading: false, id: val.uuid, initialSequence: val.sequence,
  }));

  const resp = responseHandler(data, 'Get sub-topics list:');
  if (options.onSuccess) {
    options.onSuccess(resp);
  }
  return resp;
};
export const useSubTopicList = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['SubTopicList', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
