import { red } from '@mui/material/colors';
import { HEADER_HEIGHT } from '../../../constants/styles';

export default {
  alertWrapper: {
    minWidth: '200px',
    position: 'fixed',
    right: '8px',
    top: `calc(${HEADER_HEIGHT} + 8px)`,
    zIndex: 10,
  },

  clearAlerts: {
    position: 'absolute',
    bottom: '-40px',
    right: '16px',
    backgroundColor: red[700],
    opacity: 0.75,
  },

  alertsList: {
    maxHeight: '250px',
    overflow: 'auto',
  },

  alert: {
    marginBottom: '8px',
    marginRight: '16px',
  },
};
