import queryString from 'qs';
import { useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { Box, Button } from '@mui/material';
import 'draft-js/dist/Draft.css';
import LoadingButton from '@mui/lab/LoadingButton';

import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import { TERMS_OF_USE } from '../../../constants/route';
import styles from './Terms&PrivacyStyles';
import TextEditor from '../../../components/TextEditor/TextEditor';
import { ALERT_SEVERITIES, POPUP_TYPES, PopupContext } from '../../../context';
import {
  useDefinitionTermsOfUse,
  usePatchTermsOfUseMutation,
  usePostPrivacyPolicyMutation,
  usePrivacyPolicy,
  usePatchPrivacyPolicyMutation,
} from '../../../services';

export default function TermsOfUse() {
  const dispatchPopup = useContext(PopupContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const activeTab = searchParams.get('tab');

  const [termsOfUse, setTermsOfUse] = useState('');
  const [initialTermsOfUse, setInitialTermsOfUse] = useState('');
  const [termsOfUseUUID, setTermsOfUseUUID] = useState('');
  const [termsOfUseSaveDisabled, setTermsOfUseSaveDisabled] = useState(true);

  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [initialPrivacyPolicy, setInitialPrivacyPolicy] = useState('');
  const [privacyPolicyUUID, setPrivacyPolicyUUID] = useState('');
  const [privacyPolicySaveDisabled, setPrivacyPolicySaveDisabled] = useState(true);

  const definitionTermsOfUse = useDefinitionTermsOfUse();
  const definitionPrivacyPolicy = usePrivacyPolicy();

  const termsOfUseOptions = {
    onSuccess: async () => {
      await definitionTermsOfUse.refetch();
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Terms of use was saved',
        severity: ALERT_SEVERITIES.SUCCESS,
      });
    },
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
  };
  const postTermsOfUse = usePatchTermsOfUseMutation(termsOfUseOptions);
  const patchTermsOfUse = usePatchTermsOfUseMutation(termsOfUseOptions);

  const privacyPolicyOptions = {
    onSuccess: async () => {
      await definitionPrivacyPolicy.refetch();
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Privacy policy was saved',
        severity: ALERT_SEVERITIES.SUCCESS,
      });
    },
    onError: (error) => dispatchPopup({
      type: POPUP_TYPES.ALERT,
      text: error.message,
    }),
  };
  const postPrivacyPolicy = usePostPrivacyPolicyMutation(privacyPolicyOptions);
  const patchPrivacyPolicy = usePatchPrivacyPolicyMutation(privacyPolicyOptions);

  useEffect(() => {
    if (definitionTermsOfUse.isFetched) {
      const backendTermsOfUse = _.last(definitionTermsOfUse.data);
      if (backendTermsOfUse) {
        setTermsOfUse(backendTermsOfUse.text);
        setInitialTermsOfUse(backendTermsOfUse.text);
        setTermsOfUseUUID(backendTermsOfUse.uuid);
      }
      setTermsOfUseSaveDisabled(true);
    }

    if (definitionPrivacyPolicy.isFetched) {
      const backendPrivacyPolicy = _.last(definitionPrivacyPolicy.data);
      if (backendPrivacyPolicy) {
        setPrivacyPolicy(backendPrivacyPolicy.text);
        setInitialPrivacyPolicy(backendPrivacyPolicy.text);
        setPrivacyPolicyUUID(backendPrivacyPolicy.uuid);
      }
      setPrivacyPolicySaveDisabled(true);
    }
  }, [definitionTermsOfUse.data, definitionPrivacyPolicy.data]);
  useEffect(() => {
    if (!activeTab) {
      searchParams.set('tab', 'terms');
      setSearchParams(searchParams);
    }
  }, [activeTab]);
  useEffect(() => {
    setTermsOfUseSaveDisabled(!(termsOfUse !== initialTermsOfUse));
    setPrivacyPolicySaveDisabled(!(privacyPolicy !== initialPrivacyPolicy));
  }, [termsOfUse, privacyPolicy]);

  const saveTermsData = () => {
    if (termsOfUseUUID) {
      patchTermsOfUse.mutate({
        body: { text: termsOfUse },
        uuid: termsOfUseUUID,
      });
    } else {
      postTermsOfUse.mutate({
        body: { text: termsOfUse },
      });
    }
  };
  const savePrivacyData = () => {
    if (privacyPolicyUUID) {
      patchPrivacyPolicy.mutate({
        body: { text: privacyPolicy },
        uuid: privacyPolicyUUID,
      });
    } else {
      postPrivacyPolicy.mutate({
        body: { text: privacyPolicy },
      });
    }
  };

  const query = queryString.stringify({ jurisdiction: jurisdictionUuid });
  return (
    <>
      <Box component="nav" sx={{ flexGrow: 1 }}>
        <List role="menubar" orientation="horizontal">
          <ListItem style={activeTab === 'terms' ? styles.activeSwitcher : {}}>
            <Link
              to={`/${TERMS_OF_USE.ROUTE}?${query}&tab=terms`}
            >
              <Button style={activeTab === 'terms' ? styles.activeSwitcher : {}}>Terms of use</Button>
            </Link>
          </ListItem>
          <ListDivider />
          <ListItem style={activeTab === 'privacy' ? styles.activeSwitcher : {}}>
            <Link
              to={`/${TERMS_OF_USE.ROUTE}?${query}&tab=privacy`}
            >
              <Button style={activeTab === 'privacy' ? styles.activeSwitcher : {}}>Privacy policy</Button>

            </Link>
          </ListItem>
        </List>
      </Box>
      <br />
      <br />
      {activeTab === 'terms' && definitionTermsOfUse.isFetched && !!termsOfUse && (
        <Box sx={{ width: 'fit-content' }}>
          <TextEditor
            editorName="Terms of use"
            sx={styles.editor}
            loaded={definitionTermsOfUse.isFetched}
            data={termsOfUse}
            onChange={setTermsOfUse}
          />
          <Box style={styles.buttons}>
            <Button
              variant="contained"
              onClick={() => setTermsOfUse(initialTermsOfUse)}
              disabled={postTermsOfUse.isPending || patchTermsOfUse.isPending || termsOfUseSaveDisabled}
              color="error"
            >
              CANCEL
            </Button>
            <LoadingButton
              sx={{ ml: '8px' }}
              variant="contained"
              onClick={saveTermsData}
              loading={postTermsOfUse.isPending || patchTermsOfUse.isPending}
              disabled={termsOfUseSaveDisabled}
            >
              SAVE
            </LoadingButton>
          </Box>
        </Box>
      )}
      {activeTab === 'privacy' && definitionPrivacyPolicy.isFetched && !!privacyPolicy && (
        <Box sx={{ width: 'fit-content' }}>
          <TextEditor
            editorName="Privacy policy"
            sx={styles.editor}
            loaded={definitionPrivacyPolicy.isFetched}
            data={privacyPolicy}
            onChange={setPrivacyPolicy}
          />
          <Box style={styles.buttons}>
            <Button
              variant="contained"
              onClick={() => setPrivacyPolicy(initialPrivacyPolicy)}
              disabled={postPrivacyPolicy.isPending || patchPrivacyPolicy.isPending || privacyPolicySaveDisabled}
              color="error"
            >
              CANCEL
            </Button>
            <LoadingButton
              sx={{ ml: '8px' }}
              variant="contained"
              onClick={savePrivacyData}
              loading={postPrivacyPolicy.isPending || patchPrivacyPolicy.isPending}
              disabled={privacyPolicySaveDisabled}
            >
              SAVE
            </LoadingButton>
          </Box>
        </Box>
      )}
    </>
  );
}
