import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

export default async (body) => {
  return fetch(`${config.api.url}${config.endpoints.subscription}bundle`, {
    method: 'POST',
    headers: {
      Authorization: authorization(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...body,
      jurisdictionUuid: localStorage.jurisdiction,
    }),
  })
    .then(async (response) => {
      return responseHandler(await response.json(), 'Save bundle:');
    });
};
