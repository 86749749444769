import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (params, options) => {
  const query = {
    attributeMeta: { exclude: [...DEFAULT_EXCLUDE, 'userUuid'], include: ['createdAt'] },
    queryMeta: {
      ...params.queryMeta,
      order: params.queryMeta?.order || {
        createdAt: 'DESC',
      },
    },
    includeMeta: [
      { association: 'user', include: [{ association: 'profile' }] },
      { association: 'type' },
    ],
  };
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}feedback?${queryString.stringify(query)}`,
    { headers: { 'Accept-Encoding': 'application/json', Authorization: authorization() } },
  );
  const data = await res.json();
  const resp = responseHandler(data, 'Get feedback list:');
  if (options?.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useFeedbackList = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['feedbackList', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
