export const makeOrder = (searchParams) => {
  const order = {};
  searchParams.forEach((value, key) => {
    if (key.includes('order.')) {
      order[key.replace('order.', '')] = value;
    }
  });

  return order;
};
