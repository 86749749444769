import { blue, grey } from '@mui/material/colors';

export default {
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },

  inputName: {
    marginBottom: '4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

  input: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'center',
    pl: '16px',
    pr: '16px',
    border: `1px solid ${grey[400]}`,
    borderRadius: '4px',
    backgroundColor: blue[50],
  },

  afterInput: {
    position: 'absolute',
    right: '8px',
    top: '16px',
  },
};
