import { format } from 'light-date';
import { useState, useContext, useEffect } from 'react';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import lo from 'lodash';
import { useSearchParams } from 'react-router-dom';
import Table from '../../../../components/Table/Table';
import TableDnD from '../../../../components/Table/TableDnD/TableDnD';
import { ALERT_SEVERITIES, POPUP_TYPES, PopupContext } from '../../../../context';
import {
  useStatuteList, usePostStatuteMutation, usePatchStatuteMutation, usePutOrderStatuteMutation, useDeleteStatuteMutation,
} from '../../../../services';

import { move } from '../../../../utils/dragAndDrop/move';

import AddButton from '../../../../components/AddButton/AddButton';
import { makeOrder } from '../../../../utils/order';
import { makeQueryMeta } from '../../../../utils/requestBuilder';
import StatuteEditor from './StatuteEditor/StatuteEditor';

export default function Statute() {
  const dispatchPopup = useContext(PopupContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [statutes, setStatutes] = useState([]);
  const [statuteEditor, setStatuteEditor] = useState(null);

  const [ordering, setOrdering] = useState(false);

  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const moduleUuid = searchParams.get('module');
  const jurisdictionUuid = searchParams.get('jurisdiction');

  const makeQuery = () => {
    const qorder = makeOrder(searchParams);
    if (Object.keys(qorder).length) {
      queryMeta.order = {};
    }

    lo.forEach(qorder, (ord, key) => {
      queryMeta.order[key] = ord;
    });

    return {
      queryMeta,
      filterMeta: { jurisdictionUuid, moduleUuid },
    };
  };
  const statuteList = useStatuteList(makeQuery(), {
    enabled: !ordering,
    onSuccess: ({ data }) => setStatutes(data),
  });
  const statuteOptions = {
    onSuccess: async () => {
      setOrdering(false);
      await statuteList.refetch();
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Statute changes was successfully saved',
        severity: ALERT_SEVERITIES.INFO,
      });
      setOrdering(false);
    },
    onError: (error) => {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: error.message,
      });
    },
  };
  const postStatuteM = usePostStatuteMutation(statuteOptions);
  const patchStatuteM = usePatchStatuteMutation(statuteOptions);
  const putOrderStatuteM = usePutOrderStatuteMutation(statuteOptions);
  const deleteStatuteM = useDeleteStatuteMutation(statuteOptions);

  const openStatuteEditor = (row) => {
    if (row) {
      searchParams.set('uuid', row.uuid);
      setSearchParams(searchParams);
    }
    setStatuteEditor(row || {
      name: '',
      abbreviation: '',
      content: [
        {
          sequence: 1,
          referenceNumber: '',
          heading: '',
          provision: [{
            sequence: 1,
            marker: '',
            text: '',
            subSection: 1,
          }],
          id: `${Math.random()}`,
        },
      ],
    });
  };
  const closeStatuteEditor = () => {
    if (statuteEditor) {
      searchParams.delete('uuid');
      setSearchParams(searchParams);
    }
    setStatuteEditor(null);
  };

  useEffect(() => {
    const editorUuid = searchParams.get('uuid');
    const currentEditor = statutes.find((val) => (val.uuid === editorUuid));
    if (currentEditor) {
      openStatuteEditor(currentEditor);
    } else if (editorUuid) {
      closeStatuteEditor();
    }
  }, [searchParams, statutes]);

  const isLoading = () => {
    return statuteList.isFetching
      || postStatuteM.isPending
      || patchStatuteM.isPending
      || putOrderStatuteM.isPending
      || deleteStatuteM.isPending;
  };
  const saveStatute = async (data, _, onSuccess) => {
    const body = {
      ...data,
      content: lo.sortBy(data.content.map(({
        sequence, referenceNumber, heading, provision,
      }) => ({
        sequence, referenceNumber: Number(referenceNumber), heading, provision,
      })), 'referenceNumber')
        .map((_statue, i) => ({ ..._statue, sequence: i + 1 })),
    };

    await postStatuteM.mutateAsync({ moduleUuid, jurisdictionUuid, ...body });
    onSuccess();
  };
  const updateStatute = async (data, uuid, onSuccess) => {
    const initialStatute = statutes.find((val) => val.uuid === uuid);
    const body = {};

    if (initialStatute.name !== data.name) {
      body.name = data.name;
    }
    if (initialStatute.abbreviation !== data.abbreviation) {
      body.abbreviation = data.abbreviation;
    }

    const add = lo.sortBy(data.content
      .map(({ referenceNumber, heading, provision }) => ({
        referenceNumber: Number(referenceNumber), heading, provision,
      })), 'referenceNumber')
      .map((_statue, i) => ({ ..._statue, sequence: i + 1 }));

    const remove = initialStatute.content.map((_content) => _content.uuid);

    if (add.length || remove.length) {
      body.content = {};

      if (add.length) {
        body.content.add = add;
      }
      if (remove.length) {
        body.content.remove = remove;
      }
    }

    await patchStatuteM.mutateAsync({ uuid, body: { ...body, moduleUuid, jurisdictionUuid } }, { onSuccess });
    onSuccess();
  };
  const moveStatute = (drag, drop) => {
    setOrdering(true);
    const swap = statutes?.slice() || [];
    move(swap, drag, drop);

    setStatutes(swap);
  };
  const saveOrder = async () => {
    const order = lo.filter(statutes, (val) => (val.sequence !== val.initialSequence))
      .map(({ sequence, uuid }) => ({ sequence, uuid }));

    putOrderStatuteM.mutate({ moduleUuid, jurisdictionUuid, order });
  };
  const deleteStatute = (uuid, setLoading) => {
    dispatchPopup({
      type: POPUP_TYPES.DIALOG,
      header: 'Are you sure you want to delete statute?',
      action: () => {
        statutes.find((_case) => _case.uuid === uuid).loading = true;
        setStatutes(statutes);

        deleteStatuteM.mutate(uuid);
      },
    });
    setLoading(false);
  };

  const columns = [
    {
      field: 'order',
      name: '',
      type: 'actions',
      showOrder: false,
      renderCell: (value) => (
        <TableDnD
          id={value.row.uuid}
          index={value.row.index}
          move={moveStatute}
        />
      ),
      width: 50,
    },
    {
      field: 'name',
      name: 'Name',
      renderCell: (value) => value.row.name,
      showOrder: true,
      width: 314,
    },
    {
      field: 'updatedAt',
      name: 'UpdatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.updatedAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
    {
      field: 'createdAt',
      name: 'CreatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.createdAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
    {
      field: 'action',
      name: 'Action',
      type: 'actions',
      showOrder: false,
      width: 185,
      renderCell: (value) => {
        return (
          <>
            <AddButton
              onClick={() => {
                openStatuteEditor(value.row);
              }}
              text="edit"
              sx={{ marginRight: '8px' }}
            />
            <LoadingButton
              loading={value.row.loading}
              onClick={() => {
                deleteStatute(value.row.uuid);
              }}
              size="small"
              variant="contained"
              color="error"
            >
              Delete
            </LoadingButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <AddButton
          disabled={isLoading()}
          onClick={() => openStatuteEditor()}
          text="Add Case"
          size="large"
        />
      </Stack>
      <br />
      <Table
        columns={columns}
        rows={statutes}
        count={statuteList.data?.meta?.count || 0}
        loading={isLoading()}
        rowsPerPage={queryMeta.limit}
      />
      <br />
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <LoadingButton
          loading={putOrderStatuteM.isPending}
          onClick={saveOrder}
          size="small"
          variant="contained"
          disabled={isLoading() || !ordering}
        >
          Save order
        </LoadingButton>
        <AddButton
          disabled={isLoading()}
          onClick={() => openStatuteEditor()}
          text="Add Case"
          size="large"
        />
      </Stack>
      {statuteEditor && (
        <StatuteEditor
          statute={statuteEditor}
          close={closeStatuteEditor}
          save={statuteEditor.uuid ? updateStatute : saveStatute}
        />
      )}
    </>
  );
}
