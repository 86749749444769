import { grey } from '@mui/material/colors';
import { white } from '../../constants/styles';

export default {
  item: {
    position: 'relative',
    width: '725px',
    marginBottom: '16px',
    padding: '8px',
    border: `1px solid ${grey[400]}`,
    borderRadius: '4px',
    boxShadow: `1px 1px 0 ${grey[300]}`,
    backgroundColor: white,
  },

  itemBody: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  itemInputWrapper: {
    paddingLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },

  arrowDropPlug: {
    width: '32px',
    height: '32px',
  },

  addButton: {
    marginRight: '8px',
  },

  limited: {
    position: 'absolute',
    right: '-4px',
    top: '-4px',
  },
};
