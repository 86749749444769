import PropTypes from 'prop-types';
import { STATUSES } from './statuses';
import { ALL_CONTENT_VALUES } from './content';

export const PROP_TYPE_MODULE = PropTypes.shape({
  productIdAnnual: PropTypes.string,
  productIdMonthly: PropTypes.string,
  purchaseIds: PropTypes.shape({
    appleIdMonthly: PropTypes.string,
    appleIdAnnual: PropTypes.string,
  }),
  limited: PropTypes.bool,
  name: PropTypes.string,
  status: PropTypes.oneOf(STATUSES),
  iconUrl: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]),
  uuid: PropTypes.string,
  sequence: PropTypes.number,
});

export const PROP_TYPE_CASE = PropTypes.shape({
  name: PropTypes.string,
  votingCount: PropTypes.number.isRequired,
  judgmentReference: PropTypes.string,
  factBite: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  referenceExtracts: PropTypes.arrayOf(PropTypes.shape({
    keyReference: PropTypes.string.isRequired,
    keyPoint: PropTypes.string.isRequired,
    subTopicUuid: PropTypes.string.isRequired,
  })),
  content: PropTypes.string,
  held: PropTypes.string,
  uuid: PropTypes.string,
});

export const PROP_TYPE_STATUTE = PropTypes.shape({
  abbreviation: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({})),
  contentSequence: PropTypes.number.isRequired,
  jurisdictionUuid: PropTypes.string.isRequired,
  limited: PropTypes.bool.isRequired,
  moduleUuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sequence: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
});

export const PROP_TYPE_SUB_TOPIC = PropTypes.shape({
  contentSequence: PropTypes.number.isRequired,
  jurisdictionUuid: PropTypes.string,
  limited: PropTypes.bool.isRequired,
  moduleUuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  sequence: PropTypes.number,
  uuid: PropTypes.string,
  cases: PropTypes.arrayOf(PROP_TYPE_CASE),
  statutes: PropTypes.arrayOf(PROP_TYPE_STATUTE),
});

export const PROP_TYPE_TOPIC = PropTypes.shape({
  contentSequence: PropTypes.number.isRequired,
  jurisdictionUuid: PropTypes.string,
  limited: PropTypes.bool.isRequired,
  moduleUuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  sequence: PropTypes.number,
  uuid: PropTypes.string,
  cases: PropTypes.arrayOf(PROP_TYPE_CASE),
  subtopics: PropTypes.arrayOf(PROP_TYPE_SUB_TOPIC),
});

export const PROP_TYPE_CONTENT_TYPE = PropTypes.shape({
  value: PropTypes.oneOf(ALL_CONTENT_VALUES),
  name: PropTypes.string.isRequired,
});
