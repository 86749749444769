import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

export default function Show({ show, children, alternative }) {
  const alternativeChildren = alternative === 'progress'
    ? <CircularProgress />
    : alternative;

  return show ? children : alternativeChildren;
}

Show.propTypes = {
  show: PropTypes.bool,
  alternative: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

Show.defaultProps = {
  show: false,
  alternative: null,
};
