import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import dragHandler from '../../../utils/dragAndDrop/dragHandler';
import dropHandler from '../../../utils/dragAndDrop/dropHandler';
import DragIcon from '../../../assets/icons/DragIcon';

export default function TableDnD({
  index, id,
  move,
}) {
  const dragRef = useRef(null);
  const [, drag] = dragHandler('Table', index, id);
  const [{ handlerId }, drop] = dropHandler('Table', dragRef, index, move);
  drag(drop(dragRef));

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
      ref={dragRef}
      data-handler-id={handlerId}
    >
      <DragIcon />
    </Box>
  );
}

TableDnD.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};
