import { PropTypes } from 'prop-types';
import { IconButton } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { grey } from '@mui/material/colors';

export default function DisableIcon({ onClick, sx }) {
  return (
    <IconButton
      size="small"
      onClick={onClick}
    >
      <RemoveCircleIcon
        sx={{
          width: '20px',
          height: '20px',
          color: grey[300],
          cursor: 'pointer',
          ...sx,
        }}
      />
    </IconButton>
  );
}

DisableIcon.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};

DisableIcon.defaultProps = {
  onClick: () => {},
  sx: {},
};
