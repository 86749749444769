import { useMutation } from '@tanstack/react-query';
import config from '../../../config/config.json';

import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';

const mutationFn = async ({ body }) => {
  const res = await fetch(
    `${config.api.url}${config.endpoints.support}definition/privacy-policy`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: authorization() },
      body: JSON.stringify(body),
    },
  );
  return responseHandler(await res.json(), 'Save privacy policy:', true);
};
export const usePostPrivacyPolicyMutation = (options) => {
  return useMutation({ ...options, mutationKey: ['postPrivacyPolicy', ...(options.mutationKey || [])], mutationFn });
};
