export default {
  caseCompare: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
  },

  addButton: {
    alignSelf: 'end',
    mt: '8px',
    mb: '16px',
  },

  keywords: {
    display: 'grid',
    gridTemplateColumns: '300px 300px',
    gridTemplateRows: '75px',
    gridColumnGap: '16px',
    gridRowGap: '8px',
  },

  referenceExtract: {
    marginBottom: '8px',
    display: 'flex',
    fontWeight: 'bold',
  },
};
