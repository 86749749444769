import { format } from 'light-date';
import { useContext, useEffect, useState } from 'react';
import lo from 'lodash';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';
import {
  usePutOrderQuizMutation,
  useQuizList,
  usePostQuizMutation,
  usePatchQuizMutation,
  useDeleteQuizMutation,
} from '../../../../services';

import { move } from '../../../../utils/dragAndDrop/move';
import clearHTML from '../../../../utils/clearHTML';

import {
  ALERT_SEVERITIES, POPUP_TYPES, PopupContext,
} from '../../../../context';

import Table from '../../../../components/Table/Table';
import TableDnD from '../../../../components/Table/TableDnD/TableDnD';
import { makeOrder } from '../../../../utils/order';
import { makeQueryMeta } from '../../../../utils/requestBuilder';
import QuizzesEditor from './QuizzesEditor/QuizzesEditor';
import AddButton from '../../../../components/AddButton/AddButton';

export default function Quizzes() {
  const dispatchPopup = useContext(PopupContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [quizzes, setQuizzes] = useState([]);
  const [quizEditor, setQuizEditor] = useState(null);
  const [ordering, setOrdering] = useState(false);

  const queryMeta = makeQueryMeta(searchParams.get('page'));
  const moduleUuid = searchParams.get('module');
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const makeQuery = () => {
    const order = makeOrder(searchParams);
    if (Object.keys(order).length) {
      queryMeta.order = {};
    }

    lo.forEach(order, (ord, key) => {
      if (key === 'question') {
        queryMeta.order['question.content'] = ord;
      } else if (key === 'case') {
        queryMeta.order['case.name'] = ord;
      } else if (key === 'topic') {
        queryMeta.order['topic.name'] = ord;
      } else {
        queryMeta.order[key] = ord;
      }
    });

    return {
      queryMeta,
      filterMeta: { jurisdictionUuid, moduleUuid },
    };
  };

  const quizList = useQuizList(makeQuery(), {
    enabled: !ordering,
    onSuccess: ({ data }) => setQuizzes(data),
  });
  const quizOptions = {
    onSuccess: async () => {
      await quizList.refetch();
      setQuizEditor(null);
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: 'Quiz changes was successfully saved',
        severity: ALERT_SEVERITIES.INFO,
      });
      setOrdering(false);
    },
    onError: (error) => {
      dispatchPopup({
        type: POPUP_TYPES.ALERT,
        text: error.message,
      });
    },
  };
  const postQuizM = usePostQuizMutation(quizOptions);
  const patchQuizM = usePatchQuizMutation(quizOptions);
  const putOrderQuizM = usePutOrderQuizMutation(quizOptions);
  const deleteQuizM = useDeleteQuizMutation(quizOptions);

  const openQuizEditor = (row) => {
    if (row) {
      searchParams.set('uuid', row.uuid);
      setSearchParams(searchParams);
    }
    setQuizEditor(row || {
      question: {
        content: '',
        answers:
          [
            { sequence: 1, correct: false, answer: '' },
            { sequence: 2, correct: false, answer: '' },
            { sequence: 3, correct: false, answer: '' },
            { sequence: 4, correct: false, answer: '' },
          ],
      },
    });
  };
  const closeQuizEditor = () => {
    if (quizEditor) {
      searchParams.delete('uuid');
      setSearchParams(searchParams);
    }
    setQuizEditor(null);
  };

  useEffect(() => {
    const editorUuid = searchParams.get('uuid');
    const currentEditor = quizzes.find((val) => (val.uuid === editorUuid));
    if (currentEditor) {
      openQuizEditor(currentEditor);
    } else if (editorUuid) {
      closeQuizEditor();
    }
  }, [searchParams, quizzes]);

  const isLoading = () => {
    return quizList.isFetching
    || postQuizM.isPending
    || patchQuizM.isPending
    || putOrderQuizM.isPending
    || deleteQuizM.isPending;
  };
  const saveQuiz = async (data, _, onSuccess) => {
    await postQuizM.mutateAsync({ ...data, moduleUuid, jurisdictionUuid }, { onSuccess });
    closeQuizEditor();
  };
  const updateQuiz = async (data, uuid, onSuccess) => {
    const initialQuiz = lo.find(quizzes, (val) => val.uuid === uuid);
    const body = {};

    const content = data.question.content !== initialQuiz.question.content ? data.question.content : '';

    const answersUpdate = lo
      .filter(data.question.answers, (answer) => {
        const initialAnswer = lo.find(initialQuiz.question.answers, (_answer) => _answer.uuid === answer.uuid);

        return (answer.correct !== initialAnswer.correct || answer.answer !== initialAnswer.answer);
      })
      .map(({ uuid: _uuid, correct, answer }) => ({ uuid: _uuid, correct, answer }));

    const topicUuid = data.topicUuid !== initialQuiz.topicUuid ? data.topicUuid : '';

    const caseUuid = data.caseUuid !== initialQuiz.caseUuid ? data.caseUuid : '';

    if (content || answersUpdate.length) {
      body.question = { uuid: data.question.uuid };
      if (content) {
        body.question.content = content;
      }
      if (answersUpdate.length) {
        body.question.answers = answersUpdate;
      }
    }
    if (topicUuid) {
      body.topicUuid = topicUuid;
    }
    if (caseUuid) {
      body.caseUuid = caseUuid;
    }
    await patchQuizM.mutateAsync({ uuid, body: { ...body, moduleUuid, jurisdictionUuid } }, { onSuccess });
    closeQuizEditor();
  };
  const moveQuiz = (drag, drop) => {
    setOrdering(true);
    const newQuizzes = quizzes.slice();
    move(newQuizzes, drag, drop);

    setQuizzes(newQuizzes);
  };
  const saveOrder = () => {
    const order = lo.filter(quizzes, (val) => (val.sequence !== val.initialSequence))
      .map(({ sequence, uuid }) => ({ sequence, uuid }));

    putOrderQuizM.mutate({ moduleUuid, jurisdictionUuid, order });
  };
  const deleteQuiz = (uuid, onSuccess) => {
    lo.find(quizzes, (quiz) => quiz.uuid === uuid).loading = true;
    deleteQuizM.mutate(uuid, { onSuccess });
  };

  const columns = [
    {
      field: 'order',
      name: '',
      type: 'actions',
      showOrder: false,
      renderCell: (value) => (lo.size(queryMeta.order) ? null : (
        <TableDnD
          id={value.row.uuid}
          index={value.row.index}
          move={moveQuiz}
        />
      )),
      width: 50,
    },
    {
      field: 'topic',
      name: 'Topic',
      renderCell: (value) => value.row.topic?.name,
      showOrder: true,
      width: 200,
    },
    {
      field: 'case',
      name: 'Case',
      showOrder: true,
      renderCell: (value) => value.row.case?.name,
      width: 175,
    },
    {
      field: 'question',
      name: 'Question',
      showOrder: true,
      renderCell: (value) => clearHTML(value.row.question?.content || ''),
      width: 403,
    },
    {
      field: 'answer',
      name: 'Answer',
      showOrder: false,
      renderCell: (value) => clearHTML(value?.row.question?.answers
        .find((answer) => answer.correct)
        .answer || ''),
      width: 203,
    },
    {
      field: 'updatedAt',
      name: 'UpdatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.updatedAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
    {
      field: 'createdAt',
      name: 'CreatedAt',
      showOrder: true,
      renderCell: (value) => format(new Date(value.row.createdAt), '{dd}/{MM}/{yyyy}'),
      width: 195,
    },
    {
      field: 'action',
      name: 'Action',
      type: 'actions',
      showOrder: false,
      width: 185,
      renderCell: (value) => {
        return (
          <>
            <AddButton
              disabled={isLoading()}
              onClick={() => openQuizEditor(value.row)}
              text="edit"
              sx={{ marginRight: '8px' }}
            />
            <LoadingButton
              disabled={isLoading()}
              loading={value.row.loading}
              onClick={() => {
                dispatchPopup({
                  type: POPUP_TYPES.DIALOG,
                  header: 'Are you sure you want to delete quiz?',
                  action: () => deleteQuiz(value.row.uuid, dispatchPopup.bind(null, {
                    type: POPUP_TYPES.ALERT,
                    text: 'Quiz was successfully deleted',
                    severity: ALERT_SEVERITIES.INFO,
                  })),
                });
              }}
              size="small"
              variant="contained"
              color="error"
            >
              Delete
            </LoadingButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <AddButton
          disabled={isLoading()}
          onClick={() => openQuizEditor()}
          text="Add Question"
          size="large"
        />
      </Stack>
      <br />
      <Table
        columns={columns}
        rows={quizzes}
        count={quizList.data?.meta?.count || 0}
        loading={isLoading()}
        rowsPerPage={queryMeta.limit}
      />
      <br />
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <LoadingButton
          loading={putOrderQuizM.isPending}
          onClick={saveOrder}
          size="small"
          variant="contained"
          disabled={isLoading() || !ordering}
        >
          Save order
        </LoadingButton>
        <AddButton
          disabled={isLoading()}
          onClick={() => openQuizEditor()}
          text="Add Question"
          size="large"
        />
      </Stack>
      {quizEditor && (
        <QuizzesEditor
          close={closeQuizEditor}
          quiz={quizEditor}
          save={quizEditor.uuid ? updateQuiz : saveQuiz}
        />
      )}
    </>
  );
}
