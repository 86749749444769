import { createContext } from 'react';

// Context for displaying global Alerts and Dialogs
export const POPUP_TYPES = {
  ALERT: 'alert',
  DIALOG: 'dialog',
  DELETE_ALERT: 'delete alert',
  DELETE_DIALOG: 'delete dialog',
  CLEAR_ALL: 'clearAll',
  CLEAR_ALERTS: 'clearAlerts',
};

export const ALERT_SEVERITIES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error', // default
};

export const PopupContext = createContext({ dispatchPopup: () => {} });

// Context for jurisdiction, differ jurisdictions include totally different content
export const JurisdictionContext = createContext({
  currentJurisdiction: null,
  setCurrentJurisdiction: () => {},
});

// Context for active module in Modules section
export const LearningContext = createContext({
  activeModule: null,
  setActiveModule: () => {},
  modulesList: [],
  updateModulesList: () => {},
  topicsList: [],
  updateTopicsList: () => {},
  subTopicsList: [],
  updateSubTopicsList: () => {},
  casesList: [],
  updateCasesList: () => {},
  statusesList: [],
  updateStatutesList: () => {},
});

// Context for checking rendering ScrollButtons
export const ScrollButtonsContext = createContext({});
