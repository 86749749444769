export const TITLE_VALUE = 'title';
export const BODY_VALUE = 'body';
export const LIST_VALUE = 'list';
export const BREAK_LINE_VALUE = 'br';
export const HEADER1_VALUE = 'header1';
export const HEADER2_VALUE = 'header2';
export const SAMPLE_ILLUSTRATION = 'sampleIllustration';
export const LINE_VALUE = 'line';
export const SUB_TOPIC_VALUE = 'subtopic';
export const SUB_PAGE_VALUE = 'subPage';
export const SUB_PAGE_BULLET_VALUE = 'subPageBullet';
export const CASE_VALUE = 'case';
export const CASE_WITH_BULLETS_VALUE = 'caseWithBullets';
export const STATUTE_VALUE = 'statute';
export const ALL_CONTENT_VALUES = [
  TITLE_VALUE,
  BODY_VALUE,
  LIST_VALUE,
  HEADER1_VALUE,
  HEADER2_VALUE,
  SAMPLE_ILLUSTRATION,
  LINE_VALUE,
  SUB_TOPIC_VALUE,
  SUB_PAGE_VALUE,
  SUB_PAGE_BULLET_VALUE,
  CASE_VALUE,
  CASE_WITH_BULLETS_VALUE,
  STATUTE_VALUE,
  BREAK_LINE_VALUE,
];

export const BODY = { value: BODY_VALUE, name: 'Body' };
export const LIST = { value: LIST_VALUE, name: 'List' };
export const CASE = { value: CASE_VALUE, name: 'Case' };
export const CASE_WITH_BULLETS = { value: CASE_WITH_BULLETS_VALUE, name: 'Case With Bullets' };
export const TITLE = { value: TITLE_VALUE, name: 'Title' };
export const HEADER1 = { value: HEADER1_VALUE, name: 'Header 1' };
export const HEADER2 = { value: HEADER2_VALUE, name: 'Header 2' };
export const BREAK_LINE = { value: BREAK_LINE_VALUE, name: 'Break line' };
export const HEADER_TYPES = [
  HEADER1,
  HEADER2,
];
export const SAMPLEILLUSTRATION = { value: SAMPLE_ILLUSTRATION, name: 'Sample Illustration' };
export const LINE = { value: LINE_VALUE, name: 'Line' };
export const SUB_TOPIC = { value: SUB_TOPIC_VALUE, name: 'Sub-Topic' };
export const SUB_PAGE = { value: SUB_PAGE_VALUE, name: 'Sub-Page' };
export const SUB_PAGE_BULLET = { value: SUB_PAGE_BULLET_VALUE, name: 'Bullet Sub-Page' };
export const STATUTE = { value: STATUTE_VALUE, name: 'Statute' };

export const TOPIC_CONTENT_TYPES = [
  BREAK_LINE,
  BODY,
  LIST,
  CASE,
  HEADER1,
  HEADER2,
  SAMPLEILLUSTRATION,
  LINE,
  SUB_TOPIC,
  TITLE,
];

export const SUB_TOPIC_CONTENT_TYPES = [
  BREAK_LINE,
  BODY,
  LIST,
  CASE,
  CASE_WITH_BULLETS,
  HEADER1,
  HEADER2,
  SAMPLEILLUSTRATION,
  LINE,
  SUB_PAGE,
  SUB_PAGE_BULLET,
  STATUTE,
];

export const SUBPAGE_TYPES = [
  SUB_PAGE,
  SUB_PAGE_BULLET,
];

export const RELATION_TYPES = [
  SUB_TOPIC,
  STATUTE,
  CASE,
  CASE_WITH_BULLETS,
];

// Content Length
export const MAX_CONTENT_NAME_LENGTH_SHORT = 18;
export const MAX_CONTENT_NAME_LENGTH_LONG = 34;
export const MAX_CONTENT_COUNT = 50;
